import React from "react";
import { UIInputBlock } from "../UIInputBlock";

export const DashboardInput = ({
  text,
  margin,
  eye,
  placeholder,
  alignLeft,
  disabled,
  correct,
  error,
  ErrorText,
  type,
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: alignLeft ? "flex-start" : "space-between",
        alignItems: "center",
        margin: margin,
      }}
    >
      <span>{text}</span>
      <UIInputBlock
        placeholder={placeholder}
        width="336px"
        height="48px"
        margin="0"
        eye={eye}
        disabled={disabled}
        correct={correct}
        error={error}
        ErrorText={ErrorText}
        type={type}
      />
    </div>
  );
};
