import React, { useState } from "react";
import { observer } from "mobx-react";
import "react-select-search/style.css";
import { DashboardMobileHeader } from "./DashboardMobileHeader";
import "./DashboardMobile.css";
import UIBlockButton, {
  UIBlockButtonColor,
} from "../../../../../../Components/Katelinlis/Button.tsx";
import { UIInputBlock } from "../UIInputBlock";

const MobileTeachersBlock = ({
  id,
  Name,
  Surname,
  Patronymic,
  Group,
  teacherStatus,
}) => {
  const [MobileTeacherEdit, setMobileTeacherEdit] = useState(false);
  const [MobileTeacherInvite, setMobileTeacherInvite] = useState(false);
  const [MobileTeacherDelete, setMobileTeacherDelete] = useState(false);
  return (
    <div>
      <div className="MobileTeachersBlock-Card">
        <h3>
          {id}. {Surname} {Name} {Patronymic}
        </h3>
        <span className="MobileTeachersBlock-Groups">Группа: {Group}</span>
        {teacherStatus === "registered" ? (
          <div className="teacherStatus-block">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.00016 13.6667C3.31816 13.6667 0.333496 10.682 0.333496 7.00001C0.333496 3.31801 3.31816 0.333344 7.00016 0.333344C10.6822 0.333344 13.6668 3.31801 13.6668 7.00001C13.6668 10.682 10.6822 13.6667 7.00016 13.6667ZM7.00016 12.3333C8.41465 12.3333 9.77121 11.7714 10.7714 10.7712C11.7716 9.77105 12.3335 8.4145 12.3335 7.00001C12.3335 5.58552 11.7716 4.22897 10.7714 3.22877C9.77121 2.22858 8.41465 1.66668 7.00016 1.66668C5.58567 1.66668 4.22912 2.22858 3.22893 3.22877C2.22873 4.22897 1.66683 5.58552 1.66683 7.00001C1.66683 8.4145 2.22873 9.77105 3.22893 10.7712C4.22912 11.7714 5.58567 12.3333 7.00016 12.3333ZM6.3355 9.66668L3.50683 6.83801L4.4495 5.89534L6.3355 7.78134L10.1062 4.01001L11.0495 4.95268L6.3355 9.66668Z"
                fill="#00D147"
              />
            </svg>
            <span style={{ color: "#00D147" }}>
              этот пользователь зарегистрирован
            </span>
          </div>
        ) : (
          <></>
        )}
        {teacherStatus === "invite" ? (
          <div className="teacherStatus-block">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00016 14.6667C4.31816 14.6667 1.3335 11.682 1.3335 8C1.3335 4.318 4.31816 1.33333 8.00016 1.33333C11.6822 1.33333 14.6668 4.318 14.6668 8C14.6668 11.682 11.6822 14.6667 8.00016 14.6667ZM8.00016 13.3333C9.41465 13.3333 10.7712 12.7714 11.7714 11.7712C12.7716 10.771 13.3335 9.41448 13.3335 8C13.3335 6.58551 12.7716 5.22895 11.7714 4.22876C10.7712 3.22856 9.41465 2.66666 8.00016 2.66666C6.58567 2.66666 5.22912 3.22856 4.22893 4.22876C3.22873 5.22895 2.66683 6.58551 2.66683 8C2.66683 9.41448 3.22873 10.771 4.22893 11.7712C5.22912 12.7714 6.58567 13.3333 8.00016 13.3333V13.3333ZM7.3335 10H8.66683V11.3333H7.3335V10ZM7.3335 4.66666H8.66683V8.66666H7.3335V4.66666Z"
                fill="#FE356B"
              />
            </svg>

            <span style={{ color: "rgba(254, 53, 107, 1)" }}>
              пригласите пользователя
            </span>
          </div>
        ) : (
          <></>
        )}
        {teacherStatus === "not-registered" ? (
          <div className="teacherStatus-block">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00016 14.6667C4.31816 14.6667 1.3335 11.682 1.3335 8.00001C1.3335 4.31801 4.31816 1.33334 8.00016 1.33334C11.6822 1.33334 14.6668 4.31801 14.6668 8.00001C14.6668 11.682 11.6822 14.6667 8.00016 14.6667ZM8.00016 13.3333C9.41465 13.3333 10.7712 12.7714 11.7714 11.7712C12.7716 10.7711 13.3335 9.4145 13.3335 8.00001C13.3335 6.58552 12.7716 5.22897 11.7714 4.22877C10.7712 3.22858 9.41465 2.66668 8.00016 2.66668C6.58567 2.66668 5.22912 3.22858 4.22893 4.22877C3.22873 5.22897 2.66683 6.58552 2.66683 8.00001C2.66683 9.4145 3.22873 10.7711 4.22893 11.7712C5.22912 12.7714 6.58567 13.3333 8.00016 13.3333ZM7.3335 10H8.66683V11.3333H7.3335V10ZM8.66683 8.90334V9.33334H7.3335V8.33334C7.3335 8.15653 7.40373 7.98696 7.52876 7.86194C7.65378 7.73692 7.82335 7.66668 8.00016 7.66668C8.18955 7.66667 8.37504 7.61287 8.53505 7.51156C8.69506 7.41025 8.82301 7.26559 8.90401 7.0944C8.98501 6.92321 9.01574 6.73254 8.99262 6.54457C8.96949 6.3566 8.89347 6.17907 8.77339 6.03262C8.6533 5.88617 8.4941 5.77683 8.31431 5.71732C8.13452 5.65782 7.94152 5.65059 7.75778 5.69648C7.57404 5.74237 7.40711 5.8395 7.27641 5.97655C7.14571 6.11361 7.05662 6.28497 7.0195 6.47068L5.7115 6.20868C5.79258 5.8034 5.97984 5.42692 6.25411 5.11773C6.52838 4.80854 6.87984 4.57773 7.27256 4.44889C7.66527 4.32005 8.08515 4.2978 8.48928 4.38442C8.89342 4.47104 9.2673 4.66342 9.57271 4.94189C9.87812 5.22037 10.1041 5.57495 10.2276 5.96939C10.351 6.36382 10.3675 6.78397 10.2754 7.18688C10.1833 7.58979 9.9858 7.96101 9.70318 8.26259C9.42055 8.56416 9.06292 8.78528 8.66683 8.90334Z"
                fill="#7A7A7A"
              />
            </svg>
            <span style={{ color: "rgba(122, 122, 122, 1)" }}>
              пользователь еще <br />
              не зарегистрировался по вашей ссылке
            </span>
          </div>
        ) : (
          <></>
        )}
        <div className="teacherStatusBlock-actions">
          <div className="teacherStatusBlock-left">
            {teacherStatus === "invite" ? (
              <button
                className="teacherStatusBlock-invite"
                onClick={() => {
                  setMobileTeacherInvite(true);
                }}
              >
                Пригласить
              </button>
            ) : (
              <></>
            )}
          </div>

          <div className="teacherStatusBlock-icons">
            <button
              style={{ marginRight: "2.19vw" }}
              onClick={() => {
                setMobileTeacherEdit(!MobileTeacherEdit);
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 19H6.414L15.728 9.68601L14.314 8.27201L5 17.586V19ZM21 21H3V16.757L16.435 3.32201C16.6225 3.13454 16.8768 3.02922 17.142 3.02922C17.4072 3.02922 17.6615 3.13454 17.849 3.32201L20.678 6.15101C20.8655 6.33853 20.9708 6.59284 20.9708 6.85801C20.9708 7.12317 20.8655 7.37748 20.678 7.56501L9.243 19H21V21ZM15.728 6.85801L17.142 8.27201L18.556 6.85801L17.142 5.44401L15.728 6.85801Z"
                  fill="#616568"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                setMobileTeacherDelete(true);
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_654_1055)">
                  <path
                    d="M7 4V2H17V4H22V6H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z"
                    fill="#FE356B"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_654_1055">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        </div>
      </div>
      {MobileTeacherEdit ? (
        <div className="teacherEditBlock">
          <span>Имя</span>
          <UIInputBlock
            type="text"
            placeholder={Name}
            width="100%"
            height="13.15vw"
            margin="4.38vw 0"
          />
          <span>Фамилия</span>
          <UIInputBlock
            type="text"
            placeholder={Surname}
            width="100%"
            height="13.15vw"
            margin="4.38vw 0"
          />
          <span>Отчество</span>
          <UIInputBlock
            type="text"
            placeholder={Patronymic}
            width="100%"
            height="13.15vw"
            margin="4.38vw 0"
          />
          <span>Группа</span>
          <UIInputBlock
            type="text"
            placeholder={Group}
            width="100%"
            height="13.15vw"
            margin="4.38vw 0"
          />
          <button className="button-green">Сохранить</button>
          <button
            className="button-green-trans"
            onClick={() => {
              setMobileTeacherEdit(false);
            }}
          >
            Отмена
          </button>
        </div>
      ) : (
        <></>
      )}
      {MobileTeacherInvite ? (
        <div
          className="MobileTeachers-PopupBG"
          onClick={(e) => {
            if (e) {
              if (e.target !== e.currentTarget) {
                return;
              } else {
                setMobileTeacherInvite(false);
              }
            }
          }}
        >
          <div className="MobileTeachers-inviteCard">
            <div className="MobileTeachersinviteCard__buttonContainer">
              <button
                onClick={() => {
                  setMobileTeacherInvite(false);
                }}
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4867 9.44323C13.7178 10.6835 14.8616 11.8357 15.9966 12.988C16.7737 13.7709 17.5507 14.5449 18.3191 15.3278C18.9303 15.9523 19.166 16.6911 18.8779 17.5531C18.6159 18.3448 18.0484 18.8198 17.2452 18.9605C16.4681 19.1012 15.8394 18.8022 15.2981 18.2568C13.4908 16.4273 11.6747 14.6065 9.86739 12.7857C9.78008 12.6978 9.7015 12.5922 9.60546 12.4691C9.46576 12.5922 9.37845 12.6714 9.28241 12.7593C7.41396 14.6065 5.54551 16.4448 3.66833 18.292C2.76903 19.1716 1.59906 19.2332 0.717222 18.4679C-0.0947686 17.7642 -0.225735 16.4976 0.420365 15.6444C0.5426 15.4861 0.673566 15.3366 0.813264 15.1958C2.5944 13.3926 4.38427 11.5983 6.16541 9.79507C6.25273 9.70711 6.3575 9.62795 6.47973 9.53999C6.34877 9.39925 6.27019 9.31129 6.18288 9.21454C4.36681 7.38497 2.55075 5.5554 0.734684 3.71704C-0.155886 2.81984 -0.243197 1.65877 0.507676 0.743989C1.19743 -0.100427 2.47217 -0.249959 3.33655 0.418537C3.4937 0.541681 3.64213 0.673621 3.78183 0.814357C5.63282 2.63513 7.47508 4.4471 9.32607 6.26788C9.39591 6.33824 9.47449 6.40861 9.57054 6.49657C9.67531 6.39982 9.76262 6.32065 9.84993 6.23269C11.666 4.40312 13.4821 2.56476 15.3069 0.735193C16.1974 -0.161999 17.3587 -0.241163 18.258 0.515293C19.0961 1.21018 19.2358 2.50319 18.581 3.3652C18.4588 3.52352 18.3278 3.67306 18.1881 3.81379C16.407 5.61697 14.6171 7.41136 12.8272 9.20574C12.7399 9.2849 12.6177 9.34648 12.4867 9.44323Z"
                    fill="#4FC1E9"
                  />
                </svg>
              </button>
            </div>

            <h3>Пригласить педагога</h3>
            <p>
              Передайте этот код педагогу.
              <br />
              Действителен до 23.10.22 11:00
            </p>
            <span className="MobileTeachers-inviteCode">45454</span>
            <p>или отправьте приглашение на почту</p>
            <UIInputBlock
              type="text"
              placeholder="Ввести почту"
              width="100%"
              height="13.15vw"
              margin="4.38vw 0"
            />
            <UIBlockButton
              style={UIBlockButtonColor.Primary}
              text="Отправить "
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {MobileTeacherDelete ? (
        <div
          className="MobileTeachers-PopupBG"
          onClick={(e) => {
            if (e) {
              if (e.target !== e.currentTarget) {
                return;
              } else {
                setMobileTeacherDelete(false);
              }
            }
          }}
        >
          <div className="MobileTeachers-deleteCard">
            <h3>Подтверждение</h3>
            <p>
              Вы действительно хотите <br /> удалить педагога?
            </p>
            <div className="MobileTeachersdeleteCard__actions">
              <button>Да</button>
              <button
                onClick={() => {
                  setMobileTeacherDelete(false);
                }}
              >
                Нет
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export const DashboardMobileTeachers = observer((props) => {
  const [MobileTeacherAdd, setMobileTeacherAdd] = useState(false);

  let accountType;

  if (localStorage.getItem("accountType") != null) {
    accountType = localStorage.getItem("accountType");
  } else {
    window.location.replace("/");
  }

  return (
    <>
      <DashboardMobileHeader accountType={accountType} />
      <div className="DashboardMobile-Content">
        <div className="DashboardMobile-Teachers">
          <h1 className="DashboardMobile-h1">Педагоги</h1>
          <MobileTeachersBlock
            id="1"
            Name="Мария"
            Surname="Смирнова"
            Patronymic="Сергеевна"
            Group="322"
            teacherStatus="invite"
          />
          <MobileTeachersBlock
            id="2"
            Name="Евгений"
            Surname="Антонов"
            Patronymic="Петрович"
            Group="122"
            teacherStatus="registered"
          />
          <MobileTeachersBlock
            id="3"
            Name="Андрей"
            Surname="Смирнов"
            Patronymic="Сергеевич"
            Group="2"
            teacherStatus="not-registered"
          />
          {MobileTeacherAdd ? (
            <div className="teacherEditBlock">
              <span>Имя</span>
              <UIInputBlock
                type="text"
                placeholder="Имя"
                width="100%"
                height="13.15vw"
                margin="4.38vw 0"
              />
              <span>Фамилия</span>
              <UIInputBlock
                type="text"
                placeholder="Фамилия"
                width="100%"
                height="13.15vw"
                margin="4.38vw 0"
              />
              <span>Отчество</span>
              <UIInputBlock
                type="text"
                placeholder="Отчество"
                width="100%"
                height="13.15vw"
                margin="4.38vw 0"
              />
              <span>Группа</span>
              <UIInputBlock
                type="text"
                placeholder="Группа"
                width="100%"
                height="13.15vw"
                margin="4.38vw 0"
              />
              <button className="button-green">Сохранить</button>
              <button
                className="button-green-trans"
                onClick={() => {
                  setMobileTeacherAdd(false);
                }}
              >
                Отмена
              </button>
            </div>
          ) : (
            <UIBlockButton
              style={UIBlockButtonColor.Primary}
              text="Добавить педагога"
              plusIcon
              onClick={() => {
                setMobileTeacherAdd(!MobileTeacherAdd);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
});
