import React, { useState } from "react";
import { observer } from "mobx-react";
import "react-select-search/style.css";
import { DashboardMobileHeader } from "./DashboardMobileHeader";
import "./DashboardMobile.css";
import UIBlockButton, {
  UIBlockButtonColor,
} from "../../../../../../Components/Katelinlis/Button.tsx";
import { UIInputBlock } from "../UIInputBlock";
import { Link } from "react-router-dom";

export const DashboardMobileSecurity = observer((props) => {
  const [PasswordChangedPopup, setPasswordChangedPopup] = useState(false);
  let accountType;

  if (localStorage.getItem("accountType") != null) {
    accountType = localStorage.getItem("accountType");
  } else {
    window.location.replace("/");
  }

  if (PasswordChangedPopup) {
    window.scrollTo(0, 0);
    document.getElementsByTagName("body")[0].style =
      "height: 100vh; overflow-y: hidden;";
  } else {
    document.getElementsByTagName("body")[0].style = "";
  }
  return (
    <>
      <DashboardMobileHeader accountType={accountType} />
      <div className="DashboardMobile-Content">
        <div className="DashboardMobile-Security">
          <h1 className="DashboardMobile-h1">Изменение пароля</h1>
          <UIInputBlock
            type="password"
            placeholder="Укажите старый пароль"
            width="100%"
            height="13.15vw"
            eye
          />
          <UIInputBlock
            type="password"
            placeholder="Укажите новый пароль"
            width="100%"
            height="13.15vw"
            margin="4.38vw 0"
            eye
          />
          <UIInputBlock
            type="password"
            placeholder="Подтвердите пароль"
            width="100%"
            height="13.15vw"
            eye
          />
          <UIBlockButton
            style={UIBlockButtonColor.Second}
            text="Сохранить"
            onClick={() => {
              setPasswordChangedPopup(true);
            }}
          />
          {PasswordChangedPopup ? (
            <div
              className="popup-bg"
              onClick={(e) => {
                if (e.target !== e.currentTarget) {
                  return;
                } else {
                  setPasswordChangedPopup(false);
                }
              }}
            >
              <div className="popup-card">
                <h1>Пароль успешно изменен</h1>
                <button
                  id="restore-popup-button-bg"
                  className="button-green"
                  onClick={() => {
                    setPasswordChangedPopup(false);
                  }}
                >
                  Отлично
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
});
