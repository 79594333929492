import { React, useState } from "react";
import UIBlockButton, {
  UIBlockButtonColor,
} from "../../../../../../Components/Katelinlis/Button.tsx";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

const KidRow = ({ id, Name, Surname, setSelectedKid }) => {
  return (
    <tr>
      <td>{id}</td>
      <td>{Name}</td>
      <td>{Surname}</td>
      <td>
        <button
          onClick={() => {
            setSelectedKid(id);
          }}
        >
          Выбрать
        </button>
      </td>
    </tr>
  );
};

export const DashboardQuiz = ({ accountType }) => {
  const [SelectedKid, setSelectedKid] = useState(-1);

  const group = [];

  return (
    <main
      style={{
        marginLeft: "47px",
      }}
    >
      {accountType === "parent" && SelectedKid === -1 ? (
        <>
          <div className="Dashboard-breadcrumbs">
            <button
              onClick={() => {
                setSelectedKid(-1);
              }}
            >
              Участник
            </button>
          </div>
          <h1
            style={{
              fontFamily: "Open Sans",
              fontWeight: "700",
              fontSize: "24px",
              lineHeight: "120%",
              letterSpacing: "-0.01em",
              color: "#000000",
              textAlign: "left",
              margin: "0 0 24px",
            }}
          >
            Участник
          </h1>
          <p style={{ textAlign: "left" }}>
            Выберите того, кто будет участвовать в олимпиаде
          </p>
          <table className="DashboardTable-Group">
            <thead>
              <tr>
                <th id="DashboardTable-ID">№</th>
                <th id="DashboardTable-Name">Имя</th>
                <th id="DashboardTable-Surname">Фамилия</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <KidRow
                id="1"
                Name="Миша"
                Surname="Мрак"
                setSelectedKid={setSelectedKid}
              />
              <KidRow
                id="2"
                Name="Настя"
                Surname="Гагарина"
                setSelectedKid={setSelectedKid}
              />
              <KidRow
                id="3"
                Name="Полина"
                Surname="Апполонова"
                setSelectedKid={setSelectedKid}
              />
            </tbody>
          </table>
        </>
      ) : (
        <></>
      )}
      {accountType === "parent" && SelectedKid !== -1 ? (
        <>
          <div className="Dashboard-breadcrumbs">
            <button
              onClick={() => {
                setSelectedKid(-1);
              }}
            >
              Участник
            </button>
            <figure>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.11467 8.00005L6.22867 6.11472L7.17133 5.17139L10 8.00005L7.17133 10.8287L6.22867 9.88539L8.11467 8.00005Z"
                  fill="#BDC7D5"
                />
              </svg>
            </figure>
            <button>Старт</button>
          </div>
          <div className="DashboardQuiz-Box">
            <h1
              style={{
                fontFamily: "Open Sans",
                fontWeight: "700",
                fontSize: "24px",
                lineHeight: "120%",
                letterSpacing: "-0.01em",
                color: "#000000",
                textAlign: "left",
                margin: "0 0 24px",
              }}
            >
              Старт
            </h1>
            <h2>Текущая олимпиада</h2>
            <a href="#">
              <img
                className="unselectable quiz-banner"
                src="/cosmopiada.webp"
              />
            </a>
            <h2>Предыдущая олимпиада</h2>
            <a href="#">
              <img
                className="unselectable quiz-banner"
                src="/cosmopiada.webp"
              />
            </a>
          </div>
        </>
      ) : (
        <></>
      )}
    </main>
  );
};
