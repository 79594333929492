import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import reportStore, {Base64} from "../../../../Stores/Report/ReportStore";
import childGroupsStore from "../../../../Stores/ChildGroups/ChildGroupsStore";
import CirclePercent from "../Molecules/CirclePercent";
import {BigTextAndSvg} from "../bigTextAndSvg";
import {CircleTotalTime} from "../Molecules/CircleTotalTime";
import {SmallTextAndSvg} from "../smallTextAndSvg";
import {CircleDouble} from "../Molecules/CircleDouble";
//TODO: Переделать общение со стором
const ReportDashboardGroup = () => {
  const groupID = childGroupsStore.groupChildId
  const level = reportStore.levelOlymp
  const [levelData, setLevelData] = useState(null)
  const [load, setLoad] = useState(true)



  useEffect(() =>{


    reportStore.setLevelData(groupID).then(() => {
        reportStore.levelData != null && setLevelData(reportStore.levelData.levels[(level).toString()])
        setLoad(false)
      }
    );

    return setLevelData(null)

  },[level, groupID])

  return (
    <div className="col-sm-12">
      { levelData?.levelTime > 0 ?
        (load ?
          <p>Загрузка данных</p>
          :
          (
            levelData == null ?
              <p>Данных еще нет</p>
              :
              <div style={{display: "flex", flexDirection: "column", width: '100%'}}>
                <BigTextAndSvg divM={'30px auto 0 auto'} pM={'0 0 10px 0'} div2M={'0 auto 30px auto'} text={'ОБЩЕЕ ВЫПОЛНЕНИЕ УРОВНЯ (%)'}/>
                <CirclePercent percent={levelData.levelComplete}/>

                <BigTextAndSvg divM={'11px auto 0 auto'} pM={'0'} div2M={'10px auto 37px auto'} text={'ВРЕМЯ, ПРОВЕДЕННОЕ НА ОДНОМ УРОВНЕ'}/>
                <CircleTotalTime games={levelData.gamesTime} levelTime={levelData.levelTime}/>




                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <SmallTextAndSvg text={<p style={{textAlign: "center", margin: '48px auto 0'}} >ИГРА С НАИЛУЧШИМИ <br/> ПОКАЗАТЕЛЯМИ</p>}/>

                    <CircleDouble
                      bigSize={240}
                      smallSize={163}
                      time={levelData.bestGame.gameTime}
                      percent={levelData.bestGame.complete}
                      gameName={levelData.bestGame.complete !== 0 ? levelData.bestGame.gameName : Base64.encode("Пока нет данных")}
                      totalTime={levelData.levelTime}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <SmallTextAndSvg text={<p style={{textAlign: "center", margin: '48px auto 0'}} >ИГРА, ГДЕ СТОИТ <br/> УЛУЧШИТЬ РЕЗУЛЬТАТЫ</p>}/>
                    <CircleDouble
                      bigSize={240}
                      smallSize={163}
                      time={levelData.badGame.gameTime}
                      percent={levelData.badGame.complete}
                      gameName={levelData.badGame.gameTime !== 0 ? levelData.badGame.gameName : Base64.encode("Пока нет данных")}
                      totalTime={levelData.levelTime}
                    />
                  </div>
                </div>



                <div className={'col-md-12'}>
                  <div style={{display: "flex", flexDirection: 'row', justifyContent: "space-around"}}>
                    <div style={{display: "flex", flexDirection: 'row', margin: '24px auto 0'}}>
                      <div style={{width: '18px', height: '18px', borderRadius: '50%', background: '#A0D468', margin: 'auto 8px auto 0'}}/>
                      <p className="game_text" style={{margin: 'auto 0', textAlign: "left"}}>Прогресс</p>
                    </div>

                    <div style={{display: "flex", flexDirection: 'row', margin: '24px auto 0'}}>
                      <div style={{width: '18px', height: '18px', borderRadius: '50%', background: '#4FC1E9', margin: 'auto 8px auto 0'}}/>
                      <p className="game_text" style={{margin: 'auto 0', textAlign: "left"}}>Время</p>
                    </div>
                  </div>
                </div>
              </div>
          )

        )
        :
        <p>Данных пока нет</p>



      }
    </div>
  );
};

export default observer(ReportDashboardGroup);