import React from "react";
import ProfileDropdown from "./HeaderModules/ProfileDropdown";
import { observer } from "mobx-react";
import { Link, useHistory, useLocation } from "react-router-dom";
import authStore from "../../Stores/AuthStore/AuthStore";
import { getChildrenList } from "../../Request/api/Childrens/getChildrenList";
import modalStore from "../../Components/modal/modalStore";

const Header = observer(() => {
  const location = useLocation();
  let history = useHistory();
  const headerdisabled = [
    "/dashboard-mobile",
    "/dashboard-mobile/cert",
    "/dashboard-mobile/security",
    "/dashboard-mobile/teachers",
    "/dashboard-mobile/account",
    "/dashboard-mobile/quiz",
    "/dashboard-mobile/kids",
    "/dashboard-mobile/account",
    "/dashboard-mobile/results",
    "/dashboard-mobile/code",
    "/dashboard-mobile/groups",
    "/dashboard",
  ];
  return !headerdisabled.includes(location.pathname) ? (
    <>
      <div
        className="header header_fixed"
        style={{
          zIndex: 50,
          WebkitUserSelect: "none",
          msUserSelect: "none",
          userSelect: "none",
          backgroundColor: "#fff",
        }}
      >
        <div>
          <Link to={"/"}>
            <img
              src="https://playstand.ru/wp-content/themes/innov2017/img/logo.svg"
              alt=""
              id="header_img"
              width="130px"
            />
          </Link>
        </div>

        <ProfileDropdown />
      </div>
    </>
  ) : (
    <></>
  );
});

export default Header;

function checkUserMove(history) {
  if (window.location.href.indexOf("/registration") !== -1) {
    return;
  }

  if (!authStore.isAuth) {
    history.push("/authorization");
  } else {
    if (authStore.userData.UserType === "Pedagog") {
      new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 0);
      }).then((r) => {
        modalStore.SetModalInfo(
          "С результатами вы можете ознакомиться после завершения олимпиады",
          "Олимпиада доступна только ученикам"
        );
        modalStore.simpleModalOpen();
      });
    } else if (authStore.userData.UserType === "Children") {
      document.cookie = `access=${
        authStore.getAccessToken
      }; domain=${extractDomain(window.location.href)}`;
      document.cookie = `refresh=${authStore.refresh}; domain=${extractDomain(
        window.location.href
      )}; max-age=2678400`; //max-age равен клв. секунд в месяце
      if (extractDomain(window.location.href) === "serviceplaystand.ru") {
        window.open("https://olymp.serviceplaystand.ru/");
      }
      if (extractDomain(window.location.href) === "playstand.ru") {
        window.open("https://olymp.playstand.ru/");
      }
    } else if (authStore.userData.UserType === "Parent") {
      getChildrenList().then((r) => {
        if (r !== null && r.type === "Data") {
          if (r.data.length > 0) {
            modalStore.SetModalInfo(
              "Запуск олимпиады возможен только через аккаунт ребёнка",
              "Перейдите в аккаунт ребёнка"
            );
            modalStore.simpleModalOpen();
            history.push("/children");
          } else {
            modalStore.SetModalInfo(
              "Запуск олимпиады возможен только через аккаунт ребёнка",
              "Перейдите в аккаунт ребёнка"
            );
            modalStore.simpleModalOpen();
            history.push("/children/add");
          }
        }
      });
    }
  }
}

function extractDomain(url) {
  if (url.indexOf("localhost") !== -1) {
    return "localhost";
  } else {
    return url.replace(
      /^(?:https?:\/\/)?(?:[^\/]+\.)?([^.\/]+\.[^.\/]+).*$/,
      "$1"
    );
  }
}
