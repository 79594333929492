import React from "react";
import { observer } from "mobx-react";
import RegStep1 from "./RegStep/RegStep1";
import SelectRole from "./RegStep/SelectRole/SelectRole";

import RegStep3Parent from "./RegStep/SelectRole/Role/Parent";
import RegStep3Teacher from "./RegStep/SelectRole/Role/Teacher";
import RegStep3Director from "./RegStep/SelectRole/Role/Director/Director";
import RegistrationByCode from "./RegStep/SelectRole/Role/RegistrationByCode";

import authStore from "../../Stores/AuthStore/AuthStore";
import { emailStore } from "../../Components/Inputs/Email/EmailStore";
import { telephoneStore } from "../../Components/Inputs/Telephone/TelephoneStore";
import { newPasswordStore } from "../../Components/Inputs/Password/NewPasswordStore";
import { confirmPasswordStore } from "../../Components/Inputs/Password/ConfirmPasswordStore";
import RegStepInvite from "./RegStep/RegStepInvite";
import RegStepQuestion from "./RegStep/RegStepQuestion";
import RegStep3Kindergarden from "./RegStep/RegStep3Kindergarden";
import RegStep3Children from "./RegStep/RegStep3Children";
import "./linkStyle.css";
import { registrationButtonStore } from "../../Components/Buttons/RegistrationButton/RegistrationButtonStore";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import relatedAccsStore from "../../Stores/RelatedAccsStore/RelatedAccsStore";
import masterRegStore from "../../Stores/Registration/MasterRegStore";
import { nameStore } from "../../Components/Inputs/Name/nameStore";
import { surnameStore } from "../../Components/Inputs/Surname/surnameStore";
import { middleNameStore } from "../../Components/Inputs/middleName/middleNameStore";
import dropdownPositionStore from "../../Components/Inputs/Position/dropdownPositionStore";
import { DirectorName } from "./RegStep/SelectRole/Role/Director/DirectorName";
import { DirectorEduStep } from "./RegStep/SelectRole/Role/Director/DirectorEduStep";
import { EduNotFound } from "./RegStep/SelectRole/Role/EduNotFound";
import { Dashboard } from "./RegStep/SelectRole/Role/Dashboard/index";
import { DashboardMobile } from "./RegStep/SelectRole/Role/DashboardMobile/index";
import { DashboardMobileCert } from "./RegStep/SelectRole/Role/DashboardMobile/DashboardMobile-cert";
import { DashboardMobileSecurity } from "./RegStep/SelectRole/Role/DashboardMobile/DashboardMobile-security";
import { DashboardMobileAccount } from "./RegStep/SelectRole/Role/DashboardMobile/DashboardMobile-account";
import { DashboardMobileTeachers } from "./RegStep/SelectRole/Role/DashboardMobile/DashboardMobile-teachers";
import { DashboardMobileQuiz } from "./RegStep/SelectRole/Role/DashboardMobile/DashboardMobile-quiz";
import { DashboardMobileKids } from "./RegStep/SelectRole/Role/DashboardMobile/DashboardMobile-kids";
import { DashboardMobileCode } from "./RegStep/SelectRole/Role/DashboardMobile/DashboardMobile-code";
import { Restore2 } from "./Restore2";
import { DashboardMobileGroups } from "./RegStep/SelectRole/Role/DashboardMobile/DashboardMobile-groups";
import { DashboardMobileResults } from "./RegStep/SelectRole/Role/DashboardMobile/DashboardMobile-results";
import RegStep3TeacherCode from "./RegStep/SelectRole/Role/Teacher-Code";
import RegStep3ParentCode from "./RegStep/SelectRole/Role/Parent-Code";
import TeacherName from "./RegStep/SelectRole/Role/Teacher-Name";
import ParentName from "./RegStep/SelectRole/Role/Parent-Name";

export function CheckMasterUser() {
  let history = useHistory();
  if (authStore.access !== null) {
    // if(jwt_decode(authStore.access).userType !== 'User'){
    //     history.push('/account');
    //     return null;
    // }
  }
}
export async function CheckReletedAcc() {
  await relatedAccsStore.getRelatedAccounts(
    authStore.decodeJwt.userId,
    authStore.access
  );
  let tempList = [];
  relatedAccsStore.relatedAccounts.map((user) => tempList.push(user.UserType));
  return tempList;
}

export function CheckStep(step) {
  let history = useHistory();
  try {
    let userType = jwt_decode(authStore.access).userType;
    if (userType == "Pedagog") {
      if (authStore.userData.CreatedGartenId !== 0) {
        history.push("/account");
        return null;
      }
      history.push("/registration/kindegarten");
      return null;
    }
  } catch {}

  if (localStorage.getItem("step") === null) {
    masterRegStore.setStep("invite");
  }
  if (
    localStorage.getItem("step") === "invite" &&
    step !== localStorage.getItem("step")
  ) {
    history.push("/registration/invite");
    return null;
  } else if (
    localStorage.getItem("step") === "master" &&
    step !== localStorage.getItem("step")
  ) {
    history.push("/registration/master");
    return null;
  } else if (
    localStorage.getItem("step") === "select" &&
    step !== localStorage.getItem("step")
  ) {
    history.push("/registration/select");
    return null;
    // } else if (
    //   localStorage.getItem("step") === "teacher" &&
    //   step !== localStorage.getItem("step")
    // ) {
    //   history.push("/registration/teacher");
    //   return null;
    // } else if (
    //   localStorage.getItem("step") === "parent" &&
    //   step !== localStorage.getItem("step")
    // ) {
    //   history.push("/registration/parent");
    //   return null;
  } else if (
    localStorage.getItem("step") === "questChild" &&
    step !== localStorage.getItem("step")
  ) {
    history.push("/registration/question/children");
    return null;
  } else if (
    localStorage.getItem("step") === "questKindegarten" &&
    step !== localStorage.getItem("step")
  ) {
    history.push("/registration/question/kindegarten");
    return null;
  } else if (
    localStorage.getItem("step") === "children" &&
    step !== localStorage.getItem("step")
  ) {
    history.push("/registration/children");
    return null;
  } else if (
    localStorage.getItem("step") === "kindegarten" &&
    step !== localStorage.getItem("step")
  ) {
    history.push("/registration/kindegarten");
    return null;
  }
}

function returnToSelect() {
  if (localStorage.getItem("step") === null) {
    localStorage.setItem("step", "select");
  }
}

export const RegistrationInvite = observer(() => {
  CheckMasterUser();
  authStore.setIsRegistrationTrue();
  registrationButtonStore.Clear();

  if (authStore.access !== null) {
    authStore.headerVisible = false;
  }

  return <RegistrationCard code={<RegStepInvite />} />;
});

export const RegistrationMaster = observer(() => {
  returnToSelect();
  emailStore.Clear();
  telephoneStore.Clear();
  newPasswordStore.Clear();
  confirmPasswordStore.Clear();
  authStore.setIsRegistrationTrue();
  registrationButtonStore.Clear();

  return <RegistrationCard code={<RegStep1 />} />;
});

export const RegistrationSelect = observer(() => {
  returnToSelect();

  authStore.headerVisible = false;

  authStore.setIsRegistrationTrue();
  registrationButtonStore.Clear();

  return <RegistrationCard code={<SelectRole />} />;
});
export const RegistrationParent = observer(() => {
  returnToSelect();

  authStore.headerVisible = false;

  authStore.setIsRegistrationTrue();
  registrationButtonStore.Clear();

  return (
    <RegistrationCard
      code={<RegStep3Parent />}
      text={"родителя"}
      class={"main_without_border"}
    />
  );
});
export const RegistrationTeacher = observer(() => {
  returnToSelect();

  authStore.headerVisible = false;

  authStore.setIsRegistrationTrue();
  registrationButtonStore.Clear();

  if (sessionStorage.getItem("inviteData") !== null) {
    if (
      JSON.parse(sessionStorage.getItem("inviteData")).UserType === "Pedagog"
    ) {
      nameStore.Name = JSON.parse(
        sessionStorage.getItem("inviteData")
      ).GroupMember.Name;
      surnameStore.Surname = JSON.parse(
        sessionStorage.getItem("inviteData")
      ).GroupMember.Surname;
      middleNameStore.middleName = JSON.parse(
        sessionStorage.getItem("inviteData")
      ).GroupMember.MiddleName;
      dropdownPositionStore.position = JSON.parse(
        sessionStorage.getItem("inviteData")
      ).GroupMember.Position.PositionName;
      dropdownPositionStore.positionId = JSON.parse(
        sessionStorage.getItem("inviteData")
      ).GroupMember.PositionId;
    }
  }

  return (
    <RegistrationCard
      code={
        <RegStep3Teacher disabled={sessionStorage.getItem("invite") !== null} />
      }
      text={"педагога"}
      class={"main_without_border"}
    />
  );
});

export const RegistrationDirector = observer(() => {
  returnToSelect();

  if (sessionStorage.getItem("inviteData") !== null) {
    if (
      JSON.parse(sessionStorage.getItem("inviteData")).UserType === "Pedagog"
    ) {
      nameStore.Name = JSON.parse(
        sessionStorage.getItem("inviteData")
      ).GroupMember.Name;
      surnameStore.Surname = JSON.parse(
        sessionStorage.getItem("inviteData")
      ).GroupMember.Surname;
      middleNameStore.middleName = JSON.parse(
        sessionStorage.getItem("inviteData")
      ).GroupMember.MiddleName;
      dropdownPositionStore.position = JSON.parse(
        sessionStorage.getItem("inviteData")
      ).GroupMember.Position.PositionName;
      dropdownPositionStore.positionId = JSON.parse(
        sessionStorage.getItem("inviteData")
      ).GroupMember.PositionId;
    }
  }

  return (
    <RegistrationCard
      code={
        <RegStep3Director
          disabled={sessionStorage.getItem("invite") !== null}
        />
      }
      text={"педагога"}
      class={"main_without_border"}
    />
  );
});

export const RegistrationKindegarten = observer(() => {
  returnToSelect();

  authStore.headerVisible = false;

  authStore.setIsRegistrationTrue();
  registrationButtonStore.Clear();

  return <RegistrationCard code={<RegStep3Kindergarden />} text={"садика"} />;
});
export const RegistrationChildren = observer(() => {
  returnToSelect();

  authStore.headerVisible = false;

  authStore.setIsRegistrationTrue();
  registrationButtonStore.Clear();

  if (sessionStorage.getItem("inviteData") !== null) {
    if (
      JSON.parse(sessionStorage.getItem("inviteData")).UserType === "Children"
    ) {
      nameStore.Name = JSON.parse(
        sessionStorage.getItem("inviteData")
      ).GroupMember.Name;
      surnameStore.Surname = JSON.parse(
        sessionStorage.getItem("inviteData")
      ).GroupMember.Surname;
    }
  }

  return <RegistrationCard code={<RegStep3Children />} text={"ребёнка"} />;
});
export const RegistrationQuestChildren = observer(() => {
  returnToSelect();
  authStore.setIsRegistrationTrue();
  authStore.headerVisible = false;

  registrationButtonStore.Clear();

  return (
    <RegistrationCard
      code={
        <RegStepQuestion
          question={"Зарегистрировать ребёнка?"}
          link={"/registration/children"}
          children={true}
        />
      }
    />
  );
});

export const RegistrationDirectorName = observer(() => {
  return <RegistrationCard code={<DirectorName />} />;
});

export const RegistrationDirectorEduStep = observer(() => {
  return <RegistrationCard code={<DirectorEduStep />} />;
});

export const RegistrationEduNotFound = observer(() => {
  return <RegistrationCard code={<EduNotFound />} />;
});

export const DashboardPage = observer(() => {
  return <RegistrationCard code={<Dashboard />} />;
});

export const DashboardMobilePage = observer(() => {
  return <RegistrationCard code={<DashboardMobile />} />;
});

export const DashboardMobileCertPage = observer(() => {
  return <RegistrationCard code={<DashboardMobileCert />} />;
});

export const DashboardMobileSecurityPage = observer(() => {
  return <RegistrationCard code={<DashboardMobileSecurity />} />;
});

export const DashboardMobileTeachersPage = observer(() => {
  return <RegistrationCard code={<DashboardMobileTeachers />} />;
});

export const DashboardMobileAccountPage = observer(() => {
  return <RegistrationCard code={<DashboardMobileAccount />} />;
});

export const DashboardMobileQuizPage = observer(() => {
  return <RegistrationCard code={<DashboardMobileQuiz />} />;
});

export const DashboardMobileKidsPage = observer(() => {
  return <RegistrationCard code={<DashboardMobileKids />} />;
});

export const DashboardMobileCodePage = observer(() => {
  return <RegistrationCard code={<DashboardMobileCode />} />;
});

export const DashboardMobileGroupsPage = observer(() => {
  return <RegistrationCard code={<DashboardMobileGroups />} />;
});

export const DashboardMobileResultsPage = observer(() => {
  return <RegistrationCard code={<DashboardMobileResults />} />;
});

export const Restore2Page = observer(() => {
  return <RegistrationCard code={<Restore2 />} />;
});

export const RegistrationByCodePage = observer(() => {
  return <RegistrationCard code={<RegistrationByCode />} />;
});

export const RegStep3TeacherCodePage = observer(() => {
  return <RegistrationCard code={<RegStep3TeacherCode />} />;
});

export const RegStep3ParentCodePage = observer(() => {
  return <RegistrationCard code={<RegStep3ParentCode />} />;
});

export const TeacherNamePage = observer(() => {
  return <RegistrationCard code={<TeacherName />} />;
});

export const ParentNamePage = observer(() => {
  return <RegistrationCard code={<ParentName />} />;
});

export const RegistrationQuestKindegarten = observer(() => {
  returnToSelect();
  authStore.setIsRegistrationTrue();
  authStore.headerVisible = false;

  registrationButtonStore.Clear();

  return (
    <RegistrationCard
      code={
        <RegStepQuestion
          question={"Зарегистрировать дет. сад?"}
          link={"/registration/kindegarten"}
          children={false}
        />
      }
    />
  );
});

const RegistrationCard = observer((props) => {
  let history = useHistory();

  if (authStore.localStoreBlock) {
    history.push("/authorization");
    return null;
  }
  return (
    <>
      <div className={`main pt_header ${props.class}`}>{props.code}</div>
    </>
  );
});
