import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import AvatarSlider from "../Organism/AvatarSlider";
import {LeftHeader} from "../../../../Structure/LeftHeader/leftHeader";
import {RightHeader} from "../../../../Structure/RightHeader/rightHeader";
import {Link} from "react-router-dom";
import ChildGroupsStore from "../../../../Stores/ChildGroups/ChildGroupsStore";
import reportStore from "../../../../Stores/Report/ReportStore";
import LevelSelector from "../Molecules/LevelSelector";

import {ReportDashboard} from "../Template/ReportDashBoard";
import Button from "../Atoms/Buttons/Button";



const TutorReport = () => {

  const {isChildren} = reportStore;
  const dataAvatars = reportStore.isChildren ? ChildGroupsStore.GroupChlid : ChildGroupsStore.Groups
  const activeID = reportStore.isChildren ? reportStore.childActiveID : reportStore.groupActiveID;

  useEffect(() => {
    if (!reportStore.isChildren) {
      ChildGroupsStore.GetGroups()
    }
    if (reportStore.isChildren){
      ChildGroupsStore.GetChildByGroup()
    }

  },[isChildren])



  return (
    <>
      <LeftHeader/>
      <RightHeader/>

      <div className="main mt_215 pt_header">

        <p className="title_greetings">
          <Link to={'/account'} className="grey_text">АККАУНТ ПЕДАГОГА/</Link>
          {isChildren ?

              <>
                <Link to={'/report'} className="grey_text">ОТЧЕТЫ/</Link>
                <Link to={'#'} onClick={reportStore.setIsChildren} className="blue_text">{ChildGroupsStore.Groups[reportStore.groupActiveID].Name}</Link>
              </>

            : <Link to={'/report'} className="blue_text">ОТЧЕТЫ</Link>
          }
        </p>

        <div className="row content_margin">

          <div className="col-sm-12">
            <div>
              <p style={{margin: 0, padding: 0}}>{isChildren ? "ВЫБЕРИТЕ РЕБЁНКА" : "ВЫБЕРИТЕ ГРУППУ"}</p>
              <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
              </svg>
            </div>
          </div>

          <div className="col-sm-12">
            {dataAvatars[0] ? <AvatarSlider list={dataAvatars}/> : "Нет данных"}
          </div>
          <div className="col-sm-12">
            <div className="row " style={{margin: '24px 20px 0 20px'}}>

              {(activeID != null || !dataAvatars[0] || isChildren) &&
              <Button
                primary
                text={!reportStore.isChildren ? "Отчёты по детям" : "Отчеты по группам"}
                style={{width: '100%', margin: '0px'}}
                onClick={()=> {reportStore.setIsChildren()}}
              />}
            </div>
          </div>
          <div className="col-sm-12">
            <div className="row " style={{margin: '24px 20px 0 20px'}}>
              {
                activeID != null && <LevelSelector/>
              }
            </div>
          </div>
          <div className="col-sm-12">
            {reportStore.levelOlymp != null  && <ReportDashboard/> }
          </div>


        </div>
      </div>
    </>
  );
};

export default observer(TutorReport);