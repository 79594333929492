export const Card = ({ name, onClick, svg, text }) => {
  return (
    <div
      className="selectrole-Card"
      style={{
        width: "288px",
        height: "312px",
        background: "#F5F7FA",
        borderRadius: "16px",
      }}
    >
      <div
        style={{
          marginTop: "32px",
        }}
        dangerouslySetInnerHTML={{ __html: svg }}
      />

      <div className="selectrole-Card-infobox">
        <div
          style={{
            color: "#2E2E2E",
            fontSize: "16px",
            fontWeight: "400",
            fontStyle: "normal",
            lineHeight: "150%",
            letterSpacing: "-0.01em",
            margin: "16px 24px 0 24px",
            WebkitUserSelect: "none",
            msUserSelect: "none",
            userSelect: "none",
          }}
        >
          {text}
        </div>

        <div
          onClick={onClick}
          className="button-blue"
          style={{
            margin: "24px auto",
            background: "#4FC1E9",
            borderRadius: "8px",
            width: "200px",
            height: "56px",
            textAlign: "center",
            cursor: "pointer",
            WebkitUserSelect: "none",
            msUserSelect: "none",
            userSelect: "none",
            border: "1px solid #4FC1E9",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              paddingTop: "15px",
              fontWeight: "700",
              fontSize: "20px",
              lineHeight: "24px",
            }}
          >
            {name}
          </div>
        </div>
      </div>
    </div>
  );
};
