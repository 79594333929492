import React from "react";
import { observer } from "mobx-react";
import modalStore from "./modalStore";
import authStore from "../../Stores/AuthStore/AuthStore";
import { Link, useHistory } from "react-router-dom";

export const Modal = observer(() => {
  return (
    <>
      <div
        className="modal_component"
        id="modal_message"
        style={{
          display: "none",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          flexDirection: "column",
          background: "#F5F7FA",
          borderRadius: "5px",
          zIndex: 999,
        }}
      >
        <div
          className="modal_component"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <p
            className="modal_component text_modal_top"
            style={{
              margin: 0,
              padding: "12px 38px 0 38px",
              textAlign: "center",
            }}
          >
            {modalStore.type}
          </p>
        </div>
        <div
          className="modal_component"
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: "10px 0 30px 0",
          }}
        >
          <svg
            className="modal_component"
            width="60"
            height="5"
            viewBox="0 0 60 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              className="modal_component"
              width="60"
              height="5"
              rx="2.5"
              fill="#4FC1E9"
            />
          </svg>
        </div>
        <div
          className="modal_component"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <p
            className="modal_component"
            style={{
              margin: "0 0 20px 0",
              padding: 0,
              fontSize: "14px",
              color: "rgba(51, 51, 51, 0.5)",
            }}
          >
            {modalStore.message}
          </p>
        </div>
        <div style={{ display: "flex" }}>
          {!authStore.isRegistration ? (
            modalStore.EndReg ? (
              <button
                id="registration_button"
                className={"buttons button_blue"}
                style={{ width: "100%", margin: "0 38px 14px 38px" }}
                onClick={
                  {
                    /*() => closeModal()*/
                  }
                }
              >
                Хорошо3
              </button>
            ) : (
              <Link
                to={"/authorization"}
                style={{
                  width: "100%",
                  display: "flex",
                  textDecoration: "none",
                }}
              >
                <button
                  id="registration_button"
                  className={"buttons button_blue"}
                  style={{ width: "100%", margin: "0 38px 14px 38px" }}
                  onClick={
                    {
                      /*() => closeModal()*/
                    }
                  }
                >
                  Хорошо2
                </button>
              </Link>
            )
          ) : (
            <button
              id="registration_button"
              className={"buttons button_blue"}
              style={{ width: "100%", margin: "0 38px 14px 38px" }}
              onClick={() => closeModal()}
            >
              Хорошо1
            </button>
          )}
        </div>
      </div>
    </>
  );
});

let closeModal = () => {
  document.getElementById("modal_message").style.display = "none";
  document.getElementById("opacity").style.display = "none";
};

export default Modal;
