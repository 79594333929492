import React, { useState } from "react";
import { observer } from "mobx-react";
import "react-select-search/style.css";
import { DashboardMobileHeader } from "./DashboardMobileHeader";
import { Link } from "react-router-dom";
import "./DashboardMobile.css";

export const DashboardMobile = observer((props) => {
  //ChangeMe
  // director; teacher; parent
  let accountType;

  if (localStorage.getItem("accountType") != null) {
    accountType = localStorage.getItem("accountType");
  } else {
    window.location.replace("/");
  }
  return (
    <>
      <DashboardMobileHeader accountType={accountType} />
      <div className="DashboardMobile-Content">
        <div className="DashboardMobile-Menu">
          {accountType === "parent" ? (
            <Link
              to="/dashboard-mobile/quiz"
              style={{ textDecoration: "none" }}
            >
              <button>
                <div className="DashboardMobile-MenuElementFlex">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9293 7.99988L6.66659 5.15788V10.8419L10.9293 7.99988ZM12.9173 8.27722L5.85125 12.9879C5.80106 13.0213 5.74274 13.0404 5.68251 13.0433C5.62229 13.0462 5.56241 13.0327 5.50925 13.0042C5.4561 12.9758 5.41165 12.9334 5.38066 12.8817C5.34967 12.83 5.33328 12.7708 5.33325 12.7105V3.28922C5.33328 3.22892 5.34967 3.16976 5.38066 3.11804C5.41165 3.06633 5.4561 3.02398 5.50925 2.99552C5.56241 2.96706 5.62229 2.95355 5.68251 2.95644C5.74274 2.95932 5.80106 2.97848 5.85125 3.01188L12.9173 7.72255C12.9629 7.75299 13.0003 7.79423 13.0262 7.84261C13.0521 7.89099 13.0657 7.94501 13.0657 7.99988C13.0657 8.05475 13.0521 8.10878 13.0262 8.15715C13.0003 8.20553 12.9629 8.24678 12.9173 8.27722Z"
                      fill="#92A0B4"
                    />
                  </svg>
                </div>
                <span
                  style={{
                    color: "#2E2E2E",
                  }}
                >
                  Олимпиада
                </span>
              </button>
            </Link>
          ) : (
            <></>
          )}
          <Link
            to="/dashboard-mobile/account"
            style={{ textDecoration: "none" }}
          >
            <button>
              <div className="DashboardMobile-MenuElementFlex">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.00016 14.6667C4.31816 14.6667 1.3335 11.682 1.3335 8.00004C1.3335 4.31804 4.31816 1.33337 8.00016 1.33337C11.6822 1.33337 14.6668 4.31804 14.6668 8.00004C14.6668 11.682 11.6822 14.6667 8.00016 14.6667ZM4.6755 12.1707C5.61919 12.9252 6.79195 13.3353 8.00016 13.3334C9.3135 13.3334 10.5155 12.8587 11.4448 12.072C11.0106 11.6265 10.4915 11.2726 9.91819 11.0312C9.34484 10.7899 8.72891 10.6659 8.10683 10.6667C7.46189 10.666 6.82383 10.7993 6.23314 11.0582C5.64245 11.3171 5.11202 11.6959 4.6755 12.1707ZM3.74416 11.2134C4.30447 10.6188 4.98061 10.1452 5.73092 9.82185C6.48123 9.49852 7.28982 9.33228 8.10683 9.33337C8.8946 9.33235 9.67481 9.48695 10.4027 9.78829C11.1305 10.0896 11.7917 10.5318 12.3482 11.0894C12.9189 10.2861 13.2559 9.34047 13.3219 8.35732C13.3878 7.37417 13.1801 6.39196 12.7218 5.51968C12.2635 4.64739 11.5725 3.91912 10.7255 3.41567C9.87844 2.91222 8.90849 2.65327 7.92323 2.66754C6.93798 2.68182 5.97594 2.96876 5.14384 3.49655C4.31175 4.02433 3.64214 4.77231 3.20929 5.65751C2.77645 6.54271 2.59729 7.53052 2.69171 8.51134C2.78612 9.49217 3.15041 10.4277 3.74416 11.214V11.2134ZM8.00016 8.66671C7.29292 8.66671 6.61464 8.38576 6.11454 7.88566C5.61445 7.38556 5.3335 6.70728 5.3335 6.00004C5.3335 5.2928 5.61445 4.61452 6.11454 4.11442C6.61464 3.61433 7.29292 3.33337 8.00016 3.33337C8.70741 3.33337 9.38568 3.61433 9.88578 4.11442C10.3859 4.61452 10.6668 5.2928 10.6668 6.00004C10.6668 6.70728 10.3859 7.38556 9.88578 7.88566C9.38568 8.38576 8.70741 8.66671 8.00016 8.66671ZM8.00016 7.33337C8.35379 7.33337 8.69292 7.1929 8.94297 6.94285C9.19302 6.6928 9.3335 6.35366 9.3335 6.00004C9.3335 5.64642 9.19302 5.30728 8.94297 5.05723C8.69292 4.80718 8.35379 4.66671 8.00016 4.66671C7.64654 4.66671 7.3074 4.80718 7.05735 5.05723C6.80731 5.30728 6.66683 5.64642 6.66683 6.00004C6.66683 6.35366 6.80731 6.6928 7.05735 6.94285C7.3074 7.1929 7.64654 7.33337 8.00016 7.33337Z"
                    fill="#92A0B4"
                  />
                </svg>
              </div>
              <span
                style={{
                  color: "#2E2E2E",
                }}
              >
                Аккаунт
              </span>
            </button>
          </Link>
          {accountType === "teacher" ? (
            <Link
              to="/dashboard-mobile/groups"
              style={{ textDecoration: "none" }}
            >
              <button>
                <div className="DashboardMobile-MenuElementFlex">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.33341 9.50142V10.8947C8.73003 10.6814 8.08426 10.616 7.45035 10.7039C6.81644 10.7919 6.21288 11.0307 5.69037 11.4002C5.16786 11.7698 4.74164 12.2593 4.44752 12.8277C4.1534 13.3961 3.99996 14.0268 4.00008 14.6667L2.66675 14.6661C2.66654 13.852 2.8527 13.0487 3.21095 12.3177C3.5692 11.5867 4.09004 10.9474 4.73356 10.4488C5.37708 9.95017 6.1262 9.6055 6.92352 9.44118C7.72083 9.27685 8.54519 9.29723 9.33341 9.50075V9.50142ZM8.00008 8.66675C5.79008 8.66675 4.00008 6.87675 4.00008 4.66675C4.00008 2.45675 5.79008 0.666748 8.00008 0.666748C10.2101 0.666748 12.0001 2.45675 12.0001 4.66675C12.0001 6.87675 10.2101 8.66675 8.00008 8.66675ZM8.00008 7.33342C9.47341 7.33342 10.6667 6.14008 10.6667 4.66675C10.6667 3.19341 9.47341 2.00008 8.00008 2.00008C6.52675 2.00008 5.33341 3.19341 5.33341 4.66675C5.33341 6.14008 6.52675 7.33342 8.00008 7.33342ZM12.0001 11.3334V9.33342H13.3334V11.3334H15.3334V12.6667H13.3334V14.6667H12.0001V12.6667H10.0001V11.3334H12.0001Z"
                      fill="#92A0B4"
                    />
                  </svg>
                </div>
                <span
                  style={{
                    color: "#2E2E2E",
                  }}
                >
                  Группы
                </span>
              </button>
            </Link>
          ) : (
            <></>
          )}
          {accountType === "parent" ? (
            <Link
              to="/dashboard-mobile/kids"
              style={{ textDecoration: "none" }}
            >
              <button>
                <div className="DashboardMobile-MenuElementFlex">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.33317 9.50117V10.8945C8.72978 10.6812 8.08402 10.6157 7.45011 10.7037C6.81619 10.7917 6.21264 11.0304 5.69012 11.4C5.16761 11.7695 4.7414 12.259 4.44728 12.8274C4.15316 13.3958 3.99972 14.0265 3.99984 14.6665L2.6665 14.6658C2.6663 13.8518 2.85245 13.0484 3.2107 12.3174C3.56895 11.5864 4.0898 10.9471 4.73332 10.4485C5.37684 9.94993 6.12595 9.60526 6.92327 9.44093C7.72059 9.27661 8.54494 9.29699 9.33317 9.5005V9.50117ZM7.99984 8.6665C5.78984 8.6665 3.99984 6.8765 3.99984 4.6665C3.99984 2.4565 5.78984 0.666504 7.99984 0.666504C10.2098 0.666504 11.9998 2.4565 11.9998 4.6665C11.9998 6.8765 10.2098 8.6665 7.99984 8.6665ZM7.99984 7.33317C9.47317 7.33317 10.6665 6.13984 10.6665 4.6665C10.6665 3.19317 9.47317 1.99984 7.99984 1.99984C6.5265 1.99984 5.33317 3.19317 5.33317 4.6665C5.33317 6.13984 6.5265 7.33317 7.99984 7.33317ZM11.9998 11.3332V9.33317H13.3332V11.3332H15.3332V12.6665H13.3332V14.6665H11.9998V12.6665H9.99984V11.3332H11.9998Z"
                      fill="#92A0B4"
                    />
                  </svg>
                </div>
                <span
                  style={{
                    color: "#2E2E2E",
                  }}
                >
                  Дети
                </span>
              </button>
            </Link>
          ) : (
            <></>
          )}
          {accountType === "director" ? (
            <Link
              to="/dashboard-mobile/teachers"
              style={{ textDecoration: "none" }}
            >
              <button>
                <div className="DashboardMobile-MenuElementFlex">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.33317 9.50129V10.8946C8.72978 10.6813 8.08402 10.6159 7.45011 10.7038C6.81619 10.7918 6.21264 11.0306 5.69012 11.4001C5.16761 11.7696 4.7414 12.2592 4.44728 12.8276C4.15316 13.396 3.99972 14.0266 3.99984 14.6666L2.6665 14.666C2.6663 13.8519 2.85245 13.0486 3.2107 12.3176C3.56895 11.5865 4.0898 10.9472 4.73332 10.4486C5.37684 9.95005 6.12595 9.60538 6.92327 9.44106C7.72059 9.27673 8.54494 9.29711 9.33317 9.50063V9.50129ZM7.99984 8.66663C5.78984 8.66663 3.99984 6.87663 3.99984 4.66663C3.99984 2.45663 5.78984 0.666626 7.99984 0.666626C10.2098 0.666626 11.9998 2.45663 11.9998 4.66663C11.9998 6.87663 10.2098 8.66663 7.99984 8.66663ZM7.99984 7.33329C9.47317 7.33329 10.6665 6.13996 10.6665 4.66663C10.6665 3.19329 9.47317 1.99996 7.99984 1.99996C6.5265 1.99996 5.33317 3.19329 5.33317 4.66663C5.33317 6.13996 6.5265 7.33329 7.99984 7.33329ZM11.9998 11.3333V9.33329H13.3332V11.3333H15.3332V12.6666H13.3332V14.6666H11.9998V12.6666H9.99984V11.3333H11.9998Z"
                      fill="#92A0B4"
                    />
                  </svg>
                </div>
                <span
                  style={{
                    color: "#2E2E2E",
                  }}
                >
                  Педагоги
                </span>
              </button>
            </Link>
          ) : (
            <></>
          )}
          <Link
            to="/dashboard-mobile/security"
            style={{ textDecoration: "none" }}
          >
            <button>
              <div className="DashboardMobile-MenuElementFlex">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_137_12570)">
                    <path
                      d="M2.522 1.88396L8 0.666626L13.478 1.88396C13.626 1.91687 13.7584 1.99927 13.8533 2.11756C13.9482 2.23586 14 2.38297 14 2.53463V9.19263C14 9.85112 13.8373 10.4994 13.5266 11.08C13.2158 11.6605 12.7666 12.1554 12.2187 12.5206L8 15.3333L3.78133 12.5206C3.23352 12.1554 2.78431 11.6607 2.47357 11.0803C2.16282 10.4998 2.00016 9.85167 2 9.19329V2.53463C2.00003 2.38297 2.05176 2.23586 2.14666 2.11756C2.24156 1.99927 2.37396 1.91687 2.522 1.88396ZM3.33333 3.06929V9.19263C3.33334 9.6316 3.44172 10.0638 3.64884 10.4508C3.85597 10.8379 4.15543 11.1678 4.52067 11.4113L8 13.7313L11.4793 11.4113C11.8445 11.1678 12.1439 10.838 12.351 10.4511C12.5581 10.0642 12.6665 9.63215 12.6667 9.19329V3.06929L8 2.03329L3.33333 3.06929ZM8.66667 6.66663H10.6667L7.33333 11.3333V7.99996H5.33333L8.66667 3.33329V6.66663Z"
                      fill="#92A0B4"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_137_12570">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <span
                style={{
                  color: "#2E2E2E",
                }}
              >
                Защита
              </span>
            </button>
          </Link>
          {accountType === "parent" || accountType === "teacher" ? (
            <Link
              to="/dashboard-mobile/results"
              style={{ textDecoration: "none" }}
            >
              <button>
                <div className="DashboardMobile-MenuElementFlex">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00016 14.6668C4.31816 14.6668 1.3335 11.6822 1.3335 8.00016C1.3335 5.01483 3.2955 2.48816 6.00016 1.63883V3.05483C4.85411 3.52019 3.90536 4.3693 3.3162 5.45691C2.72704 6.54451 2.53408 7.80303 2.77033 9.01719C3.00658 10.2314 3.65736 11.3257 4.61134 12.113C5.56532 12.9004 6.76323 13.3318 8.00016 13.3335C9.06268 13.3335 10.101 13.0162 10.9821 12.4223C11.8631 11.8285 12.5468 10.985 12.9455 10.0002H14.3615C13.5122 12.7048 10.9855 14.6668 8.00016 14.6668ZM14.6335 8.66683H7.3335V1.36683C7.55283 1.34483 7.7755 1.3335 8.00016 1.3335C11.6822 1.3335 14.6668 4.31816 14.6668 8.00016C14.6668 8.22483 14.6555 8.4475 14.6335 8.66683ZM8.66683 2.70816V7.3335H13.2922C13.1438 6.15857 12.6087 5.06639 11.7713 4.22899C10.9339 3.39159 9.84176 2.85655 8.66683 2.70816Z"
                      fill="#92A0B4"
                    />
                  </svg>
                </div>
                <span
                  style={{
                    color: "#2E2E2E",
                  }}
                >
                  Отчеты
                </span>
              </button>
            </Link>
          ) : (
            <></>
          )}
          {accountType === "parent" || accountType === "teacher" ? (
            <Link
              to="/dashboard-mobile/code"
              style={{ textDecoration: "none" }}
            >
              <button>
                <div className="DashboardMobile-MenuElementFlex">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.66683 3.33333V12.6667H13.3335V3.33333H2.66683ZM2.00016 2H14.0002C14.177 2 14.3465 2.07024 14.4716 2.19526C14.5966 2.32029 14.6668 2.48986 14.6668 2.66667V13.3333C14.6668 13.5101 14.5966 13.6797 14.4716 13.8047C14.3465 13.9298 14.177 14 14.0002 14H2.00016C1.82335 14 1.65378 13.9298 1.52876 13.8047C1.40373 13.6797 1.3335 13.5101 1.3335 13.3333V2.66667C1.3335 2.48986 1.40373 2.32029 1.52876 2.19526C1.65378 2.07024 1.82335 2 2.00016 2ZM4.00016 4.66667H6.00016V11.3333H4.00016V4.66667ZM6.66683 4.66667H8.00016V11.3333H6.66683V4.66667ZM8.66683 4.66667H9.3335V11.3333H8.66683V4.66667ZM10.0002 4.66667H12.0002V11.3333H10.0002V4.66667Z"
                      fill="#92A0B4"
                    />
                  </svg>
                </div>
                <span
                  style={{
                    color: "#2E2E2E",
                  }}
                >
                  Ввести код
                </span>
              </button>
            </Link>
          ) : (
            <></>
          )}
          {accountType === "director" ? (
            <Link
              to="/dashboard-mobile/cert"
              style={{ textDecoration: "none" }}
            >
              <button>
                <div className="DashboardMobile-MenuElementFlex">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.6668 14.6667H3.3335C2.80306 14.6667 2.29436 14.456 1.91928 14.0809C1.54421 13.7058 1.3335 13.1971 1.3335 12.6667V2.00004C1.3335 1.82323 1.40373 1.65366 1.52876 1.52864C1.65378 1.40361 1.82335 1.33337 2.00016 1.33337H11.3335C11.5103 1.33337 11.6799 1.40361 11.8049 1.52864C11.9299 1.65366 12.0002 1.82323 12.0002 2.00004V10H14.6668V12.6667C14.6668 13.1971 14.4561 13.7058 14.081 14.0809C13.706 14.456 13.1973 14.6667 12.6668 14.6667ZM12.0002 11.3334V12.6667C12.0002 12.8435 12.0704 13.0131 12.1954 13.1381C12.3204 13.2631 12.49 13.3334 12.6668 13.3334C12.8436 13.3334 13.0132 13.2631 13.1382 13.1381C13.2633 13.0131 13.3335 12.8435 13.3335 12.6667V11.3334H12.0002ZM10.6668 13.3334V2.66671H2.66683V12.6667C2.66683 12.8435 2.73707 13.0131 2.86209 13.1381C2.98712 13.2631 3.15669 13.3334 3.3335 13.3334H10.6668ZM4.00016 4.66671H9.3335V6.00004H4.00016V4.66671ZM4.00016 7.33337H9.3335V8.66671H4.00016V7.33337ZM4.00016 10H7.3335V11.3334H4.00016V10Z"
                      fill="#92A0B4"
                    />
                  </svg>
                </div>
                <span
                  style={{
                    color: "#2E2E2E",
                  }}
                >
                  Сертификаты
                </span>
              </button>
            </Link>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
});
