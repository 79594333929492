import {observer} from "mobx-react";
import reportStore from "../../../Stores/Report/ReportStore";
import React from "react";
import {RecStore} from "./Molecules/Recomendation/RecStore";

export const LevelButton = observer((props) => {
    return <>
        <div className="form_group" style={{marginRight: '9px'}}>
            <button id="button_level_1" className={reportStore.SelectedLevel === props.level? "buttons button_transparent button_level_select": "buttons button_transparent"} onClick={() => {reportStore.SelectLevel(props.level); RecStore.setLoad(true)}}
                    style={{width: '100%', margin: 0, padding: 0}}>{props.text}
            </button>
        </div>
    </>
})