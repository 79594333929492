import React from 'react';

const Button = (props) => {
  const {text, primary} = props
  const classAdd = primary ? "button_blue" : "button_green";

  return (
    <button {...props} className={`buttons ${classAdd}`}>{text}</button>
  );
};

export default Button;