import React from "react";
import { observer } from "mobx-react";
import {MainProfile} from './ProfileUser/mainProfile'
import {ImageProfile} from './ProfileUser/imageProfile'
import authStore from '../../../Stores/AuthStore/AuthStore'
import {ParentProfile} from './ProfileUser/parentProfile'
import {TeacherProfile} from './ProfileUser/teacherProfile'
import {ChildProfile} from './ProfileUser/childProfile'
import teacherStore from './ProfileUser/StoreProfile/teacherProfile'
import parentStore from './ProfileUser/StoreProfile/parentProfile'
import dropdownPositionStore from "../../../Components/Inputs/Position/dropdownPositionStore";
import ProfileButtonUI from "../../../Components/Buttons/ProfileButton/ButtonProfileUI";
import buttonProfileStore from "../../../Components/Buttons/ProfileButton/buttonProfileStore";
import countryStore from "../../../Components/Inputs/Country/CountryStore";
import stateStore from "../../../Components/Inputs/State/StateStore";
import {cityStore} from "../../../Components/Inputs/City/cityStore";
import {nameStore} from "../../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../../Components/Inputs/Surname/surnameStore";
import {middleNameStore} from "../../../Components/Inputs/middleName/middleNameStore";
import {genderStore} from "../../../Components/Inputs/gender/genderStore";
import {LeftHeader} from "../../../Structure/LeftHeader/leftHeader";
import {RightHeader} from "../../../Structure/RightHeader/rightHeader";
import {Link} from "react-router-dom";
import {AvatarCrop} from "../../../Components/AvatarCrop/AvavarCropUI/AvatarCrop";
import cropStore from "../../../Components/AvatarCrop/AvatarCropStore/cropStore";
import {telephoneStore} from "../../../Components/Inputs/Telephone/TelephoneStore";

export const ProfileAccount = observer( () => {

    return <>
        <LeftHeader/>
        <RightHeader/>

        <div className="main mt_215 pt_header main_relative">

            {authStore.userData.UserType === "Parent"?
                <p className="title_greetings">
                    <Link to={'/account'} className="grey_text">АККАУНТ РОДИТЕЛЯ/</Link>
                    <Link to={'/account'} className="blue_text">ПРОФИЛЬ</Link>
                </p> : ""}
            {authStore.userData.UserType === "Pedagog"?
                <p className="title_greetings">
                    <Link to={'/account'} className="grey_text">АККАУНТ ПЕДАГОГА/</Link>
                    <Link to={'/account'} className="blue_text">ПРОФИЛЬ</Link>
                </p>: ""}
            {authStore.userData.UserType === "Children"?
                <p className="title_greetings">
                    <Link to={'/account'} className="grey_text">АККАУНТ РЕБЁНКА/</Link>
                    <Link to={'/account'} className="blue_text">ПРОФИЛЬ</Link>
                </p> : ""}

            <div className="row content_margin">

                <div className="row account_wrap">
                    <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12" style={{minWidth: '212px'}}>
                        <ImageProfile/>
                    </div>

                    <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12 acc_data account_shrink">
                        <div>
                            <p style={{margin: 0, padding: 0}}>ОСНОВНЫЕ ДАННЫЕ АККАУНТА</p>
                            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
                            </svg>
                        </div>
                        {authStore.decodeJwt.userType === "Parent"? <ParentProfile disabled={!buttonProfileStore.buttonBlock}/> : ""}
                        {authStore.decodeJwt.userType === "Pedagog"? <TeacherProfile disabled={!buttonProfileStore.buttonBlock}/> : ""}
                        {authStore.decodeJwt.userType === "Children"? <ChildProfile disabled={!buttonProfileStore.buttonBlock}/> : ""}
                    </div>
                </div>

                <MainProfile/>
                <div className="col-sm-12">
                    {!buttonProfileStore.buttonBlock?
                        <div className="form_group">
                            <div className="mt-10 ml-20 mr-20 form_row pl-5 pr-5">
                                {authStore.decodeJwt.userType === "Children"?
                                <></> : <>
                                        <ProfileButtonUI color={"button_blue"} func={() => {buttonProfileStore.SetButtonBlock(); if(countryStore.country === 'Россия'){stateStore.FocusState()}}} text={'Изменить данные'} disabled={buttonProfileStore.buttonBlock}/>
                                    </>}
                            </div>
                        </div>
                        :
                        <>
                            <ButtonType userType={'Parent'}
                                        undo={() => {parentStore.getUserData(); buttonProfileStore.SetButtonBlock();
                                            buttonProfileStore.saveButtonBlock = true;
                                            nameStore.Clear(); surnameStore.Clear(); middleNameStore.Clear(); telephoneStore.Clear();
                                            countryStore.Clear(); stateStore.Clear();  cityStore.Clear(); genderStore.Clear();
                                            parentStore.getUserData()}}
                                        send={() => {parentStore.UpdateParent()}}
                            />
                            <ButtonType userType={'Pedagog'}
                                        undo={() => {parentStore.getUserData(); buttonProfileStore.SetButtonBlock();
                                            buttonProfileStore.saveButtonBlock = true;
                                            cityStore.Clear(); nameStore.Clear(); surnameStore.Clear(); middleNameStore.Clear();
                                            dropdownPositionStore.Clear(); genderStore.Clear();
                                            countryStore.Clear(); stateStore.Clear(); telephoneStore.Clear();
                                            teacherStore.getUserData()}}
                                        send={() => {teacherStore.UpdateTeacher()}}
                            />
                        </>
                    }
                </div>
            </div>


            {cropStore.openCropModal? <><AvatarCrop/><div onClick={() => {cropStore.closeCropModal()}} className="crop_opacity"/></>: <></>}
        </div>
    </>
})

export const ButtonType = observer((props) => {
    return <>
        {authStore.decodeJwt.userType === props.userType?
            <div className="form_row">
                <div className="col-md-6 col-sm-12">
                    <div className="form_group mt-10 ml-20 mr-5 mb-sm-5">
                        <ProfileButtonUI color={"button_green"} func={props.send} text={'СОХРАНИТЬ'} disabled={buttonProfileStore.saveButtonBlock}/>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form_group mt-10 ml-5 mr-20">
                        <ProfileButtonUI color={"button_blue"} func={props.undo} text={'ОТМЕНА'} disabled={!buttonProfileStore.buttonBlock}/>
                    </div>
                </div>
            </div>
            :
            <></>}
    </>
})