import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";


import ReportDashboardGroup from "../Organism/ReportDashboardGroup";
import reportStore from "../../../../Stores/Report/ReportStore";
import ReportDashboardChildren from "../Organism/ReportDashboardChildren";



export const ReportDashboard = observer((props) => {
  return (

    <div className="row" style={{margin: '24px 20px 0 20px'}}>





      {!reportStore.isChildren ?
          <ReportDashboardGroup/>
          :
          <ReportDashboardChildren/>

      }

    </div>

  )
})