import React, { useState } from "react";
import { observer } from "mobx-react";
import "react-select-search/style.css";
import { DashboardMobileHeader } from "./DashboardMobileHeader";
import "./DashboardMobile.css";
import UIBlockButton, {
  UIBlockButtonColor,
} from "../../../../../../Components/Katelinlis/Button.tsx";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement);

export const DashboardMobileResults = observer((props) => {
  let accountType;

  if (localStorage.getItem("accountType") != null) {
    accountType = localStorage.getItem("accountType");
  } else {
    window.location.replace("/");
  }

  const KidSelection = ({ id, Name, Surname, setSelectedKid }) => {
    return (
      <div className="DashboardMobileQuizSelectKid-Card">
        <h2>
          {id}. {Name} {Surname}
        </h2>
        <button
          className="teacherStatusBlock-invite"
          onClick={() => {
            // ChangeMe
            setSelectedKid(id);
          }}
        >
          Выбрать
        </button>
      </div>
    );
  };

  const BarChart = ({ Label, Percent }) => {
    return (
      <div className="barChart-bar">
        <div className="barChartBar-bg"></div>
        <div className="barChartBar-fg" style={{ width: Percent + "%" }}></div>
        <span className="barChartBar-label">{Label}</span>
        <span className="barChartBar-score">{Percent}</span>
      </div>
    );
  };

  const LevelStatsItem = ({ Color, Label, Time }) => {
    return (
      <div className="levelstats-item">
        <div className="levelstats-colorblock">
          <figure className="levelstats-color">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="9" cy="9" r="9" fill={Color} />
            </svg>
          </figure>
          <span className="levelstats-label">{Label}</span>
        </div>

        <span className="levelstats-time">{Time}</span>
      </div>
    );
  };

  const GroupSelection = ({ id, Group, setSelectedGroup }) => {
    return (
      <div className="DashboardMobileQuizSelectKid-Card">
        <h2>
          {id}. {Group}
        </h2>
        <button
          className="teacherStatusBlock-invite"
          onClick={() => {
            // ChangeMe
            setSelectedGroup(Group);
          }}
        >
          Выбрать
        </button>
      </div>
    );
  };

  const [SelectedGroup, setSelectedGroup] = useState(-1);
  const [SelectedKid, setSelectedKid] = useState(-1);

  return (
    <>
      <DashboardMobileHeader accountType={accountType} />
      <div className="DashboardMobile-Content">
        <div
          className="DashboardMobileQuiz-SelectKid"
          id="DashboardMobileResults"
        >
          {accountType === "teacher" && SelectedGroup === -1 ? (
            <>
              <h1 className="DashboardMobile-h1">Отчеты</h1>
              <GroupSelection
                id="1"
                Group="323"
                setSelectedGroup={setSelectedGroup}
              />
              <GroupSelection
                id="2"
                Group="123"
                setSelectedGroup={setSelectedGroup}
              />
              <GroupSelection
                id="3"
                Group="376"
                setSelectedGroup={setSelectedGroup}
              />
            </>
          ) : (
            ""
          )}
          {accountType === "teacher" &&
          SelectedGroup !== -1 &&
          SelectedKid === -1 ? (
            <>
              <nobr>
                <h1 className="DashboardMobile-h1">
                  Результаты группы {SelectedGroup}
                </h1>
              </nobr>

              <KidSelection
                id="1"
                Name="Настя"
                Surname="Антонова"
                setSelectedKid={setSelectedKid}
              />
              <KidSelection
                id="2"
                Name="Катя"
                Surname="Антонова"
                setSelectedKid={setSelectedKid}
              />
              <section className="SectionMobileResults">
                <h3>Общее выполнение Олимпиады (%)</h3>
                <div className="pie-Chart">
                  <Doughnut
                    data={{
                      datasets: [
                        {
                          data: [75, 25],
                          backgroundColor: ["#A0D468", "#E6E9ED"],
                          borderColor: ["#A0D468", "#E6E9ED"],
                          borderWidth: 1,
                        },
                      ],
                    }}
                  />
                  <figure className="pieChart-bg">
                    <svg
                      width="240"
                      height="240"
                      viewBox="0 0 240 240"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="120" cy="120" r="120" fill="#E6E9ED" />
                    </svg>
                  </figure>

                  <span className="pieChart-score">75</span>
                  <span className="pieChart-subtitle">"БАЛЛОВ"</span>
                </div>
                <h3>Выполнение одной игры (%)</h3>
                <div className="barChart">
                  <BarChart Label="Красная книга" Percent="36" />
                  <BarChart Label="Экосистема" Percent="76" />
                  <BarChart Label="Покорми птичку" Percent="5" />
                  <BarChart Label="Поведение в лесу" Percent="77" />
                  <BarChart Label="Экономим ресурсы" Percent="100" />
                  <BarChart Label="Сортировка мусора" Percent="23" />
                  <BarChart Label="Вторая жизнь вещей" Percent="36" />
                  <BarChart Label="Осознанное потребление" Percent="80" />
                </div>
                <h3>Время, проведенное в одной игре (мин)</h3>
                <div className="pie-Chart">
                  <Doughnut
                    data={{
                      datasets: [
                        {
                          data: [5.37, 4.37, 2.7, 5.1, 8.27, 1.3, 9.37, 4.37],
                          backgroundColor: [
                            "#FC6E51",
                            "#A0D468",
                            "#FFCE54",
                            "#4FC1E9",
                            "#5D9CEC",
                            "#AC92EC",
                            "#EC87C0",
                            "#ED5565",
                          ],
                          borderColor: [
                            "#FC6E51",
                            "#A0D468",
                            "#FFCE54",
                            "#4FC1E9",
                            "#5D9CEC",
                            "#AC92EC",
                            "#EC87C0",
                            "#ED5565",
                          ],
                          borderWidth: 1,
                        },
                      ],
                    }}
                  />
                  <figure className="pieChart-bg">
                    <svg
                      width="240"
                      height="240"
                      viewBox="0 0 240 240"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="120" cy="120" r="120" fill="#E6E9ED" />
                    </svg>
                  </figure>

                  <div className="pieChart-SpanBox">
                    <div className="pieChartSpanBox-Block">
                      <span className="pieChart-time">25</span>
                      <span className="pieChart-TimeSubtitle">МИНУТ</span>
                    </div>
                    <div className="pieChartSpanBox-Block">
                      <span className="pieChart-time">17</span>
                      <span className="pieChart-TimeSubtitle">СЕКУНД</span>
                    </div>
                  </div>
                </div>
                <h3>Время, проведенное на одном уровне (мин)</h3>
                <div className="levelstats-box">
                  <LevelStatsItem
                    Color="#ED5565"
                    Label="Красная книга"
                    Time="5м.37с."
                  />
                  <LevelStatsItem
                    Color="#FC6E51"
                    Label="Экосистема"
                    Time="5м.35с."
                  />
                  <LevelStatsItem
                    Color="#FFCE54"
                    Label="Покорми птичку"
                    Time="5м.35с."
                  />
                  <LevelStatsItem
                    Color="#A0D468"
                    Label="Поведение в лесу"
                    Time="5м.35с."
                  />
                  <LevelStatsItem
                    Color="#4FC1E9"
                    Label="Экономим ресурсы"
                    Time="5м.35с."
                  />
                  <LevelStatsItem
                    Color="#5D9CEC"
                    Label="Сортировка мусора"
                    Time="5м.35с."
                  />
                  <LevelStatsItem
                    Color="#AC92EC"
                    Label="Вторая жизнь вещей"
                    Time="5м.35с."
                  />
                  <LevelStatsItem
                    Color="#EC87C0"
                    Label="Осознанное потребление"
                    Time="5м.35с."
                  />
                </div>
              </section>
              <span className="DashboardMobile-h1">Рекомендации</span>
              <p id="ResultsPage-Summary">
                Таким образом постоянное информационно-пропагандистское
                обеспечение нашей деятельности позволяет оценить значение
                дальнейших направлений развития.
              </p>
            </>
          ) : (
            ""
          )}
          {accountType === "teacher" &&
          SelectedGroup !== -1 &&
          SelectedKid !== -1 ? (
            <>
              <h1 className="DashboardMobile-h1">Результаты Фамилии И.</h1>

              <KidSelection
                id="1"
                Name="Настя"
                Surname="Антонова"
                setSelectedKid={setSelectedKid}
              />
              <KidSelection
                id="2"
                Name="Катя"
                Surname="Антонова"
                setSelectedKid={setSelectedKid}
              />
              <section className="SectionMobileResults">
                <h3>Общее выполнение Олимпиады (%)</h3>
                <div className="pie-Chart">
                  <Doughnut
                    data={{
                      datasets: [
                        {
                          data: [75, 25],
                          backgroundColor: ["#A0D468", "#E6E9ED"],
                          borderColor: ["#A0D468", "#E6E9ED"],
                          borderWidth: 1,
                        },
                      ],
                    }}
                  />
                  <figure className="pieChart-bg">
                    <svg
                      width="240"
                      height="240"
                      viewBox="0 0 240 240"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="120" cy="120" r="120" fill="#E6E9ED" />
                    </svg>
                  </figure>

                  <span className="pieChart-score">75</span>
                  <span className="pieChart-subtitle">"БАЛЛОВ"</span>
                </div>
                <h3>Выполнение одной игры (%)</h3>
                <div className="barChart">
                  <BarChart Label="Красная книга" Percent="36" />
                  <BarChart Label="Экосистема" Percent="76" />
                  <BarChart Label="Покорми птичку" Percent="5" />
                  <BarChart Label="Поведение в лесу" Percent="77" />
                  <BarChart Label="Экономим ресурсы" Percent="100" />
                  <BarChart Label="Сортировка мусора" Percent="23" />
                  <BarChart Label="Вторая жизнь вещей" Percent="36" />
                  <BarChart Label="Осознанное потребление" Percent="80" />
                </div>
                <h3>Среднее время, проведённое в олимпиаде (мин)</h3>
                <div className="pie-Chart">
                  <Doughnut
                    data={{
                      datasets: [
                        {
                          data: [5.37, 4.37, 2.7, 5.1, 8.27, 1.3, 9.37, 4.37],
                          backgroundColor: [
                            "#FC6E51",
                            "#A0D468",
                            "#FFCE54",
                            "#4FC1E9",
                            "#5D9CEC",
                            "#AC92EC",
                            "#EC87C0",
                            "#ED5565",
                          ],
                          borderColor: [
                            "#FC6E51",
                            "#A0D468",
                            "#FFCE54",
                            "#4FC1E9",
                            "#5D9CEC",
                            "#AC92EC",
                            "#EC87C0",
                            "#ED5565",
                          ],
                          borderWidth: 1,
                        },
                      ],
                    }}
                  />
                  <figure className="pieChart-bg">
                    <svg
                      width="240"
                      height="240"
                      viewBox="0 0 240 240"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="120" cy="120" r="120" fill="#E6E9ED" />
                    </svg>
                  </figure>

                  <div className="pieChart-SpanBox">
                    <div className="pieChartSpanBox-Block">
                      <span className="pieChart-time">25</span>
                      <span className="pieChart-TimeSubtitle">МИНУТ</span>
                    </div>
                    <div className="pieChartSpanBox-Block">
                      <span className="pieChart-time">17</span>
                      <span className="pieChart-TimeSubtitle">СЕКУНД</span>
                    </div>
                  </div>
                </div>
                <h3>Время, проведенное на одном уровне (мин)</h3>
                <div className="levelstats-box">
                  <LevelStatsItem
                    Color="#ED5565"
                    Label="Красная книга"
                    Time="5м.37с."
                  />
                  <LevelStatsItem
                    Color="#FC6E51"
                    Label="Экосистема"
                    Time="5м.35с."
                  />
                  <LevelStatsItem
                    Color="#FFCE54"
                    Label="Покорми птичку"
                    Time="5м.35с."
                  />
                  <LevelStatsItem
                    Color="#A0D468"
                    Label="Поведение в лесу"
                    Time="5м.35с."
                  />
                  <LevelStatsItem
                    Color="#4FC1E9"
                    Label="Экономим ресурсы"
                    Time="5м.35с."
                  />
                  <LevelStatsItem
                    Color="#5D9CEC"
                    Label="Сортировка мусора"
                    Time="5м.35с."
                  />
                  <LevelStatsItem
                    Color="#AC92EC"
                    Label="Вторая жизнь вещей"
                    Time="5м.35с."
                  />
                  <LevelStatsItem
                    Color="#EC87C0"
                    Label="Осознанное потребление"
                    Time="5м.35с."
                  />
                </div>
              </section>
              <span className="DashboardMobile-h1">Рекомендации</span>
              <p id="ResultsPage-Summary">
                Таким образом постоянное информационно-пропагандистское
                обеспечение нашей деятельности позволяет оценить значение
                дальнейших направлений развития.
              </p>
            </>
          ) : (
            ""
          )}
          {accountType === "parent" && SelectedKid === -1 ? (
            <>
              <h1 className="DashboardMobile-h1">Отчеты</h1>

              <KidSelection
                id="1"
                Name="Настя"
                Surname="Антонова"
                setSelectedKid={setSelectedKid}
              />
              <KidSelection
                id="2"
                Name="Катя"
                Surname="Антонова"
                setSelectedKid={setSelectedKid}
              />
            </>
          ) : (
            ""
          )}
          {accountType === "parent" && SelectedKid !== -1 ? (
            <>
              <h1 className="DashboardMobile-h1">Результаты Фамилии И.</h1>

              <KidSelection
                id="1"
                Name="Настя"
                Surname="Антонова"
                setSelectedKid={setSelectedKid}
              />
              <KidSelection
                id="2"
                Name="Катя"
                Surname="Антонова"
                setSelectedKid={setSelectedKid}
              />
              <section className="SectionMobileResults">
                <h3>Общее выполнение Олимпиады (%)</h3>
                <div className="pie-Chart">
                  <Doughnut
                    data={{
                      datasets: [
                        {
                          data: [75, 25],
                          backgroundColor: ["#A0D468", "#E6E9ED"],
                          borderColor: ["#A0D468", "#E6E9ED"],
                          borderWidth: 1,
                        },
                      ],
                    }}
                  />
                  <figure className="pieChart-bg">
                    <svg
                      width="240"
                      height="240"
                      viewBox="0 0 240 240"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="120" cy="120" r="120" fill="#E6E9ED" />
                    </svg>
                  </figure>

                  <span className="pieChart-score">75</span>
                  <span className="pieChart-subtitle">"БАЛЛОВ"</span>
                </div>
                <h3>Выполнение одной игры (%)</h3>
                <div className="barChart">
                  <BarChart Label="Красная книга" Percent="36" />
                  <BarChart Label="Экосистема" Percent="76" />
                  <BarChart Label="Покорми птичку" Percent="5" />
                  <BarChart Label="Поведение в лесу" Percent="77" />
                  <BarChart Label="Экономим ресурсы" Percent="100" />
                  <BarChart Label="Сортировка мусора" Percent="23" />
                  <BarChart Label="Вторая жизнь вещей" Percent="36" />
                  <BarChart Label="Осознанное потребление" Percent="80" />
                </div>
                <h3>Среднее время, проведённое в олимпиаде (мин)</h3>
                <div className="pie-Chart">
                  <Doughnut
                    data={{
                      datasets: [
                        {
                          data: [5.37, 4.37, 2.7, 5.1, 8.27, 1.3, 9.37, 4.37],
                          backgroundColor: [
                            "#FC6E51",
                            "#A0D468",
                            "#FFCE54",
                            "#4FC1E9",
                            "#5D9CEC",
                            "#AC92EC",
                            "#EC87C0",
                            "#ED5565",
                          ],
                          borderColor: [
                            "#FC6E51",
                            "#A0D468",
                            "#FFCE54",
                            "#4FC1E9",
                            "#5D9CEC",
                            "#AC92EC",
                            "#EC87C0",
                            "#ED5565",
                          ],
                          borderWidth: 1,
                        },
                      ],
                    }}
                  />
                  <figure className="pieChart-bg">
                    <svg
                      width="240"
                      height="240"
                      viewBox="0 0 240 240"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="120" cy="120" r="120" fill="#E6E9ED" />
                    </svg>
                  </figure>

                  <div className="pieChart-SpanBox">
                    <div className="pieChartSpanBox-Block">
                      <span className="pieChart-time">25</span>
                      <span className="pieChart-TimeSubtitle">МИНУТ</span>
                    </div>
                    <div className="pieChartSpanBox-Block">
                      <span className="pieChart-time">17</span>
                      <span className="pieChart-TimeSubtitle">СЕКУНД</span>
                    </div>
                  </div>
                </div>
                <h3>Время, проведенное на одном уровне (мин)</h3>
                <div className="levelstats-box">
                  <LevelStatsItem
                    Color="#ED5565"
                    Label="Красная книга"
                    Time="5м.37с."
                  />
                  <LevelStatsItem
                    Color="#FC6E51"
                    Label="Экосистема"
                    Time="5м.35с."
                  />
                  <LevelStatsItem
                    Color="#FFCE54"
                    Label="Покорми птичку"
                    Time="5м.35с."
                  />
                  <LevelStatsItem
                    Color="#A0D468"
                    Label="Поведение в лесу"
                    Time="5м.35с."
                  />
                  <LevelStatsItem
                    Color="#4FC1E9"
                    Label="Экономим ресурсы"
                    Time="5м.35с."
                  />
                  <LevelStatsItem
                    Color="#5D9CEC"
                    Label="Сортировка мусора"
                    Time="5м.35с."
                  />
                  <LevelStatsItem
                    Color="#AC92EC"
                    Label="Вторая жизнь вещей"
                    Time="5м.35с."
                  />
                  <LevelStatsItem
                    Color="#EC87C0"
                    Label="Осознанное потребление"
                    Time="5м.35с."
                  />
                </div>
              </section>
              <span className="DashboardMobile-h1">Рекомендации</span>
              <p id="ResultsPage-Summary">
                Таким образом постоянное информационно-пропагандистское
                обеспечение нашей деятельности позволяет оценить значение
                дальнейших направлений развития.
              </p>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
});
