import React, { useState } from "react";
import { observer } from "mobx-react";
import "react-select-search/style.css";
import { DashboardMobileHeader } from "./DashboardMobileHeader";
import "./DashboardMobile.css";
import UIBlockButton, {
  UIBlockButtonColor,
} from "../../../../../../Components/Katelinlis/Button.tsx";

export const DashboardMobileCert = observer((props) => {
  let accountType;

  if (localStorage.getItem("accountType") != null) {
    accountType = localStorage.getItem("accountType");
  } else {
    window.location.replace("/");
  }
  return (
    <>
      <DashboardMobileHeader accountType={accountType} />
      <div className="DashboardMobile-Content">
        <div className="DashboardMobile-Cert">
          <h1 className="DashboardMobile-h1">Сертификаты</h1>
          <p className="DashboardMobile-p">
            Все сертификаты скачаются одним архивом
          </p>
          <UIBlockButton style={UIBlockButtonColor.Primary} text="Скачать" />
        </div>
      </div>
    </>
  );
});
