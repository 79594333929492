import { observable, action, makeAutoObservable } from "mobx"
import jwt_decode from "jwt-decode";
import relatedAccsStore from "../RelatedAccsStore/RelatedAccsStore"
import {emailStore} from "../../Components/Inputs/Email/EmailStore";
import {newPasswordStore} from "../../Components/Inputs/Password/NewPasswordStore";
import Notify from "../../Notifications/Notify";
import {changeAccount} from "../../Request/api/OAuth2/changeAccount";
import {getTokensAuth} from "../../Request/api/OAuth2/getTokensAuth";
import {getTokensRefresh} from "../../Request/api/OAuth2/getTokensRefresh";
import loadStore from "../LoadStore/loadStore";
import {LogoutApi} from "../../Request/api/OAuth2/logout";
import {deleteAvatar} from "../../Request/api/BaseUser/deleteAvatar";
import modalStore from "../../Components/modal/modalStore";


class AuthStore {
  isRefresh = false;

  @observable isLoading = false;
  @observable isAuth = false;
  @observable access = null
  @observable refresh = null
  @observable errorText = null
  @observable masterAccountData;
  @observable userData;
  @observable savePassowrd = false;
  @observable localStoreBlock = false;
  @observable headerVisible = true;
  @observable headerMenuVisible = false;
  @observable profileSelector = false;
  loadPage = false;

  isRegistration = false;

  setSavePassword = () => {
    this.savePassowrd = !this.savePassowrd;
  }

  setIsRegistrationTrue(){
    this.isRegistration = true;
  }
  setIsRegistrationFalse(){
    this.isRegistration = false;
  }

  constructor() {
    makeAutoObservable(this);

    this.loadPage = true;

    try {
      this.checkStorages()
      localStorage.setItem('tokenBlock', 'false');

      if(this.access !== null && this.refresh !== null){
        this.SetAuthData(
            getTokensRefresh(this.refresh).then(r => {
              if(r.type === "Error"){
                this.Logout();
              }

              return dataInDict(r);
            })
        )

        Notify.connectToNotify()
      }

      if (this.isAuth && this.decodeJwt != null)
        localStorage.setItem('test', 'test'); localStorage.removeItem('test')
    } catch(e) {
      this.localStoreBlock = true;
    }
  }

  @action checkStorages() {
    let refresh = localStorage.getItem('refresh')
    let access = sessionStorage.getItem('access')

    if(access === null){
      access = localStorage.getItem('access')
    }

    if (refresh === null){
      refresh = sessionStorage.getItem('refresh')
    }

    if (refresh != null && access != null){
      this.SetAuthDataWithoutThen(
          {
            access: access,
            refresh: refresh,
            error: null,
            errorText: "",
          }
      )
    }
  }

  get decodeJwt() {
    try {
      return jwt_decode(this.access)
    } catch {
      return null
    }
  }

  getCompulsoryToken(){
      this.SetAuthData(
          getTokensRefresh(this.refresh).then(r => {
            if(r.type === "Error"){
              this.Logout();
            }
            return dataInDict(r);
          })
      )
  }
  async getRefreshToken1(){
    let response = await fetch(`https://api.playstand.ru/oauth/refresh`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
        token: localStorage.getItem('refresh') || sessionStorage.getItem('refresh')
      })
    });

    await response.json().then(
        (response) => {
          if(response.type === "Error"){
            authStore.Logout()
          }

          sessionStorage.setItem('access', response.data)
        },
        (error) =>{

        }
    )
  }

  async getact(){

    let currentTime = new Date();
    let utcTime = Date.UTC(currentTime.getUTCFullYear(),currentTime.getUTCMonth(), currentTime.getUTCDate() , currentTime.getUTCHours(), currentTime.getUTCMinutes(), currentTime.getUTCSeconds());
    utcTime = Math.floor(utcTime / 1000)
    if (jwt_decode(sessionStorage.getItem('access')).exp < utcTime || jwt_decode(sessionStorage.getItem('access')).exp - utcTime < 60){
      await this.getRefreshToken1();
    }
    return await sessionStorage.getItem('access')
  }

  get getAccessToken() {
    let decodeToken = this.decodeJwt
    if (decodeToken == null) {
      this.isAuth = false
      return null
    }
    let now = new Date();
    let utcTime = Date.UTC(now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate() , now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
    utcTime = Math.floor(utcTime / 1000)

    if (decodeToken.exp < utcTime || decodeToken.exp - utcTime < 60) {
        this.SetAuthData(
            getTokensRefresh(this.refresh).then(r => {
              if(r.type === "Error" && r.data === null){
                authStore.Logout()
              }
              return dataInDict(r);
            })
        )
    }
    return sessionStorage.getItem('access')
  }

  getHeaders() {
    
  }

  checkPermission(permission) {
    let decodeToken = this.decodeJwt
    if(decodeToken == null){
      return false
    }
    return decodeToken.perms.includes(permission)
  }

  @action Clear = async (e) => {
    this.isLoading = false
    this.isAuth = false
    this.errorText = null
  }


  @action Logout = async (e=null) => {
    this.Clear()
    if(e !== null){
      localStorage.setItem('logout', 'true')
      localStorage.setItem('logout', 'false')
      await LogoutApi();
    }
    document.cookie = "sessionAccess=null; max-age=-1"
    document.cookie = "sessionRefresh=null; max-age=-1"
    document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    this.access = null
    this.refresh = null
    localStorage.removeItem('refresh');
    localStorage.removeItem('access');
    localStorage.removeItem('step');
    sessionStorage.removeItem('refresh');
    sessionStorage.removeItem('access');
    sessionStorage.removeItem('step')

    if(e !== null){
      window.location.href = '/authorization';
    }
  }

  @action ChangeRelatedAccount = async (targetId) => {
    this.SetAuthData(
        changeAccount(targetId).then(r => {
          return dataInDict(r, false, true);
        })

    )
  }

  @action Auth = async (e) => {
    this.errorText = null
    emailStore.ValidEmail();
    newPasswordStore.ValidPassword();
    if(emailStore.GetEmail !== '' && newPasswordStore.GetPassword !== ''){
      this.SetAuthData(
          getTokensAuth().then(r => {
            if(r !== null && (r.type === 'Data' || r.type === 'Error')){
              return dataInDict(r, true, true)
            } else {
              return dataInDict(null)
            }

          })
      )
      emailStore.Clear();
      newPasswordStore.Clear();
      localStorage.setItem('auth', 'true')
      localStorage.setItem('auth', 'false')
      this.loadPage = false;
    }
  }

  @action SetAuthData(data) {
    data.then(
      data => {
        this.SetAuthDataWithoutThen(data)
      }
    )
  }

  @action SetAuthDataWithoutThen(data) {
    if (data.error != null) {
      this.errorText = data.error
      this.isAuth = false
      this.isLoading = false
    } else {
      this.access = data.access
      this.refresh = data.refresh
      this.masterAccountData = jwt_decode(data.access)
      if(this.masterAccountData.userData !== "")
        this.userData = JSON.parse(this.masterAccountData.userData)

      if(!this.savePassowrd && localStorage.getItem('refresh') === null){
        sessionStorage.setItem('refresh', this.refresh);
        sessionStorage.setItem('access', this.access);
        document.cookie = `sessionAccess=${this.access}`
        document.cookie = `sessionRefresh=${this.refresh}`
      } else {
        localStorage.setItem('refresh', this.refresh);
        sessionStorage.setItem('access', this.access);
      }
      this.isAuth = true
      this.isLoading = false
      if(jwt_decode(this.getAccessToken).userType === 'User'){
        relatedAccsStore.getRelatedAccounts(this.decodeJwt.userId, this.getAccessToken)
      } else if (!this.loadPage && (authStore.userData.UserType === 'Parent' || authStore.userData.UserType === 'Children')){
        relatedAccsStore.getRelatedAccounts(this.decodeJwt.userId, this.getAccessToken)
      } else {
        this.loadPage = false;
      }
    }
    loadStore.load = false;
    if(localStorage.getItem('refresh') !== null){
      localStorage.setItem('access', sessionStorage.getItem('access'))
    }
  }

  keyDown = (e) => {
    if(e.code === "Enter" && document.activeElement.tagName !== "BUTTON" && document.activeElement.tagName !== "A"){
      this.Auth(e);
    }
  }

  CheckStoreType(func){
    if(sessionStorage.getItem('access') === null && sessionStorage.getItem('refresh') === null &&
        localStorage.getItem('access') !== null && localStorage.getItem('refresh') !== null){

      func();
    }
  }

  deleteAvatar(userId){
    deleteAvatar(userId).then(r => {
      if(r !== null && r.type !== "Error"){
        modalStore.SetModalInfo("", "Аватар успешно удален")
        modalStore.simpleModalOpen();
      } else {
        modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
        modalStore.simpleModalOpen();
      }
    })
  }
}

const authStore = new AuthStore();

export default authStore;


function dataInDict(r, notifyFlag=false, authFlag=false){

  let data = {};
  if(r !== null){
    if(r.type === "Data"){
      if(authFlag){
        data.access = r.data.access
        data.refresh = r.data.refresh
      } else {
        data.access = r.data
        data.refresh = authStore.refresh
      }
      data.error = null

      if(notifyFlag)
        Notify.connectToNotify()

      return data;
    } else {
      data.access = null
      data.refresh = null
      data.error = r.message
      return data;
    }
  }
  else{
    data.access = null;
    data.refresh = null;
    data.error = "Что-то пошло не так";
    return data;
  }
}
