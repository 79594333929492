import {observer} from "mobx-react";
import {TextDashboard} from "../textDashboard";
import  {Base64} from "../../../../Stores/Report/ReportStore";
import {ColumnDash2} from "./ColumnDash2/ColumnDash2";

export const ProgressGame = observer(({name, percent, iter}) => {
  return (


          <div className="row" key={iter}>
            <div className="col-md-4 sm_none">
              <TextDashboard text={Base64.decode(name)}/>
            </div>
            <div className="col-md-8 col-sm-12">
              <ColumnDash2 width={percent} iter={iter} text={Base64.decode(name)}/>
            </div>
          </div>


  )
})