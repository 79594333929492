import React from 'react';

const NamedAvatar = (props) => {
  return (
    <>
      <div className="child_dashboard_container" {...props}>
        <div className="img_container" style={props.active ? {background: '#4FC1E9'} : {}}>
          <img className="img_child" style={props.active ? {background: 'white'} : {}} src={props.img} alt={""}/>
          <p className={"child_dashboard_name"}>{props.name}</p>
        </div>
      </div>
    </>
  );
};

export default NamedAvatar;