import {useHistory} from "react-router-dom";
import authStore from "../../Stores/AuthStore/AuthStore";
import {ParentReport} from "./Components/Pages/ParentReport";
import TutorReport from "./Components/Pages/TutorReport";


export const Report = (() => {
    const history = useHistory();

    if (!authStore.isAuth) {
        history.push('/')
        return null
    }
    if (authStore.decodeJwt.userType == "Parent"){
        return <ParentReport/>
    } else if (authStore.decodeJwt.userType == "Pedagog") {
        return <TutorReport/>
    }

})