import React, { useState } from "react";
import "react-select-search/style.css";
import { Link } from "react-router-dom";
import UIBlockButton, {
  UIBlockButtonColor,
} from "../../../../../../Components/Katelinlis/Button.tsx";
import { DashboardInput } from "./DashboardInput";
import { UIInputBlock } from "../UIInputBlock";

const TeacherRow = ({
  value,
  index,
  ChangeTeacher,
  editTeacher,
  setEditTeacher,
  DeleteTeacher,
  setEditMode,
}) => {
  const [DeletePopup, setDeletePopup] = useState(false);
  const [InvitePopup, setInvitePopup] = useState(false);
  const [SuccessPopup, setSuccessPopup] = useState(false);

  if (DeletePopup || InvitePopup) {
    window.scrollTo(0, 0);
    document.getElementsByTagName("body")[0].style =
      "height: 100vh; overflow-y: hidden;";
  } else {
    document.getElementsByTagName("body")[0].style = "";
  }
  return (
    <>
      <tr>
        <td>
          <StatusIcon status={value.status} />
        </td>
        <td>{value.id}</td>
        <td>
          <EditTeacher
            editTeacher={editTeacher}
            id={value.id}
            value={value.firstname}
            name="Имя"
            onChange={(it) => {
              console.log(it);
              ChangeTeacher(index, "firstname", it);
            }}
          />
        </td>
        <td>
          <EditTeacher
            editTeacher={editTeacher}
            id={value.id}
            value={value.secondname}
            name="Фамилия"
            onChange={(it) => {
              ChangeTeacher(index, "secondname", it);
            }}
          />
        </td>
        <td>
          <EditTeacher
            editTeacher={editTeacher}
            id={value.id}
            value={value.middlename}
            name="Отчество"
            onChange={(it) => {
              ChangeTeacher(index, "middlename", it);
            }}
          />
        </td>
        <td>
          <EditTeacher
            editTeacher={editTeacher}
            id={value.id}
            value={value.group}
            name="Группа"
            onChange={(it) => {
              ChangeTeacher(index, "group", it);
            }}
          />
        </td>
        <td>
          <button
            className="DashboardTeachersTableButton"
            onClick={() => {
              setEditTeacher(value.id);
              setEditMode(true);
            }}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 16.0001H3.414L12.728 6.68608L11.314 5.27208L2 14.5861V16.0001ZM18 18.0001H0V13.7571L13.435 0.322083C13.6225 0.134612 13.8768 0.0292969 14.142 0.0292969C14.4072 0.0292969 14.6615 0.134612 14.849 0.322083L17.678 3.15108C17.8655 3.33861 17.9708 3.59292 17.9708 3.85808C17.9708 4.12325 17.8655 4.37756 17.678 4.56508L6.243 16.0001H18V18.0001ZM12.728 3.85808L14.142 5.27208L15.556 3.85808L14.142 2.44408L12.728 3.85808Z"
                fill="#616568"
              />
            </svg>
          </button>
        </td>
        <td>
          <button
            className="DashboardTeachersTableButton"
            onClick={() => {
              setDeletePopup(true);
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 2V0H15V2H20V4H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V4H0V2H5ZM4 4V18H16V4H4ZM7 7H9V15H7V7ZM11 7H13V15H11V7Z"
                fill="#FE356B"
              />
            </svg>
          </button>
        </td>
        <td>
          {value.status === "1" ? (
            <button
              className="DashboardInviteButton"
              onClick={() => setInvitePopup(true)}
            >
              Пригласить
            </button>
          ) : (
            ""
          )}
        </td>
      </tr>
      {InvitePopup ? (
        <div
          className="Teachers-PopupBG"
          onClick={(e) => {
            if (e) {
              if (e.target !== e.currentTarget) {
                return;
              } else {
                setInvitePopup(false);
              }
            }
          }}
        >
          <div className="Teachers-inviteCard">
            <div className="TeachersinviteCard__buttonContainer">
              <button
                onClick={() => {
                  setInvitePopup(false);
                }}
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4867 9.44323C13.7178 10.6835 14.8616 11.8357 15.9966 12.988C16.7737 13.7709 17.5507 14.5449 18.3191 15.3278C18.9303 15.9523 19.166 16.6911 18.8779 17.5531C18.6159 18.3448 18.0484 18.8198 17.2452 18.9605C16.4681 19.1012 15.8394 18.8022 15.2981 18.2568C13.4908 16.4273 11.6747 14.6065 9.86739 12.7857C9.78008 12.6978 9.7015 12.5922 9.60546 12.4691C9.46576 12.5922 9.37845 12.6714 9.28241 12.7593C7.41396 14.6065 5.54551 16.4448 3.66833 18.292C2.76903 19.1716 1.59906 19.2332 0.717222 18.4679C-0.0947686 17.7642 -0.225735 16.4976 0.420365 15.6444C0.5426 15.4861 0.673566 15.3366 0.813264 15.1958C2.5944 13.3926 4.38427 11.5983 6.16541 9.79507C6.25273 9.70711 6.3575 9.62795 6.47973 9.53999C6.34877 9.39925 6.27019 9.31129 6.18288 9.21454C4.36681 7.38497 2.55075 5.5554 0.734684 3.71704C-0.155886 2.81984 -0.243197 1.65877 0.507676 0.743989C1.19743 -0.100427 2.47217 -0.249959 3.33655 0.418537C3.4937 0.541681 3.64213 0.673621 3.78183 0.814357C5.63282 2.63513 7.47508 4.4471 9.32607 6.26788C9.39591 6.33824 9.47449 6.40861 9.57054 6.49657C9.67531 6.39982 9.76262 6.32065 9.84993 6.23269C11.666 4.40312 13.4821 2.56476 15.3069 0.735193C16.1974 -0.161999 17.3587 -0.241163 18.258 0.515293C19.0961 1.21018 19.2358 2.50319 18.581 3.3652C18.4588 3.52352 18.3278 3.67306 18.1881 3.81379C16.407 5.61697 14.6171 7.41136 12.8272 9.20574C12.7399 9.2849 12.6177 9.34648 12.4867 9.44323Z"
                    fill="#4FC1E9"
                  />
                </svg>
              </button>
            </div>

            <h3>Пригласить педагога</h3>
            <p>
              Передайте этот код педагогу.
              <br />
              Действителен до 23.10.22 11:00
            </p>
            <span className="Teachers-inviteCode">45454</span>
            <p>или отправьте приглашение на почту</p>
            <UIInputBlock
              type="text"
              placeholder="Ввести почту"
              width="100%"
              height="13.15vw"
              margin="4.38vw 0"
            />
            <UIBlockButton
              style={UIBlockButtonColor.Primary}
              text="Отправить "
              onClick={() => {
                setInvitePopup(false);
                setSuccessPopup(true);
              }}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {DeletePopup ? (
        <div
          className="popup-bg"
          onClick={(e) => {
            if (e.target !== e.currentTarget) {
              return;
            } else {
              setDeletePopup(false);
            }
          }}
        >
          <div id="AvatarDelete-Popup" className="popup-card">
            <h1>
              Вы уверены, что хотите
              <br />
              удалить педагога?
            </h1>
            <div className="DeletePopupButtonBox">
              <button
                className="button-red"
                onClick={() => {
                  setDeletePopup(false);
                  DeleteTeacher(index);
                }}
              >
                Удалить
              </button>
              <button
                className="button-red-trans"
                onClick={() => {
                  setDeletePopup(false);
                }}
              >
                Отмена
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {SuccessPopup ? (
        <div
          className="popup-bg"
          onClick={(e) => {
            if (e.target !== e.currentTarget) {
              return;
            } else {
              setSuccessPopup(false);
            }
          }}
        >
          <div id="AvatarDelete-Popup" className="popup-card">
            <p>Приглашение отправлено</p>
            <figure>
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28 51.3334C15.113 51.3334 4.66663 40.8871 4.66663 28.0001C4.66663 15.1131 15.113 4.66675 28 4.66675C40.887 4.66675 51.3333 15.1131 51.3333 28.0001C51.3333 40.8871 40.887 51.3334 28 51.3334ZM28 46.6667C32.9507 46.6667 37.6986 44.7001 41.1993 41.1994C44.7 37.6987 46.6666 32.9508 46.6666 28.0001C46.6666 23.0494 44.7 18.3014 41.1993 14.8008C37.6986 11.3001 32.9507 9.33341 28 9.33341C23.0493 9.33341 18.3013 11.3001 14.8006 14.8008C11.3 18.3014 9.33329 23.0494 9.33329 28.0001C9.33329 32.9508 11.3 37.6987 14.8006 41.1994C18.3013 44.7001 23.0493 46.6667 28 46.6667ZM25.6736 37.3334L15.7733 27.4331L19.0726 24.1337L25.6736 30.7347L38.871 17.5351L42.1726 20.8344L25.6736 37.3334Z"
                  fill="#00D147"
                />
              </svg>
            </figure>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const StatusIcon = ({ status }) => {
  switch (status) {
    case "1":
      return (
        <div className="UITable-ErrorIcon">
          <span className="UITableError-Popup">Пригласите педагога</span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM9 13H11V15H9V13ZM9 5H11V11H9V5Z"
              fill="#FE356B"
            />
          </svg>
        </div>
      );
      break;
    case "2":
      return (
        <div className="UITable-ErrorIcon">
          <span className="UITableError-Popup">
            Этот педагог зарегистрирован
          </span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM9.003 14L4.76 9.757L6.174 8.343L9.003 11.172L14.659 5.515L16.074 6.929L9.003 14Z"
              fill="#00D147"
            />
          </svg>
        </div>
      );
      break;
    case "3":
      return (
        <div className="UITable-ErrorIcon">
          <span className="UITableError-Popup">
            Педагог еще
            <br />
            не зарегистрировался по вашей ссылке
          </span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM9 13H11V15H9V13ZM11 11.355V12H9V10.5C9 10.2348 9.10536 9.98043 9.29289 9.79289C9.48043 9.60536 9.73478 9.5 10 9.5C10.2841 9.49998 10.5623 9.4193 10.8023 9.26733C11.0423 9.11536 11.2343 8.89837 11.3558 8.64158C11.4773 8.3848 11.5234 8.0988 11.4887 7.81684C11.454 7.53489 11.34 7.26858 11.1598 7.04891C10.9797 6.82924 10.7409 6.66523 10.4712 6.57597C10.2015 6.48671 9.91204 6.47587 9.63643 6.54471C9.36081 6.61354 9.11042 6.75923 8.91437 6.96482C8.71832 7.1704 8.58468 7.42743 8.529 7.706L6.567 7.313C6.68863 6.70508 6.96951 6.14037 7.38092 5.67659C7.79233 5.2128 8.31952 4.86658 8.90859 4.67332C9.49766 4.48006 10.1275 4.44669 10.7337 4.57661C11.3399 4.70654 11.9007 4.99511 12.3588 5.41282C12.8169 5.83054 13.1559 6.36241 13.3411 6.95406C13.5263 7.54572 13.5511 8.17594 13.4129 8.78031C13.2747 9.38467 12.9785 9.9415 12.5545 10.3939C12.1306 10.8462 11.5941 11.1779 11 11.355Z"
              fill="#7A7A7A"
            />
          </svg>
        </div>
      );
      break;
  }
};

const GenerateTeacher = (
  id,
  status,
  firstname,
  secondname,
  middlename,
  group
) => {
  return {
    id,
    status,
    firstname,
    secondname,
    middlename,
    group,
  };
};

const EditTeacher = ({ editTeacher, id, value, name, onChange }) => {
  return (
    <>
      {editTeacher !== id ? (
        value
      ) : (
        <UIInputBlock
          value={value}
          type="text"
          placeholder={name}
          width="150px"
          height="48px"
          margin="0"
          onChange={(it) => {
            onChange(it.target.value);
          }}
        />
      )}
    </>
  );
};

export const DashboardTeachers = () => {
  const [EditMode, setEditMode] = useState(false);
  const [teacher, setTeacher] = useState([
    GenerateTeacher(1, "1", "Мария", "Смирнова", "Игоревна", "354"),
    GenerateTeacher(2, "2", "Наталья", "Путина", "Александровна", "322"),
    GenerateTeacher(3, "3", "Анастасия", "Семеренко", "Петровна", "123"),
  ]);

  const ChangeTeacher = (index, name, value) => {
    let teacher2 = teacher.slice(0);
    teacher2[index][name] = value;

    setTeacher(teacher2);
  };

  const DeleteTeacher = (index) => {
    setTeacher([...teacher.slice(0, index), ...teacher.slice(index + 1)]);
  };

  const CreateTeacher = () => {
    let teacher2 = teacher.slice(0);
    console.log(teacher.length);


    let OneTeacher = GenerateTeacher(teacher.length + 1, "1", "", "", "", "")
    setEditMode(true)
    setEditTeacher(OneTeacher.id)

    setTeacher([
      ...teacher2,
      OneTeacher,
    ]);
  };

  const [editTeacher, setEditTeacher] = useState(0);

  return (
    <main
      style={{
        marginLeft: "47px",
      }}
    >
      <h1
        style={{
          fontFamily: "Open Sans",
          fontWeight: "700",
          fontSize: "24px",
          lineHeight: "120%",
          letterSpacing: "-0.01em",
          color: "#000000",
          textAlign: "left",
          margin: "0 0 24px",
        }}
      >
        Педагоги
      </h1>
      <table className="DashboardTable">
        <thead>
          <tr>
            <th></th>
            <th id="DashboardTable-ID">№</th>
            <th id="DashboardTable-Name">Имя</th>
            <th id="DashboardTable-Surname">Фамилия</th>
            <th id="DashboardTable-Patronomyc">Отчество</th>
            <th id="DashboardTable-Group">Группа</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {teacher.map((value, index) => (
            <TeacherRow
              value={value}
              index={index}
              ChangeTeacher={ChangeTeacher}
              editTeacher={editTeacher}
              setEditTeacher={setEditTeacher}
              DeleteTeacher={DeleteTeacher}
              setEditMode={setEditMode}
            />
          ))}
        </tbody>
      </table>
      <div
        style={{
          margin: "48px 0 0",
          width: "315px",
        }}
      >
        {!EditMode ? (
          <UIBlockButton
            style={UIBlockButtonColor.Primary}
            padding="12px 24px"
            text="Добавить педагога"
            plusIcon
            onClick={() => {
              CreateTeacher();
            }}
          />
        ) : (
          <div className="DashboardEditActions-Buttons">
            <UIBlockButton
              style={UIBlockButtonColor.Second}
              text="Сохранить"
              onClick={() => {
                setEditMode(false);
                setEditTeacher(false);
              }}
            />
            <button className="button-green-trans">Отмена</button>
          </div>
        )}
      </div>
    </main>
  );
};
