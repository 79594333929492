import React, { useState } from "react";
import AuthForm from "./AuthForm";
import authStore from "../../../Stores/AuthStore/AuthStore";
import { Link, useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { emailStore } from "../../../Components/Inputs/Email/EmailStore";
import { newPasswordStore } from "../../../Components/Inputs/Password/NewPasswordStore";
import { UIInputBlock } from "../RegStep/SelectRole/Role/UIInputBlock";

const Auth = observer(() => {
  let history = useHistory();
  if (authStore.isAuth) {
    history.push("/");
  }
  let errorText;
  if (authStore.errorText != null) {
    errorText = (
      <div className="alert alert-danger" role="alert" style={{ color: "red" }}>
        {authStore.errorText}
      </div>
    );
  }

  const [checkboxState, setcheckboxState] = useState(false);

  emailStore.Clear();
  newPasswordStore.Clear();

  const changeMode = () => {
    setcheckboxState(!checkboxState);
  };

  document.getElementsByTagName("body")[0].style = "";

  return (
    <div
      id="auth-container"
      className="unselectable"
      style={{ height: "100vh" }}
    >
      <div
        id="auth-container-child"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translateY(-50%) translateX(-50%)",
        }}
      >
        <h1
          style={{
            margin: "0",
            textAlign: "center",
            fontFamily: "Open Sans",
            fontWeight: "700",
            fontSize: "24px",
            lineHeight: "28.8px",
            letterSpacing: "-0.01em",
            color: "#000000",
          }}
          className="unselectable"
        >
          Вход
        </h1>
        <UIInputBlock
          type="email"
          placeholder="Почта*"
          width="336px"
          height="48px"
          margin="40px 0 24px"
        />
        <UIInputBlock
          type="password"
          placeholder="Пароль*"
          width="336px"
          height="48px"
          margin="24px 0 27px"
          eye
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <button
            for="cbx"
            class="cbx"
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              background: "transparent",
              WebkitUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
              border: "none",
            }}
            onClick={changeMode}
          >
            <span className="unselectable">
              {checkboxState ? (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ margin: "4.5px 12px 0 0" }}
                >
                  <path
                    d="M1 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM2 2V16H16V2H2ZM8.003 13L3.76 8.757L5.174 7.343L8.003 10.172L13.659 4.515L15.074 5.929L8.003 13Z"
                    fill="#9CA1A7"
                  />
                </svg>
              ) : (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ margin: "4.5px 12px 0 0" }}
                >
                  <path
                    d="M1 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM2 2V16H16V2H2Z"
                    fill="#9CA1A7"
                  />
                </svg>
              )}
            </span>
            <span className="unselectable">Запомнить пароль</span>
          </button>
          <Link
            to="/restore"
            style={{
              fontFamily: "Open Sans",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "19px",
              color: "#4FC1E9",
              textDecoration: "none",
            }}
            className="hover-underline"
          >
            Забыли пароль?
          </Link>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            style={{
              padding: "12px 56px",
              background: "#4FC1E9",
              borderRadius: "8px",
              cursor: "pointer",
              WebkitUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
              border: "solid 1px #4FC1E9",
              fontFamily: "PT Sans Caption",
              fontWeight: "400",
              fontSize: "20px",
              lineHeight: "24px",
              color: "#000000",
              margin: "42.5px 0 0",
              boxSizing: "border-box",
            }}
            className="button-blue"
            onClick={authStore.Auth}
          >
            Войти
          </button>
        </div>
      </div>
    </div>
  );

  // return <>
  //   <div className="main mt_215 pt_header">
  //     <p className="title_greetings">АВТОРИЗАЦИЯ</p>
  //     <p className="greetings">ВВЕДИТЕ ДАННЫЕ АККАУНТА</p>

  //     <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
  //       <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
  //     </svg>

  //     <AuthForm />
  //     {errorText}
  //     <Link to="/registration/invite" className="link" onClick={(() => sessionStorage.setItem('step', 'invite'))}>Зарегистрировать новый аккаунт</Link>
  //   </div>
  // </>

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <div className="card shadow-lg rounded-lg mt-5">
              <div className="card-header justify-content-center">
                <h3 className="font-weight-light my-4">Войти в аккаунт</h3>
              </div>
              <div className="card-body">
                <p
                  style={{
                    color: "red",
                    display: authStore.localStoreBlock ? "" : "none",
                  }}
                >
                  <u
                    onClick={() =>
                      window.open(
                        "https://yandex.ru/support/common/browsers-settings/browsers-cookies.html"
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    Включите cookie
                  </u>
                  , чтобы пользоваться сайтом.
                </p>
                <AuthForm />
              </div>
              {errorText}
              <div className="card-footer text-center">
                <div className="small">
                  <Link
                    to="/registration/invite"
                    className="doc_link"
                    onClick={() => sessionStorage.setItem("step", "invite")}
                  >
                    Зарегистрировать новый аккаунт
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Auth;
