import React from "react";
import UIBlockButton, {
  UIBlockButtonColor,
} from "../../../../../../Components/Katelinlis/Button.tsx";
import { DashboardInput } from "./DashboardInput";
import { useState } from "react";
import { Link } from "react-router-dom";

export const DashboardAccount = ({ accountType }) => {
  const [AvatarDeletePopup, setAvatarDeletePopup] = useState(false);
  const [AccountEdit, setAccountEdit] = useState(false);
  const [sendMailPopup, setSendMailPopup] = useState(false);
  const [MailStatus, setMailStatus] = useState(false);

  if (AvatarDeletePopup || sendMailPopup) {
    window.scrollTo(0, 0);
    document.getElementsByTagName("body")[0].style =
      "height: 100vh; overflow-y: hidden;";
  } else {
    document.getElementsByTagName("body")[0].style = "";
  }

  return (
    <main
      style={{
        marginLeft: "47px",
      }}
    >
      <h1
        style={{
          fontFamily: "Open Sans",
          fontWeight: "700",
          fontSize: "24px",
          lineHeight: "120%",
          letterSpacing: "-0.01em",
          color: "#000000",
          textAlign: "left",
          margin: "0",
        }}
      >
        Аккаунт {accountType === "director" ? "директора" : ""}
        {accountType === "teacher" ? "педагога" : ""}
        {accountType === "parent" ? "родителя" : ""}
      </h1>
      <h2
        style={{
          fontFamily: "Open Sans",
          fontWeight: "700",
          fontSize: "16px",
          lineHeight: "120%",
          letterSpacing: "-0.01em",
          color: "#000000",
          textAlign: "left",
          margin: "40px 0 24px",
        }}
      >
        Основная информация
      </h2>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyCcontent: "center",
          flexDirection: "column",
          width: "120px",
          margin: "-15px 0 0 545px",
          position: "absolute",
        }}
      >
        <span
          style={{
            fontFamily: "Open Sans",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "22px",
            color: "#2E2E2E",
          }}
        >
          Фото профиля
        </span>
        <img
          alt="Avatar"
          style={{
            width: "96px",
            height: "96px",
            borderRadius: "50%",
            objectFit: "cover",
            margin: "16px 0",
            cursor: "pointer",
          }}
          src="https://klike.net/uploads/posts/2019-07/1564314059_1.jpg"
        ></img>
        <button
          className="DashboardAccountAvatarDelete-Button"
          style={{
            WebkitUserSelect: "none",
            msUserSelect: "none",
            userSelect: "none",
            cursor: "pointer",
            background: "transparent",
            border: "none",
            color: "#FF0000",
            fontSize: "14px",
          }}
          onClick={() => {
            setAvatarDeletePopup(true);
          }}
        >
          Удалить
        </button>
      </div>
      <div
        style={{
          width: "505px",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
        }}
      >
        <DashboardInput
          text="Имя"
          placeholder="Мария"
          disabled={!AccountEdit}
        />
        <DashboardInput
          text="Фамилия"
          placeholder="Иванова"
          margin="16px 0"
          disabled={!AccountEdit}
        />
        <DashboardInput
          text="Отчество"
          placeholder="Сергеевна"
          disabled={!AccountEdit}
        />
      </div>
      <h2
        style={{
          fontFamily: "Open Sans",
          fontWeight: "700",
          fontSize: "16px",
          lineHeight: "120%",
          letterSpacing: "-0.01em",
          color: "#000000",
          textAlign: "left",
          margin: "40px 0 24px",
        }}
      >
        Контактная информация
      </h2>
      <div
        style={{
          width: "505px",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
        }}
      >
        <DashboardInput
          text="Телефон"
          placeholder="+7 (917) 100-03-92"
          disabled={!AccountEdit}
        />
        <DashboardInput
          text="Почта"
          margin="16px 0 0"
          placeholder="director@edu.gov.ru"
          disabled={!AccountEdit}
          correct={MailStatus}
          error={!MailStatus}
          ErrorText="Подтвердите почту или нажмите “выслать письмо”"
        />
        {!MailStatus ? (
          <button
            onClick={() => {
              setSendMailPopup(true);
            }}
            id="DashboardAccountMailButton"
          >
            Выслать письмо для подтверждения
          </button>
        ) : (
          ""
        )}
      </div>
      {accountType === "director" ? (
        <>
          <h2
            style={{
              fontFamily: "Open Sans",
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "120%",
              letterSpacing: "-0.01em",
              color: "#000000",
              textAlign: "left",
              margin: "40px 0 24px",
            }}
          >
            Информация о детском саде
          </h2>
          <div
            style={{
              width: "505px",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
            }}
          >
            <DashboardInput
              text="Город"
              placeholder="Челябинск"
              disabled={!AccountEdit}
            />
            <DashboardInput
              text="Название дет. сада"
              margin="16px 0 0"
              placeholder="Светлячок"
              disabled={!AccountEdit}
            />
          </div>
        </>
      ) : (
        ""
      )}
      <div
        style={{
          margin: "48px 0 0",
          width: "100px",
        }}
      >
        {AccountEdit ? (
          <UIBlockButton
            style={UIBlockButtonColor.Second}
            text="Сохранить"
            onClick={() => {
              setAccountEdit(false);
            }}
          />
        ) : (
          <UIBlockButton
            style={UIBlockButtonColor.Primary}
            text="Редактировать"
            onClick={() => {
              setAccountEdit(true);
            }}
          />
        )}
      </div>
      {AvatarDeletePopup ? (
        <div
          className="popup-bg"
          onClick={(e) => {
            if (e.target !== e.currentTarget) {
              return;
            } else {
              setAvatarDeletePopup(false);
            }
          }}
        >
          <div id="AvatarDelete-Popup" className="popup-card">
            <h1>
              Вы уверены, что хотите
              <br />
              удалить фото?
            </h1>
            <div className="DeletePopupButtonBox">
              <button
                className="button-red"
                onClick={() => {
                  setAvatarDeletePopup(false);
                }}
              >
                Удалить
              </button>
              <button
                className="button-red-trans"
                onClick={() => {
                  setAvatarDeletePopup(false);
                }}
              >
                Отмена
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {sendMailPopup ? (
        <div
          className="popup-bg"
          onClick={(e) => {
            if (e.target !== e.currentTarget) {
              return;
            } else {
              setSendMailPopup(false);
            }
          }}
        >
          <div id="AccountSendMail-Popup" className="popup-card">
            <h1>Проверьте почту</h1>
            <p>Перейдите по ссылке, чтобы подтвердить почту</p>
            <button
              id="restore-popup-button-bg"
              className="button-green"
              onClick={() => {
                setSendMailPopup(false);
                setMailStatus(true);
              }}
            >
              Хорошо
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </main>
  );
};
