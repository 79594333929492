import React, { useState } from "react";
import { observer } from "mobx-react";
import "react-select-search/style.css";
import { useHistory, Link } from "react-router-dom";
import authStore from "../../../../../Stores/AuthStore/AuthStore";
import { CheckStep } from "../../../registration1";
import UIBlockButton, {
  UIBlockButtonColor,
} from "../../../../../Components/Katelinlis/Button.tsx";
import { UIInputBlock } from "./UIInputBlock";

export const ParentName = observer((props) => {
  const [SuccessPopup, setSuccessPopup] = useState(false);

  if (SuccessPopup) {
    window.scrollTo(0, 0);
    document.getElementsByTagName("body")[0].style =
      "height: 100vh; overflow-y: hidden;";
  } else {
    document.getElementsByTagName("body")[0].style = "";
  }

  let history = useHistory();
  if (authStore.isRegistration) {
    CheckStep("teacher");
  }

  return (
    <>
      <div
        className="RegistrationFirstStepMainContainer"
        id="RegistrationParentNameContainer"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translateY(-50%) translateX(-50%)",
        }}
      >
        <h1
          style={{
            margin: "32px 0",
            fontSize: "24px",
            fontWeight: "bold",
            fontFamily:
              "'Open Sans','Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
            WebkitUserSelect: "none",
            MsUserSelect: "none",
            UserSelect: "none",
          }}
        >
          Введите данные ребенка
        </h1>
        <div
          className="RegistrationFirstStepContainer"
          style={{
            display: "flex",
            width: "876px",
            maxWidth: "100%",
            margin: "0 auto",
            flexDirection: "row",
            flexWrap: "wrap",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <UIInputBlock
            type="text"
            placeholder="Имя*"
            width="280px"
            height="48px"
            margin="12px 8px"
          />
          <UIInputBlock
            type="text"
            placeholder="Фамилия*"
            width="280px"
            height="48px"
            margin="12px 8px"
          />

          <UIInputBlock
            type="text"
            placeholder="Год рождения*"
            width="280px"
            height="48px"
            margin="12px 8px"
          />
          <select
            style={{
              width: "280px",
              height: "48px",
              margin: "12px 8px",
              borderRadius: "8px",
              background: "#F5F7FA",
              border: "1px solid #CCCCCC",
              padding: "8px 40px 8px 15px",
            }}
            name="gender"
            id="ParentNameGenderSelect"
          >
            <option value="" disabled selected hidden>
              Пол*
            </option>
            <option value="male">Мужской</option>
            <option value="female">Женский</option>
          </select>
        </div>
        <UIBlockButton
          style={UIBlockButtonColor.Primary}
          text="Войти"
          margin="40px 0 0 0"
          onClick={() => {
            setSuccessPopup(true);
          }}
        />
      </div>

      {/*</form>*/}
      <svg
        className="step_reg"
        width="70"
        height="10"
        viewBox="0 0 70 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="65" cy="5" r="5" fill="#434A54" />
        <circle cx="35" cy="5" r="5" fill="#434A54" fillOpacity="0.5" />
        <circle cx="5" cy="5" r="5" fill="#434A54" fillOpacity="0.5" />
      </svg>
      {SuccessPopup ? (
        <div
          className="popup-bg"
          onClick={(e) => {
            if (e.target !== e.currentTarget) {
              return;
            } else {
              setSuccessPopup(false);
            }
          }}
        >
          <div id="TeacherCode-Popup" className="popup-card">
            <h1>Вы успешно зарегистрировались!</h1>
            <p>
              На почту вам пришло письмо. Перейдите по ссылке,
              чтобы&nbsp;подтвердить почту
            </p>
            <div id="TeacherCodePopupButton-Desktop">
              <Link to="/dashboard">
                <button
                  id="restore-popup-button-bg"
                  className="button-green"
                  onClick={() => {
                    localStorage.setItem("accountType", "parent");
                  }}
                >
                  Хорошо
                </button>
              </Link>
            </div>
            <div id="TeacherCodePopupButton-Mobile">
              <Link to="/dashboard-mobile">
                <button
                  id="restore-popup-button-bg"
                  className="button-green"
                  onClick={() => {
                    localStorage.setItem("accountType", "parent");
                  }}
                >
                  Хорошо
                </button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
});

export default ParentName;
