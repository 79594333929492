import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import reportStore, {Base64} from "../../../../Stores/Report/ReportStore";
import childGroupsStore from "../../../../Stores/ChildGroups/ChildGroupsStore";
import {BigTextAndSvg} from "../bigTextAndSvg";
import CirclePercent from "../Molecules/CirclePercent";
import {ProgressGame} from "../Molecules/ProgressInGames";
import {CircleTotalTime} from "../Molecules/CircleTotalTime";
import {GameTime} from "../Molecules/GameTime/GameTime";
import {CircleDouble} from "../Molecules/CircleDouble";
import {SmallTextAndSvg} from "../smallTextAndSvg";


const ReportDashboardChildren = () => {
  const activeChild = reportStore.childActiveID
  const child = childGroupsStore.GroupChlid
  const [stat, setStat] = useState(null)
  const [loading, setLoad] = useState(true)


  useEffect(() => {


    (reportStore.childActiveID != null && child[0] && child[reportStore.childActiveID].User != null ) ?
      reportStore.GetPlayerStatsTutor(child[reportStore.childActiveID].User.UserId)
        .then(() => {
          setStat(reportStore.PlayStats)
          setLoad(false)
        })
        :
      setLoad(false)



    return () => {
      setStat(null)
    }
  },[activeChild])

  useEffect(() => {
    !loading && reportStore.SelectLevel(reportStore.levelOlymp+1)
  }, [reportStore.levelOlymp])

  return (

  <div className="col-sm-12">
    {

        loading ?
          <p>Загрузка данных...</p>
          :
          (stat?.totalOlympTime && stat[`totalLevel${reportStore.levelOlymp+1}Time`] !== 0) ?
            <div style={{display: "flex", flexDirection: "column", width: '100%'}}>
              <BigTextAndSvg divM={'48px auto 0 auto'} pM={'0 0 10px 0'} div2M={'0 auto 30px auto'}
                             text={'ОБЩЕЕ ВЫПОЛНЕНИЕ УРОВНЯ (%)'}/>
              <CirclePercent percent={stat.percentGamesOnLevel[reportStore.levelOlymp].percents * 100}/>

              <BigTextAndSvg divM={'48px auto 0 auto'} pM={'0 0 10px 0'} div2M={'0 auto 30px auto'}
                             text={'ВЫПОЛНЕНИЕ ОДНОЙ ИГРЫ (%)'}/>
              {
                stat.percentsByGame
                  .filter(item => item.PlayerDifficulty == reportStore.levelOlymp)
                  .map((item, key) => {
                    return <ProgressGame name={item.LevelName} percent={item.Percent * 100} key={key} iter={key}/>
                  }
                )
              }
              <BigTextAndSvg divM={'48px auto 0 auto'} pM={'0 0 10px 0'} div2M={'0 auto 30px auto'}
                             text={'ВРЕМЯ, ПРОВЕДЕННОЕ НА ОДНОМ УРОВНЕ'}/>
              <CircleTotalTime games={stat.gamesTime.filter(item => item.PlayerDifficulty == reportStore.levelOlymp)}
                              levelTime={stat[`totalLevel${reportStore.levelOlymp+1}Time`]}/>
              <BigTextAndSvg divM={'48px auto 0 auto'} pM={'0'} div2M={'10px auto 37px auto'}
                             text={'ВРЕМЯ, ПРОВЕДЕННОЕ В ОДНОЙ ИГРЕ'}/>
              <GameTime gamesTime={stat.gamesTime.filter(item => item.PlayerDifficulty == reportStore.levelOlymp)}/>


              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: '20px'}}>
                <div style={{display: "flex", flexDirection: "column"}}>

                </div>

                <div style={{display: "flex", flexDirection: "column"}}>

                </div>
              </div>


                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <SmallTextAndSvg text={<p style={{textAlign: "center", margin: '48px auto 0'}} >ИГРА С НАИЛУЧШИМИ <br/> ПОКАЗАТЕЛЯМИ</p>}/>

                      <CircleDouble
                        encode
                        bigSize={240}
                        smallSize={163}
                        time={reportStore.bestGame.Seconds}
                        percent={reportStore.bestGame.Percent * 100}
                        gameName={reportStore.bestGame.LevelName}
                        totalTime={stat[`totalLevel${reportStore.levelOlymp+1}Time`]}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <SmallTextAndSvg text={<p style={{textAlign: "center", margin: '48px auto 0'}} >ИГРА, ГДЕ СТОИТ <br/> УЛУЧШИТЬ РЕЗУЛЬТАТЫ</p>}/>
                      <CircleDouble
                        encode
                        bigSize={240}
                        smallSize={163}
                        time={reportStore.worseGame.Seconds}
                        percent={reportStore.worseGame.Percent * 100}
                        gameName={reportStore.worseGame.LevelName}
                        totalTime={stat[`totalLevel${reportStore.levelOlymp+1}Time`]}
                      />
                    </div>
                  </div>



                  <div className={'col-md-12'}>
                    <div style={{display: "flex", flexDirection: 'row', justifyContent: "space-around"}}>
                      <div style={{display: "flex", flexDirection: 'row', margin: '24px auto 0'}}>
                        <div style={{width: '18px', height: '18px', borderRadius: '50%', background: '#A0D468', margin: 'auto 8px auto 0'}}/>
                        <p className="game_text" style={{margin: 'auto 0', textAlign: "left"}}>Прогресс</p>
                      </div>

                      <div style={{display: "flex", flexDirection: 'row', margin: '24px auto 0'}}>
                        <div style={{width: '18px', height: '18px', borderRadius: '50%', background: '#4FC1E9', margin: 'auto 8px auto 0'}}/>
                        <p className="game_text" style={{margin: 'auto 0', textAlign: "left"}}>Время</p>
                      </div>
                    </div>
                  </div>




            </div>
            :
            activeChild == null ?
              <p>Выберите ребенка</p>
              :
              <p>Данных пока нет</p>

    }




  </div>
  );
};

export default observer(ReportDashboardChildren);