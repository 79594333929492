import { React, useState } from "react";
import { observer } from "mobx-react";
import restorePassword from "../../Stores/Registration/restorePassword";
import EmailUI from "../../Components/Inputs/Email/EmailUI";
import { UIInputBlock } from "./RegStep/SelectRole/Role/UIInputBlock";
import { Link, useHistory } from "react-router-dom";

export const Restore2 = observer(() => {
  const [EmailPopup, setEmailPopup] = useState(false);
  if (EmailPopup) {
    window.scrollTo(0, 0);
    document.getElementsByTagName("body")[0].style =
      "height: 100vh; overflow-y: hidden;";
  } else {
    document.getElementsByTagName("body")[0].style = "";
  }
  return (
    <div
      id="restore-container"
      className="unselectable"
      style={{ height: "100vh" }}
    >
      <div
        id="restore-container-child"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translateY(-50%) translateX(-50%)",
        }}
      >
        <h1
          style={{
            margin: "0",
            textAlign: "center",
            fontFamily: "Open Sans",
            fontWeight: "700",
            fontSize: "24px",
            lineHeight: "28.8px",
            letterSpacing: "-0.01em",
            color: "#000000",
          }}
          className="unselectable"
        >
          Изменение пароля
        </h1>
        <UIInputBlock
          type="password"
          placeholder="Введите пароль"
          width="336px"
          height="48px"
          margin="40px 0 0"
          eye
        />
        <UIInputBlock
          type="password"
          placeholder="Подтвердите пароль"
          width="336px"
          height="48px"
          margin="24px 0 0"
          eye
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            style={{
              padding: "12px 56px",
              background: "#4FC1E9",
              borderRadius: "8px",
              cursor: "pointer",
              WebkitUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
              border: "solid 1px #4FC1E9",
              fontFamily: "PT Sans Caption",
              fontWeight: "400",
              fontSize: "20px",
              lineHeight: "24px",
              color: "#000000",
              margin: "40px 0 0",
              boxSizing: "border-box",
            }}
            className="button-blue"
            onClick={() => {
              setEmailPopup(true);
            }}
          >
            Далее
          </button>
        </div>
      </div>
      {EmailPopup ? (
        <div
          className="popup-bg"
          onClick={(e) => {
            if (e.target !== e.currentTarget) {
              return;
            } else {
              setEmailPopup(false);
            }
          }}
        >
          <div className="popup-card">
            <h1>Пароль успешно обновлен!</h1>
            <Link to="/authorization">
              <button id="restore-popup-button-bg" className="button-green">
                Отлично
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
});
