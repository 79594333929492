import React, { useState } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { DashboardAsideButtonMobile } from "./DashboardMobile";
import "react-select-search/style.css";

export const DashboardMobileHeader = observer((props) => {
  const [showMenu, setShowMenu] = React.useState(false);
  const BurgerOnClick = () => setShowMenu(!showMenu);

  if (showMenu) {
    window.scrollTo(0, 0);
    document.getElementsByTagName("body")[0].style =
      "height: 100vh; overflow-y: hidden;";
  } else {
    document.getElementsByTagName("body")[0].style = "";
  }
  return (
    <>
      <header id="DashboardMobile-Header">
        <Link to={"/dashboard-mobile"} id="DashboardMobile-Logo">
          <img
            src="https://playstand.ru/wp-content/themes/innov2017/img/logo.svg"
            alt=""
            id="header_img"
            width="130px"
          />
        </Link>
        <button
          className="headerButton DashboardMobile-Button"
          onClick={() => BurgerOnClick()}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 3H16" stroke="#74777A" stroke-width="2" />
            <path d="M0 8H16" stroke="#74777A" stroke-width="2" />
            <path d="M0 13H16" stroke="#74777A" stroke-width="2" />
          </svg>
        </button>
      </header>
      {showMenu ? (
        <div
          id="DashboardMobile-MenuContainer"
          onClick={(e) => {
            if (e.target !== e.currentTarget) {
              return;
            } else {
              setShowMenu(false);
            }
          }}
        >
          <div id="DashboardMobile-MenuCard">
            <figure>
              <svg
                width="40"
                height="3"
                viewBox="0 0 40 3"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="40" height="3" fill="#D9D9D9" />
              </svg>
            </figure>
            <div className="DashboardAsideButtonsMobile">
              {props.accountType === "parent" ? (
                <Link
                  to="/dashboard-mobile/quiz"
                  style={{ textDecoration: "none" }}
                >
                  <DashboardAsideButtonMobile
                    insideText="Олимпиада"
                    svg='<svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9293 7.99988L6.66659 5.15788V10.8419L10.9293 7.99988ZM12.9173 8.27722L5.85125 12.9879C5.80106 13.0213 5.74274 13.0404 5.68251 13.0433C5.62229 13.0462 5.56241 13.0327 5.50925 13.0042C5.4561 12.9758 5.41165 12.9334 5.38066 12.8817C5.34967 12.83 5.33328 12.7708 5.33325 12.7105V3.28922C5.33328 3.22892 5.34967 3.16976 5.38066 3.11804C5.41165 3.06633 5.4561 3.02398 5.50925 2.99552C5.56241 2.96706 5.62229 2.95355 5.68251 2.95644C5.74274 2.95932 5.80106 2.97848 5.85125 3.01188L12.9173 7.72255C12.9629 7.75299 13.0003 7.79423 13.0262 7.84261C13.0521 7.89099 13.0657 7.94501 13.0657 7.99988C13.0657 8.05475 13.0521 8.10878 13.0262 8.15715C13.0003 8.20553 12.9629 8.24678 12.9173 8.27722Z"
                      fill="#2E2E2E"
                    />
                  </svg>
'
                    eventKey="dashboard-mobile/quiz"
                  />
                </Link>
              ) : (
                <></>
              )}
              <Link
                to="/dashboard-mobile/account"
                style={{ textDecoration: "none" }}
              >
                <DashboardAsideButtonMobile
                  insideText="Аккаунт"
                  svg='<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.00016 13.6668C3.31816 13.6668 0.333496 10.6822 0.333496 7.00016C0.333496 3.31816 3.31816 0.333496 7.00016 0.333496C10.6822 0.333496 13.6668 3.31816 13.6668 7.00016C13.6668 10.6822 10.6822 13.6668 7.00016 13.6668ZM3.6755 11.1708C4.6192 11.9253 5.79195 12.3354 7.00016 12.3335C8.3135 12.3335 9.5155 11.8588 10.4448 11.0722C10.0106 10.6267 9.49153 10.2727 8.91819 10.0314C8.34484 9.78998 7.72891 9.66603 7.10683 9.66683C6.46189 9.66609 5.82383 9.7994 5.23314 10.0583C4.64245 10.3172 4.11202 10.6961 3.6755 11.1708V11.1708ZM2.74416 10.2135C3.30447 9.61888 3.98061 9.14531 4.73092 8.82197C5.48123 8.49864 6.28982 8.3324 7.10683 8.3335C7.8946 8.33247 8.67481 8.48707 9.40266 8.78842C10.1305 9.08976 10.7917 9.53192 11.3482 10.0895C11.9189 9.28625 12.2559 8.34059 12.3219 7.35744C12.3878 6.37429 12.1801 5.39209 11.7218 4.5198C11.2635 3.64751 10.5725 2.91924 9.72547 2.41579C8.87844 1.91234 7.90849 1.65339 6.92323 1.66766C5.93798 1.68194 4.97594 1.96889 4.14384 2.49667C3.31175 3.02445 2.64214 3.77243 2.20929 4.65763C1.77645 5.54283 1.59729 6.53064 1.69171 7.51146C1.78612 8.49229 2.15041 9.42778 2.74416 10.2142V10.2135ZM7.00016 7.66683C6.29292 7.66683 5.61464 7.38588 5.11454 6.88578C4.61445 6.38568 4.3335 5.70741 4.3335 5.00016C4.3335 4.29292 4.61445 3.61464 5.11454 3.11454C5.61464 2.61445 6.29292 2.3335 7.00016 2.3335C7.70741 2.3335 8.38568 2.61445 8.88578 3.11454C9.38588 3.61464 9.66683 4.29292 9.66683 5.00016C9.66683 5.70741 9.38588 6.38568 8.88578 6.88578C8.38568 7.38588 7.70741 7.66683 7.00016 7.66683ZM7.00016 6.3335C7.35378 6.3335 7.69292 6.19302 7.94297 5.94297C8.19302 5.69292 8.3335 5.35378 8.3335 5.00016C8.3335 4.64654 8.19302 4.3074 7.94297 4.05735C7.69292 3.80731 7.35378 3.66683 7.00016 3.66683C6.64654 3.66683 6.3074 3.80731 6.05735 4.05735C5.80731 4.3074 5.66683 4.64654 5.66683 5.00016C5.66683 5.35378 5.80731 5.69292 6.05735 5.94297C6.3074 6.19302 6.64654 6.3335 7.00016 6.3335Z" fill="#2E2E2E"/>
</svg>
'
                  eventKey="dashboard-mobile/account"
                />
              </Link>
              {props.accountType === "teacher" ? (
                <Link
                  to="/dashboard-mobile/groups"
                  style={{ textDecoration: "none" }}
                >
                  <DashboardAsideButtonMobile
                    insideText="Группы"
                    svg='<svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.33341 9.50142V10.8947C8.73003 10.6814 8.08426 10.616 7.45035 10.7039C6.81644 10.7919 6.21288 11.0307 5.69037 11.4002C5.16786 11.7698 4.74164 12.2593 4.44752 12.8277C4.1534 13.3961 3.99996 14.0268 4.00008 14.6667L2.66675 14.6661C2.66654 13.852 2.8527 13.0487 3.21095 12.3177C3.5692 11.5867 4.09004 10.9474 4.73356 10.4488C5.37708 9.95017 6.1262 9.6055 6.92352 9.44118C7.72083 9.27685 8.54519 9.29723 9.33341 9.50075V9.50142ZM8.00008 8.66675C5.79008 8.66675 4.00008 6.87675 4.00008 4.66675C4.00008 2.45675 5.79008 0.666748 8.00008 0.666748C10.2101 0.666748 12.0001 2.45675 12.0001 4.66675C12.0001 6.87675 10.2101 8.66675 8.00008 8.66675ZM8.00008 7.33342C9.47341 7.33342 10.6667 6.14008 10.6667 4.66675C10.6667 3.19341 9.47341 2.00008 8.00008 2.00008C6.52675 2.00008 5.33341 3.19341 5.33341 4.66675C5.33341 6.14008 6.52675 7.33342 8.00008 7.33342ZM12.0001 11.3334V9.33342H13.3334V11.3334H15.3334V12.6667H13.3334V14.6667H12.0001V12.6667H10.0001V11.3334H12.0001Z"
                      fill="#2E2E2E"
                    />
                  </svg>
'
                    margin="3px 0"
                    eventKey="dashboard-mobile/groups"
                  />
                </Link>
              ) : (
                <></>
              )}
              {props.accountType === "parent" ? (
                <Link
                  to="/dashboard-mobile/kids"
                  style={{ textDecoration: "none" }}
                >
                  <DashboardAsideButtonMobile
                    insideText="Дети"
                    svg='<svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.33317 9.50117V10.8945C8.72978 10.6812 8.08402 10.6157 7.45011 10.7037C6.81619 10.7917 6.21264 11.0304 5.69012 11.4C5.16761 11.7695 4.7414 12.259 4.44728 12.8274C4.15316 13.3958 3.99972 14.0265 3.99984 14.6665L2.6665 14.6658C2.6663 13.8518 2.85245 13.0484 3.2107 12.3174C3.56895 11.5864 4.0898 10.9471 4.73332 10.4485C5.37684 9.94993 6.12595 9.60526 6.92327 9.44093C7.72059 9.27661 8.54494 9.29699 9.33317 9.5005V9.50117ZM7.99984 8.6665C5.78984 8.6665 3.99984 6.8765 3.99984 4.6665C3.99984 2.4565 5.78984 0.666504 7.99984 0.666504C10.2098 0.666504 11.9998 2.4565 11.9998 4.6665C11.9998 6.8765 10.2098 8.6665 7.99984 8.6665ZM7.99984 7.33317C9.47317 7.33317 10.6665 6.13984 10.6665 4.6665C10.6665 3.19317 9.47317 1.99984 7.99984 1.99984C6.5265 1.99984 5.33317 3.19317 5.33317 4.6665C5.33317 6.13984 6.5265 7.33317 7.99984 7.33317ZM11.9998 11.3332V9.33317H13.3332V11.3332H15.3332V12.6665H13.3332V14.6665H11.9998V12.6665H9.99984V11.3332H11.9998Z"
                      fill="#2e2e2e"
                    />
                  </svg>
'
                    margin="3px 0"
                    eventKey="dashboard-mobile/kids"
                  />
                </Link>
              ) : (
                <></>
              )}
              {props.accountType === "director" ? (
                <Link
                  to="/dashboard-mobile/teachers"
                  style={{ textDecoration: "none" }}
                >
                  <DashboardAsideButtonMobile
                    insideText="Педагоги"
                    svg='<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.33317 9.50117V10.8945C6.72978 10.6812 6.08402 10.6157 5.45011 10.7037C4.81619 10.7917 4.21264 11.0304 3.69012 11.4C3.16761 11.7695 2.7414 12.259 2.44728 12.8274C2.15316 13.3958 1.99972 14.0265 1.99984 14.6665L0.666504 14.6658C0.666297 13.8518 0.852451 13.0484 1.2107 12.3174C1.56895 11.5864 2.0898 10.9471 2.73332 10.4485C3.37684 9.94993 4.12595 9.60526 4.92327 9.44093C5.72059 9.27661 6.54494 9.29699 7.33317 9.5005V9.50117ZM5.99984 8.6665C3.78984 8.6665 1.99984 6.8765 1.99984 4.6665C1.99984 2.4565 3.78984 0.666504 5.99984 0.666504C8.20984 0.666504 9.99984 2.4565 9.99984 4.6665C9.99984 6.8765 8.20984 8.6665 5.99984 8.6665ZM5.99984 7.33317C7.47317 7.33317 8.6665 6.13984 8.6665 4.6665C8.6665 3.19317 7.47317 1.99984 5.99984 1.99984C4.5265 1.99984 3.33317 3.19317 3.33317 4.6665C3.33317 6.13984 4.5265 7.33317 5.99984 7.33317ZM9.99984 11.3332V9.33317H11.3332V11.3332H13.3332V12.6665H11.3332V14.6665H9.99984V12.6665H7.99984V11.3332H9.99984Z" fill="#2E2E2E"/>
          </svg>
'
                    margin="3px 0"
                    eventKey="dashboard-mobile/teachers"
                  />
                </Link>
              ) : (
                <></>
              )}
              <Link
                to="/dashboard-mobile/security"
                style={{ textDecoration: "none" }}
              >
                <DashboardAsideButtonMobile
                  insideText="Защита"
                  svg='<svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.522 1.88384L6 0.666504L11.478 1.88384C11.626 1.91675 11.7584 1.99915 11.8533 2.11744C11.9482 2.23573 12 2.38285 12 2.5345V9.1925C12 9.85099 11.8373 10.4993 11.5266 11.0798C11.2158 11.6604 10.7666 12.1553 10.2187 12.5205L6 15.3332L1.78133 12.5205C1.23352 12.1553 0.784307 11.6606 0.473565 11.0801C0.162824 10.4997 0.000157571 9.85155 0 9.19317V2.5345C2.60252e-05 2.38285 0.0517586 2.23573 0.14666 2.11744C0.241561 1.99915 0.373958 1.91675 0.522 1.88384ZM1.33333 3.06917V9.1925C1.33334 9.63148 1.44172 10.0637 1.64884 10.4507C1.85597 10.8377 2.15543 11.1677 2.52067 11.4112L6 13.7312L9.47933 11.4112C9.84447 11.1677 10.1439 10.8379 10.351 10.451C10.5581 10.0641 10.6665 9.63203 10.6667 9.19317V3.06917L6 2.03317L1.33333 3.06917ZM6.66667 6.6665H8.66667L5.33333 11.3332V7.99984H3.33333L6.66667 3.33317V6.6665Z" fill="#2E2E2E"/>
          </svg>
'
                  eventKey="dashboard-mobile/security"
                />
              </Link>
              {props.accountType === "parent" ||
              props.accountType === "teacher" ? (
                <Link
                  to="/dashboard-mobile/results"
                  style={{ textDecoration: "none" }}
                >
                  <DashboardAsideButtonMobile
                    insideText="Отчеты"
                    svg='<svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00016 14.6668C4.31816 14.6668 1.3335 11.6822 1.3335 8.00016C1.3335 5.01483 3.2955 2.48816 6.00016 1.63883V3.05483C4.85411 3.52019 3.90536 4.3693 3.3162 5.45691C2.72704 6.54451 2.53408 7.80303 2.77033 9.01719C3.00658 10.2314 3.65736 11.3257 4.61134 12.113C5.56532 12.9004 6.76323 13.3318 8.00016 13.3335C9.06268 13.3335 10.101 13.0162 10.9821 12.4223C11.8631 11.8285 12.5468 10.985 12.9455 10.0002H14.3615C13.5122 12.7048 10.9855 14.6668 8.00016 14.6668ZM14.6335 8.66683H7.3335V1.36683C7.55283 1.34483 7.7755 1.3335 8.00016 1.3335C11.6822 1.3335 14.6668 4.31816 14.6668 8.00016C14.6668 8.22483 14.6555 8.4475 14.6335 8.66683ZM8.66683 2.70816V7.3335H13.2922C13.1438 6.15857 12.6087 5.06639 11.7713 4.22899C10.9339 3.39159 9.84176 2.85655 8.66683 2.70816Z"
                      fill="#2e2e2e"
                    />
                  </svg>
'
                    margin="3px 0 0 0"
                    eventKey="dashboard-mobile/results"
                  />
                </Link>
              ) : (
                <> </>
              )}
              {props.accountType === "parent" ||
              props.accountType === "teacher" ? (
                <Link
                  to="/dashboard-mobile/code"
                  style={{ textDecoration: "none" }}
                >
                  <DashboardAsideButtonMobile
                    insideText="Ввести код"
                    svg='<svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.66683 3.33333V12.6667H13.3335V3.33333H2.66683ZM2.00016 2H14.0002C14.177 2 14.3465 2.07024 14.4716 2.19526C14.5966 2.32029 14.6668 2.48986 14.6668 2.66667V13.3333C14.6668 13.5101 14.5966 13.6797 14.4716 13.8047C14.3465 13.9298 14.177 14 14.0002 14H2.00016C1.82335 14 1.65378 13.9298 1.52876 13.8047C1.40373 13.6797 1.3335 13.5101 1.3335 13.3333V2.66667C1.3335 2.48986 1.40373 2.32029 1.52876 2.19526C1.65378 2.07024 1.82335 2 2.00016 2ZM4.00016 4.66667H6.00016V11.3333H4.00016V4.66667ZM6.66683 4.66667H8.00016V11.3333H6.66683V4.66667ZM8.66683 4.66667H9.3335V11.3333H8.66683V4.66667ZM10.0002 4.66667H12.0002V11.3333H10.0002V4.66667Z"
                      fill="#2e2e2e"
                    />
                  </svg>
'
                    margin="3px 0 0 0"
                    eventKey="dashboard-mobile/results"
                  />
                </Link>
              ) : (
                <> </>
              )}
              {props.accountType === "director" ? (
                <Link
                  to="/dashboard-mobile/cert"
                  style={{ textDecoration: "none" }}
                >
                  <DashboardAsideButtonMobile
                    insideText="Сертификаты"
                    svg='<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.6668 13.6668H2.3335C1.80306 13.6668 1.29436 13.4561 0.919283 13.081C0.54421 12.706 0.333496 12.1973 0.333496 11.6668V1.00016C0.333496 0.823352 0.403734 0.653782 0.528758 0.528758C0.653782 0.403734 0.823352 0.333496 1.00016 0.333496H10.3335C10.5103 0.333496 10.6799 0.403734 10.8049 0.528758C10.9299 0.653782 11.0002 0.823352 11.0002 1.00016V9.00016H13.6668V11.6668C13.6668 12.1973 13.4561 12.706 13.081 13.081C12.706 13.4561 12.1973 13.6668 11.6668 13.6668ZM11.0002 10.3335V11.6668C11.0002 11.8436 11.0704 12.0132 11.1954 12.1382C11.3204 12.2633 11.49 12.3335 11.6668 12.3335C11.8436 12.3335 12.0132 12.2633 12.1382 12.1382C12.2633 12.0132 12.3335 11.8436 12.3335 11.6668V10.3335H11.0002ZM9.66683 12.3335V1.66683H1.66683V11.6668C1.66683 11.8436 1.73707 12.0132 1.86209 12.1382C1.98712 12.2633 2.15669 12.3335 2.3335 12.3335H9.66683ZM3.00016 3.66683H8.3335V5.00016H3.00016V3.66683ZM3.00016 6.3335H8.3335V7.66683H3.00016V6.3335ZM3.00016 9.00016H6.3335V10.3335H3.00016V9.00016Z" fill="#2E2E2E"/>
          </svg>
'
                    margin="3px 0 0 0"
                    eventKey="dashboard-mobile/cert"
                  />
                </Link>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
});
