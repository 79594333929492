import React from 'react';
import {useDeclOfNums} from "../hooks/useDeclOfNums";

const CirclePercent = ({percent}) => {
  const percentRound = Math.round(percent);
  const title = useDeclOfNums(percent, ['ВЫПОЛНЕН','ВЫПОЛНЕНО','ВЫПОЛНЕНО']);


  return (
    <div style={{width: '280px', height: '280px', margin: '0 auto', position: 'relative'}}>
      <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut">
        <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#E6E9ED"/>
        <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#E6E9ED"
                strokeWidth="8"/>
        <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#A0D468"
                strokeWidth="8" strokeDasharray={`
                        ${percentRound} 
                        ${100-percentRound}
                        `}
                strokeDashoffset="25"/>
      </svg>
      <span style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                <p style={{margin: 0, fontSize: '36px'}}>{percentRound}%</p>
                <p style={{margin: 0, fontSize: '12px'}}>{title}</p>
            </span>
    </div>
  );
};

export default CirclePercent;