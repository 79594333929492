import React, { useState } from "react";

export const UIInputBlock = ({
  type,
  eye,
  placeholder,
  correct,
  error,
  message,
  messageColor,
  value,
  onChange,
  width,
  height,
  margin,
  disabled,
  ErrorText,
}) => {
  const [eyeStatus, setEyeStatus] = useState(false);

  let LocalType = type;
  if (type === "password" && eyeStatus) LocalType = "text";

  return (
    <div
      className="UIInputBlockBox"
      style={{
        position: "relative",
        width: width,
        height: height,
        margin: margin,
      }}
    >
      <input
        className="UIInputBlock"
        placeholder={placeholder}
        type={LocalType}
        disabled={disabled}
        style={{
          background: "#F5F7FA",
          border: "1px solid #CCCCCC",
          borderRadius: "8px",
          width: "100%",
          height: "100%",
          padding: "8px 40px 8px 15px",
          boxSizing: "border-box",
        }}
        value={value}
        onChange={onChange}
      />
      {correct && (
        <button
          style={{
            position: "absolute",
            top: "0",
            margin: "0",
            right: "0px",
            width: "48px",
            height: "48px",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            background: "transparent",
            border: "none",
            WebkitTapHighlightColor: "transparent!important",
            cursor: "pointer",
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM9.003 14L4.76 9.757L6.174 8.343L9.003 11.172L14.659 5.515L16.074 6.929L9.003 14Z"
              fill="#00D147"
            />
          </svg>
        </button>
      )}
      {error && (
        <button
          className="UIInputBlock-ErrorIcon"
          style={{
            position: "absolute",
            top: "0",
            margin: "0",
            right: "0px",
            width: "48px",
            height: "48px",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            background: "transparent",
            border: "none",
            WebkitTapHighlightColor: "transparent!important",
            cursor: "pointer",
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM9 13H11V15H9V13ZM9 5H11V11H9V5Z"
              fill="#FE356B"
            />
          </svg>
          <span className="UIInputBlockError-Popup">{ErrorText}</span>
        </button>
      )}
      {eye && (
        <button
          style={{
            position: "absolute",
            top: "0",
            margin: "0",
            right: "0px",
            width: "48px",
            height: "48px",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            background: "transparent",
            border: "none",
            WebkitTapHighlightColor: "transparent!important",
            cursor: "pointer",
          }}
          onClick={() => {
            setEyeStatus(!eyeStatus);
          }}
        >
          {!eyeStatus ? (
            <svg
              width="23"
              height="18"
              viewBox="0 0 23 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="eyeStatus-enabled"
            >
              <path
                d="M11.4997 0C16.8917 0 21.3777 3.88 22.3187 9C21.3787 14.12 16.8917 18 11.4997 18C6.10766 18 1.62166 14.12 0.680664 9C1.62066 3.88 6.10766 0 11.4997 0ZM11.4997 16C13.5391 15.9996 15.5181 15.3068 17.1125 14.0352C18.707 12.7635 19.8226 10.9883 20.2767 9C19.8209 7.0133 18.7046 5.24 17.1103 3.97003C15.516 2.70005 13.538 2.00853 11.4997 2.00853C9.46136 2.00853 7.48335 2.70005 5.88904 3.97003C4.29473 5.24 3.17843 7.0133 2.72266 9C3.17676 10.9883 4.29234 12.7635 5.88681 14.0352C7.48128 15.3068 9.4602 15.9996 11.4997 16ZM11.4997 13.5C10.3062 13.5 9.1616 13.0259 8.31768 12.182C7.47377 11.3381 6.99966 10.1935 6.99966 9C6.99966 7.80653 7.47377 6.66193 8.31768 5.81802C9.1616 4.97411 10.3062 4.5 11.4997 4.5C12.6931 4.5 13.8377 4.97411 14.6816 5.81802C15.5256 6.66193 15.9997 7.80653 15.9997 9C15.9997 10.1935 15.5256 11.3381 14.6816 12.182C13.8377 13.0259 12.6931 13.5 11.4997 13.5ZM11.4997 11.5C12.1627 11.5 12.7986 11.2366 13.2674 10.7678C13.7363 10.2989 13.9997 9.66304 13.9997 9C13.9997 8.33696 13.7363 7.70107 13.2674 7.23223C12.7986 6.76339 12.1627 6.5 11.4997 6.5C10.8366 6.5 10.2007 6.76339 9.7319 7.23223C9.26306 7.70107 8.99966 8.33696 8.99966 9C8.99966 9.66304 9.26306 10.2989 9.7319 10.7678C10.2007 11.2366 10.8366 11.5 11.4997 11.5Z"
                fill="#2E2E2E"
              />
            </svg>
          ) : (
            <svg
              width="23"
              height="22"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="eyeStatus-disabled"
            >
              <path
                d="M17.3817 18.2971C15.6229 19.4126 13.5824 20.0034 11.4997 20.0001C6.10766 20.0001 1.62166 16.1201 0.680664 11.0001C1.11069 8.67078 2.28229 6.54296 4.02066 4.93407L0.891664 1.80807L2.30666 0.393066L22.1057 20.1931L20.6907 21.6071L17.3807 18.2971H17.3817ZM5.43466 6.35007C4.07567 7.58566 3.12898 9.20885 2.72266 11.0001C3.03496 12.3665 3.66193 13.6412 4.55367 14.7227C5.44541 15.8041 6.57729 16.6625 7.85922 17.2294C9.14114 17.7963 10.5377 18.0561 11.9378 17.9882C13.3378 17.9203 14.7026 17.5264 15.9237 16.8381L13.8957 14.8101C13.0324 15.3539 12.0099 15.5882 10.9959 15.4745C9.98194 15.3608 9.03671 14.906 8.31523 14.1845C7.59376 13.463 7.13889 12.5178 7.02523 11.5038C6.91156 10.4899 7.14585 9.46738 7.68966 8.60407L5.43466 6.35007ZM12.4137 13.3281L9.17166 10.0861C8.99373 10.539 8.95185 11.034 9.05117 11.5104C9.15049 11.9868 9.38668 12.4238 9.73078 12.7679C10.0749 13.1121 10.5119 13.3482 10.9883 13.4476C11.4647 13.5469 11.9597 13.505 12.4127 13.3271L12.4137 13.3281ZM20.3067 15.5921L18.8757 14.1621C19.5441 13.2094 20.0201 12.1353 20.2767 11.0001C20.0049 9.80979 19.4939 8.68721 18.7748 7.70056C18.0557 6.71391 17.1435 5.88379 16.0935 5.26067C15.0436 4.63755 13.878 4.23443 12.6674 4.07583C11.4568 3.91723 10.2267 4.00645 9.05166 4.33807L7.47366 2.76007C8.72066 2.27007 10.0797 2.00007 11.4997 2.00007C16.8917 2.00007 21.3777 5.88007 22.3187 11.0001C22.0122 12.6658 21.3235 14.2376 20.3067 15.5921ZM11.2227 6.50807C11.8592 6.46873 12.4968 6.56513 13.0932 6.79088C13.6896 7.01663 14.2313 7.36658 14.6822 7.81752C15.1332 8.26846 15.4831 8.81009 15.7088 9.40652C15.9346 10.003 16.031 10.6406 15.9917 11.2771L11.2217 6.50807H11.2227Z"
                fill="#2E2E2E"
              />
            </svg>
          )}
        </button>
      )}
      <span
        style={{
          fontFamily: "PT Sans Caption",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "24px",
          color: messageColor,
          textAlign: "left",
          width: "336px",
          display: "block",
          margin: "4px 0 0 0",
        }}
      >
        {message}
      </span>
    </div>
  );
};
