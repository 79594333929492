import {observer} from "mobx-react";
import React from "react";

export const Circle75 = observer((props) => {
    return <div>
        <div style={{width: `${props.size}px`, height: `${props.size}px`, margin: '0 auto', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
            <div style={{position: 'relative'}}>
                <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut">
                    <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="transparent"/>
                    <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="transparent"
                            strokeWidth="8"/>
                    <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke={props.color}
                            strokeWidth={props.strWidth} strokeDasharray={props.dasharray}
                            strokeDashoffset="-25"/>
                </svg>
                {props.dataFlag?
                    <span style={{position: "absolute", top: "70%", left: "50%", transform: `translate(0, 50%)`}}>
                            <p className={'game_text'} style={{paddingLeft: '5px', paddingBottom: '8px', marginTop: '-3px'}}>{props.percent} %</p>
                            <p className={'game_text'} style={{paddingLeft: '5px'}}>{props.time}</p>
                    </span>: <></>}

                {props.dataFlag?
                    <span style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                        <p className={'game_text'} style={{textAlign: "center"}}>{props.text}</p>
                    </span>: <></>}
            </div>
        </div>
    </div>
})

