import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {RecStore} from "./RecStore";
import styles from './Recommendation.module.css'
import reportStore from "../../../../../Stores/Report/ReportStore";
import {BigTextAndSvg} from "../../bigTextAndSvg";

const Recommendation = observer(() => {
  const load = RecStore.loading
  const data = RecStore.recommend

  useEffect(() => {
    load && reportStore.bestGame && reportStore.worseGame && RecStore.loadData();
    console.log(reportStore.SelectedLevel)

  },[reportStore.bestGame, reportStore.worseGame, reportStore.SelectedLevel])



  //TODO:добавить проверку ошибок

  return (
    <div className={styles.wrap}>
      {
        reportStore.bestGame == null || reportStore.worseGame == null ?
          ''
          :
          RecStore.loading ?
            <p>Загрузка рекомендаций...</p>
            :
            <>
              <BigTextAndSvg className={styles.header} divM={'30px auto 0 auto'} pM={'0 0 10px 0'} div2M={'0 auto 30px auto'} text={'Рекомендации'}/>
              {
                data.map(item => {
                return (
                  <>
                    <p className={styles.text}>{item.text}</p>
                    {item.img !== null && <img src={item.img}/>}
                  </>
                  )
                })
              }

            </>
      }

    </div>
  );
})

export default Recommendation;