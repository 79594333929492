import {observer} from "mobx-react";
import * as style from './ColumnDash.module.css'

export const ColumnDash2 = observer((props) => {
  return (
    <>
      <div className={`${style.lineGame} ${props.iter == 0 ? style.lineGame_first : "" }`}/>
          <div className={style.a}>
            <div className={style.b} style={{width: `${props.width}%`}}></div>
            <p className={`${style.percent} ${props.width >= 5 ? style.percent_right : style.percent_left}`}>{Math.round(props.width)}</p>
            <p className={style.name}>{props.text}</p>
          </div>
      <div className={style.lineGame}/>
    </>
    )

})