import React from "react";

export const DashboardAsideButton = ({
  insideText,
  svg,
  margin,
  state,
  eventKey,
  setState,
}) => {
  return (
    <button
      style={{
        cursor: "pointer",
        background: state === eventKey ? "#4FC1E9CC" : "transparent",
        width: "184px",
        height: "48px",
        padding: "12px 24px",
        borderRadius: "8px",
        WebkitUserSelect: "none",
        msUserSelect: "none",
        userSelect: "none",
        margin: margin,
        border: "none",
        display: "flex",
        alignItems: "center",
      }}
      onClick={() => {
        setState(eventKey);
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "10px",
          filter: state === eventKey ? "opacity(1)" : "opacity(0.4)",
        }}
        dangerouslySetInnerHTML={{ __html: svg }}
      />
      <span
        style={{
          color: "#2E2E2E",
        }}
      >
        {insideText}
      </span>
    </button>
  );
};
