import { UserRoles } from "../../../Stores/Registration/RegistrationForm";
import request from "../../Request";
import {rThen} from "../../requestThen";


export const userRegistration = async (props:{
    email:string,
    password:string,
    phone:string,
    role:UserRoles,
    firstname:string,
    lastname:string,
    middlename:string
}) => {

    let phoneNumber =  props.phone.replace(/[() -]/ig, "")

    return request.post(
        `/api/User`,
        {
            email:props.email,
            password:props.password,
            phoneNumber,
            role:props.role,
            firstname:props.firstname,
            lastname:props.lastname,
            middlename:props.middlename
        },
        false
    ).then(r => {
        return rThen(r);
    })
}
