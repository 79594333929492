import React from 'react';


const BigButton = (props) => {
  const {active, text} = props;
  return (
    <div className="form_group" style={{marginRight: '9px'}}>
      <button id="button_level_1"
              {...props}
              className={`buttons button_transparent ${active && "button_level_select"}`}
              style={{width: '100%', margin: 0, padding: 0}}
      >
        {text}
      </button>
    </div>
  );
};

export default BigButton;