import recIMG from "./tempGetRecomm/imgs/rec1.webp"
const serverData = {
  "Найди лишнее":{
    bestGame:[
      {
        text:"У вашего ребёнка хорошо развито умение классифицировать.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развит кругозор и представление об окружающем мире.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развито зрительное восприятие и анализ.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развита память, интеллект, умение образно и логически мыслить.",
        img:null,
      },
    ],
    worseGame:[
      {
        text:"Для того, чтобы развить умение классифицировать, рекомендуем вам игры на «исключение»: найти лишнюю фигуру или объект и попросить ребёнка объяснить свой выбор.",
        img:null,
      },
      {
        text:"Для того, чтобы расширить кругозор и представление об окружающем мире, рекомендуем вам игры на «исключение»: возьмите картинки нескольких вещей домашнего быта и одну картинку верхней одежды. ," +
          "img:null,Попросите ребёнка найти лишнее и объяснить свой выбор. Задания можно усложнять, совмещая более трудные объекты (например, верхнюю одежу и обувь)"
      },
      {
        text:"Для того, чтобы развить зрительное восприятие и анализ, рекомендуем вам игры на «исключение»: возьмите картинки разных по цвету и форме фигур, и попросите ребёнка найти лишнее. Задания можно усложнять, совмещая более сложные формы.",
        img:null,
      },
      {
        text:"Для того, чтобы развить память, интеллект, умение образно и логически мыслить, рекомендуем вам игры на запоминание: разложите фигурки в определённом порядке и попросите ребёнка запомнить их расположение. После перемешайте их и попросите ребёнка вернуть всех на свои места. Задания можно усложнять, разбивая объекты на небольшие группы по признакам.",
        img:null,
      },
    ]
  },
  "Чем мы похожи?":{
    bestGame:[
      {
        text:"У вашего ребёнка хорошо развит навык классификации по определенным признакам.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развит навык сопоставления объектов и выявления сходных черт и различий.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развито внимание, память и мыслительные способности.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развита способность к последовательному выполнению задач.",
        img:null,
      },
    ],
    worseGame:[
      {
        text:"Для того, чтобы развить навык классификации по определенным признакам, рекомендуем вам игры на соотнесения объектов по признакам: предложите ребёнку назвать все слова, которые схожи по признаку ," +
          "img:null,«горячо». Задания можно усложнять, давая более сложные признаки или добавляя классификации (например, назови все растения, которые относятся к деревьям)."
      },
      {
        text:"Для того, чтобы развить навык сопоставления объектов и выявления сходных черт и различий, рекомендуем вам игры на «соотнесение»: назовите или положите перед ребёнком несколько карточек с фруктами, и несколько с овощами. Попросите ребёнка распределить карточки по группам и объяснить свой выбор.",
        img:null,
      },
      {
        text:"Для того, чтобы развить внимание, память и мыслительные способности, рекомендуем вам игры на запоминание: разложите фигурки в определённом порядке и попросите ребёнка запомнить их расположение. После перемешайте их и попросите ребёнка вернуть всех на свои места. Задания можно усложнять, разбивая объекты на небольшие группы по признакам.",
        img:null,
      },
      {
        text:"Для того, чтобы развить способность к последовательному выполнению задач, рекомендуем вам игру «разложи по порядку»: попросите ребёнка разложить фигуры/объекты по цветам, задав нужный порядок. Например: разложи красные круги первыми, следом за ними положи зелёные квадраты, а самыми последними — жёлтые треугольники. Для усложнения уровня можно разбивать предмет по частям и просить ребёнка собрать в правильном порядке",
        img:null,
      },
    ]
  },
  "Главное дело":{
    bestGame:[
      {
        text:"У вашего ребёнка хорошо развито умение выделять главное.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развит кругозор и представление об окружающем мире.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развито зрительное восприятие и анализ.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развита память, интеллект, умения образно и логически мыслить.",
        img:null,
      },
    ],
    worseGame:[
      {
        text:"Для того, чтобы развить умение выделять главное, рекомендуем вам игры на нахождение главного признака предмета: разложите карточки предметов перед ребёнком и задайте главный признак, по которому должен проходить отбор карточек.  Например: к признаку «форма» будет относиться колесо автомобиля или футбольный мяч, а к признаку «непромокаемость» — резиновые сапоги и дождевик. По подобному принципу составьте задания для ребёнка в зависимости от желаемой сложности.",
        img:null,
      },
      {
        text:"Для того, чтобы развить кругозор и представление об окружающем мире, рекомендуем вам игру «Найди пару»: подберите 7 – 8 пар картинок, связанных друг с другом по смыслу. Разложите их попарно перед ребенком. Например, картинка, на которой нарисовано дерево, кладется рядом с изображением леса, а изображение дома рядом с изображением окна. Возможны любые варианты взаимосвязей предметов.",
        img:null,
      },
      {
        text:"Для того, чтобы развить зрительное восприятие и анализ, рекомендуем вам игры на «исключение»: возьмите картинки разных по цвету и форме фигур, и попросите ребёнка найти лишнее. Задания можно усложнять, совмещая более сложные формы.",
        img:null,
      },
      {
        text:"Для того, чтобы развить память, интеллект, умение образно и логически мыслить, рекомендуем вам игры на запоминание: разложите фигурки в определённом порядке и попросите ребёнка запомнить их расположение. После перемешайте их и попросите ребёнка вернуть всех на свои места. Задания можно усложнять, разбивая объекты на небольшие группы по признакам.",
        img:null,
      },
    ]
  },
  "Раскраска":{
    bestGame:[
      {
        text:"У вашего ребёнка хорошо развито умение соотносить фигуру с её месторасположением относительно других фигур.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развито умение решать логические задачи и задачи на смекалку.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развито зрительное восприятие.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развита память, интеллект, умения образно и логически мыслить.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развито умение ориентироваться в пространстве.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развито представление о геометрических фигурах и форме предметов.",
        img:null,
      },
    ],
    worseGame:[
      {
        text:"Для того, чтобы развить умение выделять главное, рекомендуем вам игры на нахождение главного признака предмета: разложите карточки предметов перед ребёнком и задайте главный признак, по которому должен проходить отбор карточек.  Например: к признаку «форма» будет относиться колесо автомобиля или футбольный мяч, а к признаку «непромокаемость» — резиновые сапоги и дождевик. По подобному принципу составьте задания для ребёнка в зависимости от желаемой сложности.",
        img:null,
      },
      {
        text:"Для того, чтобы развить умение соотносить фигуру с её месторасположением относительно других фигур, рекомендуем вам игру «найди место: разложите перед ребёнком карточки фигур в разном порядке и попросите ребёнка назвать местоположение заданной фигуры. Например: положите круг, выше круга треугольник, правее круга квадрат, а левее треугольника ромб. Попросите ребёнка назвать положение ромба.",
        img:null,
      },
      {
        text:"Для того, чтобы развить умение решать логические задачи и задачи на смекалку, рекомендуем вам игры на «исключение»: возьмите картинки разных по цвету и форме фигур, и попросите ребёнка найти лишнее. Задания можно усложнять, совмещая более сложные формы.",
        img:null,
      },
      {
        text:"Для того, чтобы развить зрительное восприятие, рекомендуем игры с геометрическими фигурами: нарисуйте пересекающиеся пары фигур, и попросите ребёнка закрасить выбранную вами фигуру. Для увеличения сложности можно добавлять как количество фигур, так и делать более сложные пересечения между ними. Например:",
        img:recIMG,
      },
      {
        text:"Для того, чтобы развить память, интеллект, умение образно и логически мыслить, рекомендуем вам игры на запоминание: разложите фигурки в определённом порядке и попросите ребёнка запомнить их расположение. После перемешайте их и попросите ребёнка вернуть всех на свои места. Задания можно усложнять, разбивая объекты на небольшие группы по признакам.",
        img:null,
      },
      {
        text:"Для того, чтобы развить умение ориентироваться в пространстве, рекомендуем вам игру «найди место: разложите перед ребёнком карточки фигур в разном порядке и попросите ребёнка назвать местоположение заданной фигуры. Например: положите круг, выше круга треугольник, правее круга квадрат, а левее треугольника ромб. Попросите ребёнка назвать положение ромба.",
        img:null,
      },
      {
        text:"Для того, чтобы развить представление о геометрических фигурах и форме предметов, рекомендуем игры с геометрическими фигурами: разложите перед ребёнком карточки с разными фигурами и названиями этих фигур. Попросите ребёнка соотнести названия с фигурами.",
        img:null,
      },
    ]
  },
  "Посчитай":{
    bestGame:[
      {
        text:"У вашего ребёнка хорошо развиты сенсорные, предметно-действенные познания математических свойств и отношений: сопоставление, группировка, упорядочение.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развито представление о математических свойствах и отношений.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развиты математические способы познания действительности: счет, измерение, простейшие вычисления.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развиты интеллектуально-творческие проявления: находчивость, смекалка, догадки, сообразительность, стремление к поиску нестандартных решений.",
        img:null,
      },
    ],
    worseGame:[
      {
        text:"Для того, чтобы развить сенсорные, предметно-действенные познания математических свойств и отношений (сопоставление, группировка, упорядочение), рекомендуем игру «посчитай»: распределите с одной стороны n количество игрушек, с другой стороны на 1 игрушку больше или меньше. Попросите ребёнка посчитать, сколько игрушек с каждой стороны и равны ли стороны по количеству игрушек между собой.",
        img:null,
      },
      {
        text:"Для того, чтобы развить представление о математических свойствах и отношениях, рекомендуем игру «посчитай»: распределите с одной стороны n количество игрушек, с другой стороны на 1 игрушку больше или меньше. Попросите ребёнка посчитать, сколько игрушек с каждой стороны и равны ли стороны по количеству игрушек между собой.",
        img:null,
      },
      {
        text:"Для того, чтобы развить математические способы познания действительности (счет, измерение, простейшие вычисления), рекомендуем игру «посчитай»: распределите с одной стороны n количество игрушек, с другой стороны на 1 игрушку больше или меньше. Попросите ребёнка посчитать, сколько игрушек с каждой стороны и где игрушек больше: справа или слева.",
        img:null,
      },
      {
        text:"Для того, чтобы развить интеллектуально-творческие проявления (находчивость, смекалку, догадки, сообразительность, стремление к поиску нестандартных решений), рекомендуем игру «собери»: положите перед ребёнком различной формы фигуры, и попросите собрать из них какой-либо предмет. Например: из круга и двух треугольников можно собрать конфету. Увеличивайте сложность давая бОльшее количество предметов",
        img:null,
      },
    ]
  },
  "Круги Эйлера":{
    bestGame:[
      {
        text:"У вашего ребёнка хорошо развито умение наглядно моделировать отношения между двумя множествами объектов.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развит навык классификации элементов множеств по определенной принадлежности, свойствам, признакам",
        img:null,
      },
    ],
    worseGame:[
      {
        text:"Для того, чтобы развить умение наглядно моделировать отношения между двумя множествами объектов, рекомендуем игру «найди общее»: расположите в правой части предметы разные по форме, но одинаковые по цвету, а в левой части предметы, разные по цвету, но имеющие одинаковую форму. Попросите ребёнка найти один общий признак для этих групп предметов.",
        img:null,
      },
      {
        text:"Для того, чтобы развить навык классификации элементов множеств по определенной принадлежности, свойствам, признакам, рекомендуем игру «найди общее»: расположите в правой части предметы разные по форме, но одинаковые по цвету, а в левой части предметы, разные по цвету, но имеющие одинаковую форму. Попросите ребёнка найти один общий признак для этих групп предметов.",
        img:null,
      },
    ]
  },
  "Самый важный признак":{
    bestGame:[
      {
        text:"У вашего ребёнка хорошо развито умение классифицировать.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развит кругозор и представление об окружающем мире.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развито зрительное восприятие и анализ.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развита память, интеллект, умение образно и логически мыслить.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развит навык обобщения.",
        img:null,
      },
    ],
    worseGame:[
      {
        text:"Для того, чтобы развить умение классифицировать, рекомендуем вам игры на «соотнесение»: назовите или положите перед ребёнком несколько карточек с фруктами, и несколько с овощами. Попросите ребёнка распределить карточки по группам и объяснить свой выбор. Для усложнения уровня можно брать более трудные предметы для классификаций.",
        img:null,
      },
      {
        text:"Для того, чтобы расширить кругозор и представление об окружающем мире, рекомендуем игру «угадай по описанию». Предложите угадать ребёнку, о чем (о каком овоще, животном, игрушке) вы говорите и дать описание этого предмета. Например: это овощ, он красный, сочный, растет на огороде (Помидор). Если ребенок затрудняется с ответом, перед выложите перед ним картинки с различными овощами.",
        img:null,
      },
      {
        text:"Для того, чтобы развить зрительное восприятие, рекомендуем игры с геометрическими фигурами: нарисуйте пересекающиеся пары фигур, и попросите ребёнка закрасить выбранную вами фигуру. Для увеличения сложности можно добавлять как количество фигур, так и делать более сложные пересечения между ними. Например:",
        img:recIMG,
      },
      {
        text:"Для того, чтобы развить память, интеллект, умение образно и логически мыслить, рекомендуем вам игры на запоминание: разложите фигурки в определённом порядке и попросите ребёнка запомнить их расположение. После перемешайте их и попросите ребёнка вернуть всех на свои места. Задания можно усложнять, разбивая объекты на небольшие группы по признакам.",
        img:null,
      },
      {
        text:"Для того, чтобы развить навык обобщения, рекомендуем вам игры на «соотнесение»: назовите или положите перед ребёнком несколько карточек с фруктами, и несколько с овощами. Попросите ребёнка распределить карточки по группам и объяснить свой выбор. Для усложнения уровня можно брать более сложные объекты и обобщать по признакам.",
        img:null,
      },
    ]
  },
  "Расшифруй фигуру":{
    bestGame:[
      {
        text:"У вашего ребёнка хорошо развито представление о геометрических фигурах.",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развито представление о геометрических объемных фигурах, сформированы понятия вид «сверху», «снизу», «с боку».",
        img:null,
      },
      {
        text:"У вашего ребёнка хорошо развит навык нахождения фигуры по заданным признакам.",
        img:null,
      },
    ],
    worseGame:[
      {
        text:"Для того, чтобы расширить представление о геометрических фигурах, рекомендуем игры, направленные на изучение фигур: разложите перед ребёнком карточки с разными фигурами и названиями этих фигур. Попросите ребёнка соотнести названия с фигурами.",
        img:null,
      },
      {
        text:"Для того, чтобы развить представление о геометрических объемных фигурах и сформировать понятия вид «сверху», «снизу», «с боку», рекомендуем вам игру «найди место»: разложите перед ребёнком карточки фигур в разном порядке и попросите ребёнка назвать местоположение заданной фигуры. Например: положите круг, выше круга треугольник, правее круга квадрат, а левее треугольника ромб. Попросите ребёнка назвать положение ромба.",
        img:null,
      },
      {
        text:"Для того, чтобы развить навык нахождения фигуры по заданным признакам, рекомендуем игру «найди фигуру»: разложите перед ребёнком карточки с разными фигурами (по форме и цвету). Попросите ребёнка найти красный круг/зелёный треугольник или оранжевый квадрат.\n" +
          "Можно усложнить задания, добавив более сложные фигуры.",
        img:null,
      },
    ]
  },
}


export const getRecommendation = async (gameName) => {

    return serverData[gameName]
}