import React, { useState } from "react";
import { observer } from "mobx-react";
import "react-select-search/style.css";
import { DashboardMobileHeader } from "./DashboardMobileHeader";
import "./DashboardMobile.css";
import UIBlockButton, {
  UIBlockButtonColor,
} from "../../../../../../Components/Katelinlis/Button.tsx";

export const DashboardMobileQuiz = observer((props) => {
  let accountType;

  if (localStorage.getItem("accountType") != null) {
    accountType = localStorage.getItem("accountType");
  } else {
    window.location.replace("/");
  }
  const kids = [
    { id: 1, Name: "Настя", Surname: "Антонова" },
    { id: 2, Name: "Костя", Surname: "Антонов" },
  ];

  const [SelectedKid, setSelectedKid] = useState(["undefined"]);

  if (kids.length === 1 && SelectedKid[0] === "undefined") {
    setSelectedKid([kids[0].id]);
  }

  const KidSelection = ({ id, Name, Surname }) => {
    return (
      <div className="DashboardMobileQuizSelectKid-Card">
        <h2>
          {id}. {Name} {Surname}
        </h2>
        <button
          className="teacherStatusBlock-invite"
          onClick={() => {
            // ChangeMe
            setSelectedKid([kids[0].id]);
          }}
        >
          Пригласить
        </button>
      </div>
    );
  };

  return (
    <>
      {SelectedKid[0] !== "undefined" ? (
        <>
          <DashboardMobileHeader accountType={accountType} />
          <div className="DashboardMobile-Content">
            <div className="DashboardMobile-Quiz">
              <h1 className="DashboardMobile-h1">Олимпиада</h1>
              <h2>Текущая олимпиада</h2>
              <a href="#">
                <img
                  className="unselectable quiz-banner"
                  src="/cosmopiada.webp"
                />
              </a>
              <h2>Предыдущая олимпиада</h2>
              <a href="#">
                <img
                  className="unselectable quiz-banner"
                  src="/cosmopiada.webp"
                />
              </a>
            </div>
          </div>
        </>
      ) : (
        <>
          <DashboardMobileHeader accountType={accountType} />
          <div className="DashboardMobile-Content">
            <div className="DashboardMobileQuiz-SelectKid">
              <p>Выберите того, кто будет участвовать в&nbsp;олимпиаде</p>
              {/* ChangeMe */}
              <KidSelection id="1" Name="Настя" Surname="Антонова" />
              <KidSelection id="2" Name="Катя" Surname="Антонова" />
            </div>
          </div>
        </>
      )}
    </>
  );
});
