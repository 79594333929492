import React, { useEffect, useState } from "react";
import authStore from "../../../Stores/AuthStore/AuthStore";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import relatedAccsStore from "../../../Stores/RelatedAccsStore/RelatedAccsStore";
import { useHistory, useLocation } from "react-router-dom";

const ProfileDropdown = observer(() => {
  const [person, setPerson] = useState("РОДИТЕЛЬ");
  const location = useLocation();
  const history = useHistory();
  const userType = authStore.isAuth ? authStore.decodeJwt.userType : null;

  useEffect(() => {
    if (authStore.isAuth) {
      authStore.decodeJwt.userType === "Parent" && setPerson("РОДИТЕЛЬ");
      authStore.decodeJwt.userType === "Pedagog" && setPerson("ПЕДАГОГ");
      if (authStore.decodeJwt.userType === "Children") {
        setPerson("РЕБЁНОК");
        history.push("/game");
      }
    }
  }, [userType]);

  if (!authStore.isAuth) {
    return location.pathname !== "/authorization" ? (
      <Link
        to={"/authorization"}
        style={{ display: "flex", textDecoration: "none", color: "#2E2E2E" }}
        className="headerButton"
      >
        <div
          style={{
            marginRight: "16px",
            width: "100%",
            display: "flex",
            alignItems: "baseline",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="21"
            viewBox="0 0 16 21"
            fill="none"
          >
            <path
              d="M16 21H14V19C14 18.2044 13.6839 17.4413 13.1213 16.8787C12.5587 16.3161 11.7956 16 11 16H5C4.20435 16 3.44129 16.3161 2.87868 16.8787C2.31607 17.4413 2 18.2044 2 19V21H0V19C0 17.6739 0.526784 16.4021 1.46447 15.4645C2.40215 14.5268 3.67392 14 5 14H11C12.3261 14 13.5979 14.5268 14.5355 15.4645C15.4732 16.4021 16 17.6739 16 19V21ZM8 12C7.21207 12 6.43185 11.8448 5.7039 11.5433C4.97595 11.2417 4.31451 10.7998 3.75736 10.2426C3.20021 9.68549 2.75825 9.02405 2.45672 8.2961C2.15519 7.56815 2 6.78793 2 6C2 5.21207 2.15519 4.43185 2.45672 3.7039C2.75825 2.97595 3.20021 2.31451 3.75736 1.75736C4.31451 1.20021 4.97595 0.758251 5.7039 0.456723C6.43185 0.155195 7.21207 -1.17411e-08 8 0C9.5913 2.37122e-08 11.1174 0.632141 12.2426 1.75736C13.3679 2.88258 14 4.4087 14 6C14 7.5913 13.3679 9.11742 12.2426 10.2426C11.1174 11.3679 9.5913 12 8 12ZM8 10C9.06087 10 10.0783 9.57857 10.8284 8.82843C11.5786 8.07828 12 7.06087 12 6C12 4.93913 11.5786 3.92172 10.8284 3.17157C10.0783 2.42143 9.06087 2 8 2C6.93913 2 5.92172 2.42143 5.17157 3.17157C4.42143 3.92172 4 4.93913 4 6C4 7.06087 4.42143 8.07828 5.17157 8.82843C5.92172 9.57857 6.93913 10 8 10Z"
              fill="#74777A"
            />
          </svg>
        </div>
        <span
          style={{
            fontFamily: "PT Sans Caption",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "18px",
            color: "#2E2E2E",
          }}
        >
          Вход
        </span>
      </Link>
    ) : (
      <Link
        to={"/registration/select"}
        style={{ display: "flex", textDecoration: "none", color: "#2E2E2E" }}
        className="headerButton"
      >
        <div
          style={{
            marginRight: "16px",
            width: "100%",
            display: "flex",
            alignItems: "baseline",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="21"
            viewBox="0 0 16 21"
            fill="none"
          >
            <path
              d="M16 21H14V19C14 18.2044 13.6839 17.4413 13.1213 16.8787C12.5587 16.3161 11.7956 16 11 16H5C4.20435 16 3.44129 16.3161 2.87868 16.8787C2.31607 17.4413 2 18.2044 2 19V21H0V19C0 17.6739 0.526784 16.4021 1.46447 15.4645C2.40215 14.5268 3.67392 14 5 14H11C12.3261 14 13.5979 14.5268 14.5355 15.4645C15.4732 16.4021 16 17.6739 16 19V21ZM8 12C7.21207 12 6.43185 11.8448 5.7039 11.5433C4.97595 11.2417 4.31451 10.7998 3.75736 10.2426C3.20021 9.68549 2.75825 9.02405 2.45672 8.2961C2.15519 7.56815 2 6.78793 2 6C2 5.21207 2.15519 4.43185 2.45672 3.7039C2.75825 2.97595 3.20021 2.31451 3.75736 1.75736C4.31451 1.20021 4.97595 0.758251 5.7039 0.456723C6.43185 0.155195 7.21207 -1.17411e-08 8 0C9.5913 2.37122e-08 11.1174 0.632141 12.2426 1.75736C13.3679 2.88258 14 4.4087 14 6C14 7.5913 13.3679 9.11742 12.2426 10.2426C11.1174 11.3679 9.5913 12 8 12ZM8 10C9.06087 10 10.0783 9.57857 10.8284 8.82843C11.5786 8.07828 12 7.06087 12 6C12 4.93913 11.5786 3.92172 10.8284 3.17157C10.0783 2.42143 9.06087 2 8 2C6.93913 2 5.92172 2.42143 5.17157 3.17157C4.42143 3.92172 4 4.93913 4 6C4 7.06087 4.42143 8.07828 5.17157 8.82843C5.92172 9.57857 6.93913 10 8 10Z"
              fill="#74777A"
            />
          </svg>
        </div>
        <span
          style={{
            fontFamily: "PT Sans Caption",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "18px",
            color: "#2E2E2E",
          }}
        >
          Регистрация
        </span>
      </Link>
    );
  }
  if (authStore.decodeJwt == null) {
    window.location.reload();
  }

  if (authStore.decodeJwt.userType === "User") {
    return <div style={{ width: "198px" }}></div>;
  }

  let userData;
  let avatar;
  if (authStore?.userData === undefined) {
    userData = { UserName: authStore.decodeJwt.user_name };
    avatar = authStore.decodeJwt.avatar;
  } else {
    userData = authStore.userData;
    avatar = authStore.masterAccountData.avatar;
  }

  return (
    <>
      <div className="header_menu_select">
        <div
          className="header_menu"
          onClick={() => {
            onHeaderMenu();
          }}
        >
          <div className="user_move">
            <div className="user_data">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div>
                  <p
                    id="user_fio"
                    className="dropdown_menu_elem"
                    style={{ display: "table-caption" }}
                  >
                    {userData.UserName}
                  </p>
                </div>
              </div>
              <p className="header_small_text dropdown_menu_elem">{person}</p>
            </div>
            <div className="user_image">
              <img src={avatar} className="dropdown_menu_elem avatar_header" />
            </div>
            <div
              className="right_menu"
              style={{
                paddingRight: "10px",
                paddingLeft: "10px",
                margin: "auto 0",
              }}
              onClick={() => {
                document.getElementById("right_open_menu_id").style.display =
                  "inline";
              }}
            >
              <svg
                onClick={() => {
                  document.getElementById("opacity_panel").style.display =
                    "inline";
                }}
                className="image_right_menu"
                width="34"
                height="23"
                viewBox="0 0 34 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="image_right_menu"
                  d="M0 23H34V19.1667H0V23ZM0 13.4167H34V9.58333H0V13.4167ZM0 0V3.83333H34V0H0Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        </div>

        {authStore.headerMenuVisible ? (
          <div id={"dropdown_menu_id"} className={"dropdown_menu"}>
            <Link
              to={"/account"}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                textDecoration: "none",
                marginTop: "10px",
              }}
            >
              <div className="elem_menu" style={{}}>
                <svg
                  className="svg_elem_menu"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 21.6C6.708 21.6 2.4 17.292 2.4 12C2.4 6.708 6.708 2.4 12 2.4C17.292 2.4 21.6 6.708 21.6 12C21.6 17.292 17.292 21.6 12 21.6Z"
                    fill="black"
                  />
                  <path
                    d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20L12 24L20 20V18C20 16.2448 17.6792 15.0643 15.3333 14.4643C14.1241 14.155 12.9082 14 12 14Z"
                    fill="black"
                  />
                </svg>

                <p style={{ textAlign: "end" }}>ПРОФИЛЬ</p>
              </div>
            </Link>
            {authStore.decodeJwt !== null &&
            (authStore.decodeJwt.userType === "Parent" ||
              authStore.decodeJwt.userType === "Children") ? (
              <div
                className="elem_menu dropdown_menu_elem"
                onClick={() => {
                  authStore.profileSelector = !authStore.profileSelector;
                  if (
                    authStore.profileSelector &&
                    relatedAccsStore.relatedAccounts.length === 0
                  ) {
                    relatedAccsStore.getRelatedAccounts(
                      authStore.decodeJwt.userId,
                      authStore.access
                    );
                  }
                }}
              >
                <svg
                  className="svg_elem_menu dropdown_menu_elem"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="dropdown_menu_elem"
                    d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 21.6C6.708 21.6 2.4 17.292 2.4 12C2.4 6.708 6.708 2.4 12 2.4C17.292 2.4 21.6 6.708 21.6 12C21.6 17.292 17.292 21.6 12 21.6ZM17.004 15.312L15.684 13.992C16.536 12.396 16.32 10.38 14.976 9.036C14.148 8.208 13.08 7.8 12 7.8C11.964 7.8 11.928 7.812 11.892 7.812L13.2 9.12L11.928 10.392L8.532 6.996L11.928 3.6L13.2 4.872L12.048 6.024C13.572 6.036 15.084 6.6 16.248 7.752C18.288 9.804 18.54 12.984 17.004 15.312ZM15.468 17.004L12.072 20.4L10.8 19.128L11.94 17.988C10.428 17.976 8.916 17.388 7.764 16.236C5.712 14.184 5.46 11.016 6.996 8.688L8.316 10.008C7.464 11.604 7.68 13.62 9.024 14.964C9.864 15.804 10.98 16.212 12.096 16.176L10.8 14.88L12.072 13.608L15.468 17.004Z"
                    fill="black"
                  />
                </svg>

                <p className="dropdown_menu_elem" style={{ textAlign: "end" }}>
                  Сменить аккаунт
                </p>
              </div>
            ) : (
              <></>
            )}

            {authStore.profileSelector ? (
              <>
                {relatedAccsStore.relatedAccounts.map((account) => {
                  return (
                    <div
                      id="user_selector"
                      onClick={() =>
                        authStore.ChangeRelatedAccount(account.UserId)
                      }
                    >
                      <p style={{ textAlign: "end" }}>
                        {account.UserName}
                        <svg
                          className="svg_elem_right_menu"
                          style={{ margin: "auto 0" }}
                          width="12"
                          height="12"
                          viewBox="0 0 12 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.71451 0.75099C1.08765 0.110796 0 0.554625 0 1.45061V20.622C0 21.5078 1.06646 21.9562 1.69946 21.3367L11.2852 11.9549C11.6799 11.5686 11.6867 10.9353 11.3002 10.5406L1.71451 0.75099Z"
                            fill="black"
                          />
                        </svg>
                      </p>
                    </div>
                  );
                })}
              </>
            ) : (
              <></>
            )}

            {authStore.decodeJwt !== null &&
            authStore.decodeJwt.userType === "Parent" ? (
              <Link
                to={"/children/add"}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  textDecoration: "none",
                }}
              >
                <div className="elem_menu">
                  <svg
                    className="svg_elem_menu"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                  >
                    <g id="Слой_2" data-name="Слой 2">
                      <g id="Слой_1_копия_" data-name="Слой 1 (копия)">
                        <path style={{ fill: "none" }} d="M0,0H24V24H0Z" />
                        <path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm0,21.33A9.33,9.33,0,1,1,21.33,12,9.32,9.32,0,0,1,12,21.33ZM13.33,6.67H10.67v4h-4v2.66h4v4h2.66v-4h4V10.67h-4Z" />
                      </g>
                    </g>
                  </svg>

                  <p style={{ textAlign: "end" }}>Добавить ребёнка</p>
                </div>
              </Link>
            ) : (
              <></>
            )}
            <div className="elem_menu" onClick={authStore.Logout}>
              <svg
                className="svg_elem_menu"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3333 0H10.6667V13.3333H13.3333V0ZM19.7733 2.89333L17.88 4.78667C19.9867 6.48 21.3333 9.08 21.3333 12C21.3333 17.16 17.16 21.3333 12 21.3333C6.84 21.3333 2.66667 17.16 2.66667 12C2.66667 9.08 4.01333 6.48 6.10667 4.77333L4.22667 2.89333C1.64 5.09333 0 8.34667 0 12C0 18.6267 5.37333 24 12 24C18.6267 24 24 18.6267 24 12C24 8.34667 22.36 5.09333 19.7733 2.89333Z"
                  fill="black"
                />
              </svg>

              <p style={{ textAlign: "end" }}>Выйти</p>
            </div>
          </div>
        ) : (
          <div>Вход</div>
        )}
      </div>
    </>
  );
});

export default ProfileDropdown;

function onHeaderMenu() {
  authStore.headerMenuVisible = true;
  document.getElementById("opacity_panel_two").style.display = "inline";
}

window.addEventListener("click", function (event) {
  if (!event.target.matches(".dropdown_menu_elem")) {
    document.getElementById("opacity_panel_two").style.display = "none";
    authStore.headerMenuVisible = false;
    authStore.profileSelector = false;
  }
});
