import React from "react";
import {observer} from "mobx-react";
import {Circle75_2} from "../Atoms/Circle75_2";
import reportStore, {Base64}  from "../../../../Stores/Report/ReportStore";



export const CircleDouble = observer(({bigSize, smallSize, time, percent, gameName, totalTime, encode}) => {

    const nuller = time > 0 && totalTime > 0


    return <>
      <div style={{position: 'relative', width: bigSize, height:bigSize, margin:'auto'}}>
        <Circle75_2 size={bigSize}
                  color={'#4FC1E9'}
                  dataFlag={false}
                  dasharray={`
                    ${!nuller ? 0 : parseInt(Math.round(time / totalTime * 75))} 
                    ${100 - (!nuller ? 0 : parseInt(Math.round(time / totalTime * 75)))}
                  `}
                  strWidth={5}
                  text={!encode ? Base64.decode(gameName) : gameName}
                  time={time / 60 >= 1 ?
                    `${Math.floor(time/60)}м.${time - Math.floor(time/60) * 60}с.`
                    :
                    `${time}с.`
                  }

        />

        <Circle75_2 size={smallSize} color={'#A0D468'}
                  dataFlag={true}
                  dasharray={`
                    ${percent == 0 ? 0 : parseInt(Math.round(percent / 100 * 75))} 
                    ${100 - (percent == 0  ? 0 : parseInt(Math.round(percent / 100 * 75)))}
                  `}
                  strWidth={8}
                  text={!encode ? Base64.decode(gameName) : gameName}
                  time={time / 60 >= 1 ?
                      `${Math.floor(time/60)}м.${time - Math.floor(time/60) * 60}с.`
                    :
                      `${time}с.`
                  }
                  percent={parseInt(Math.round(percent))
        }/>

      </div>

    </>
})