import React, { useState } from "react";
import { observer } from "mobx-react";
import "react-select-search/style.css";
import { useHistory, Link } from "react-router-dom";
import authStore from "../../../../../../Stores/AuthStore/AuthStore";
import { CheckStep } from "../../../../registration1";
import UIBlockButton, {
  UIBlockButtonColor,
} from "../../../../../../Components/Katelinlis/Button.tsx";
import { UIInputBlock } from "../UIInputBlock";
import modalStore from "../../../../../../Components/modal/modalStore";

export const DirectorEduStep = observer((props) => {
  const [SuccessPopup, setSuccessPopup] = useState(false);

  if (SuccessPopup) {
    window.scrollTo(0, 0);
    document.getElementsByTagName("body")[0].style =
      "height: 100vh; overflow-y: hidden;";
  } else {
    document.getElementsByTagName("body")[0].style = "";
  }

  let history = useHistory();
  if (authStore.isRegistration) {
    CheckStep("director");
  }

  return (
    <>
      <div
        id="DirectorEduStep-Container"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translateY(-50%) translateX(-50%)",
        }}
      >
        <h1
          style={{
            margin: "32px 0",
            fontSize: "24px",
            fontWeight: "bold",
            fontFamily:
              "'Open Sans','Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
            WebkitUserSelect: "none",
            MsUserSelect: "none",
            UserSelect: "none",
          }}
        >
          Введите данные <nobr>ДОУ/МДОУ</nobr>
        </h1>
        <div
          className="DirectorEduStep-Input"
          style={{
            display: "flex",
            width: "336px",
            maxWidth: "100%",
            margin: "0 auto",
            flexDirection: "column",
            flexWrap: "wrap",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <UIInputBlock
            type="text"
            placeholder="Город*"
            width="336px"
            height="48px"
          />
          <select
            style={{
              margin: "24px 0",
              width: "336px",
              height: "48px",
              borderRadius: "8px",
              background: "#F5F7FA",
              border: "1px solid #CCCCCC",
              padding: "8px 40px 8px 15px",
            }}
            name="edu"
            id="edu-select"
          >
            <option value="">Выберите учебное заведение*</option>
            <option value="kg260">
              МБДОУ "Детский сад № 260 г. Челябинска"
            </option>
            <option value="kg244">Детский сад № 244</option>
            <option value="kg124">Kindergarten № 124</option>
          </select>
        </div>

        <p
          style={{
            margin: "0 auto 40px",
            width: "336px",
            maxWidth: "100%",
            textAlign: "left",
          }}
        >
          <Link
            to="/registration/custom-edu"
            style={{
              textDecoration: "none",
              color: "#4FC1E9",
            }}
          >
            Не нашли свой ДОУ?
          </Link>
        </p>
        <div id="DirectorEduStep-Desktop">
          <UIBlockButton
            style={UIBlockButtonColor.Primary}
            // disabled
            text="Войти"
            margin="0"
            onClick={() => {
              setSuccessPopup(true);
            }}
          />
        </div>
        <div id="DirectorEduStep-Mobile">
          <UIBlockButton
            style={UIBlockButtonColor.Primary}
            // disabled
            text="Войти"
            margin="0"
            // onClick={() => {
            //   authStore.setIsRegistrationTrue();
            //   modalStore.SetModalInfo(
            //     "Подтвердите почту, чтобы мы могли прислать вам благодарственное письмо",
            //     "Вы успешно зарегистрировались!"
            //   );
            //   modalStore.simpleModalOpen();
            // }}
            onClick={() => {
              setSuccessPopup(true);
            }}
          />
        </div>
      </div>

      {/*</form>*/}
      <svg
        className="step_reg"
        width="70"
        height="10"
        viewBox="0 0 70 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="65" cy="5" r="5" fill="#434A54" />
        <circle cx="35" cy="5" r="5" fill="#434A54" fillOpacity="0.5" />
        <circle cx="5" cy="5" r="5" fill="#434A54" fillOpacity="0.5" />
      </svg>
      {SuccessPopup ? (
        <div
          className="popup-bg"
          onClick={(e) => {
            if (e.target !== e.currentTarget) {
              return;
            } else {
              setSuccessPopup(false);
            }
          }}
        >
          <div id="TeacherCode-Popup" className="popup-card">
            <h1>Вы успешно зарегистрировались!</h1>
            <p>
              Подтвердите почту, чтобы мы могли
              <br />
              прислать вам благодарственное письмо
            </p>
            <div id="TeacherCodePopupButton-Desktop">
              <Link to="/dashboard">
                <button
                  id="restore-popup-button-bg"
                  className="button-green"
                  onClick={() => {
                    localStorage.setItem("accountType", "director");
                  }}
                >
                  Хорошо
                </button>
              </Link>
            </div>
            <div id="TeacherCodePopupButton-Mobile">
              <Link to="/dashboard-mobile">
                <button
                  id="restore-popup-button-bg"
                  className="button-green"
                  onClick={() => {
                    localStorage.setItem("accountType", "director");
                  }}
                >
                  Хорошо
                </button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
});
