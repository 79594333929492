import React from "react";
import UIBlockButton, {
  UIBlockButtonColor,
} from "../../../../../../Components/Katelinlis/Button.tsx";
import { DashboardInput } from "./DashboardInput";
import { useState } from "react";

export const DashboardCode = ({ accountType }) => {
  const [SuccessPopup, setSuccessPopup] = useState(false);

  if (SuccessPopup) {
    window.scrollTo(0, 0);
    document.getElementsByTagName("body")[0].style =
      "height: 100vh; overflow-y: hidden;";
  } else {
    document.getElementsByTagName("body")[0].style = "";
  }

  return (
    <main
      style={{
        marginLeft: "47px",
      }}
    >
      <h1
        style={{
          fontFamily: "Open Sans",
          fontWeight: "700",
          fontSize: "24px",
          lineHeight: "120%",
          letterSpacing: "-0.01em",
          color: "#000000",
          textAlign: "left",
          margin: "0 0 24px",
        }}
      >
        Код-приглашение
      </h1>
      <DashboardInput
        placeholder="Введите код-приглашение"
        margin="40px 0"
        alignLeft
        type="text"
      />
      <div
        style={{
          margin: "40px 0 0",
          width: "100px",
        }}
      >
        <UIBlockButton
          style={UIBlockButtonColor.Primary}
          text="Ввести"
          onClick={() => {
            setSuccessPopup(true);
          }}
        />
      </div>
      {SuccessPopup ? (
        <div
          className="popup-bg"
          onClick={(e) => {
            if (e.target !== e.currentTarget) {
              return;
            } else {
              setSuccessPopup(false);
            }
          }}
        >
          <div id="AvatarDelete-Popup" className="popup-card">
            {accountType === "teacher" ? (
              <p>
                Теперь вы добавлены к вашему
                <br />
                учебному заведению
              </p>
            ) : (
              ""
            )}
            {accountType === "parent" ? (
              <p>
                Теперь ребенок добавлен к вашему
                <br />
                учебному заведению
              </p>
            ) : (
              ""
            )}
            <figure>
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28 51.3334C15.113 51.3334 4.66663 40.8871 4.66663 28.0001C4.66663 15.1131 15.113 4.66675 28 4.66675C40.887 4.66675 51.3333 15.1131 51.3333 28.0001C51.3333 40.8871 40.887 51.3334 28 51.3334ZM28 46.6667C32.9507 46.6667 37.6986 44.7001 41.1993 41.1994C44.7 37.6987 46.6666 32.9508 46.6666 28.0001C46.6666 23.0494 44.7 18.3014 41.1993 14.8008C37.6986 11.3001 32.9507 9.33341 28 9.33341C23.0493 9.33341 18.3013 11.3001 14.8006 14.8008C11.3 18.3014 9.33329 23.0494 9.33329 28.0001C9.33329 32.9508 11.3 37.6987 14.8006 41.1994C18.3013 44.7001 23.0493 46.6667 28 46.6667ZM25.6736 37.3334L15.7733 27.4331L19.0726 24.1337L25.6736 30.7347L38.871 17.5351L42.1726 20.8344L25.6736 37.3334Z"
                  fill="#00D147"
                />
              </svg>
            </figure>
          </div>
        </div>
      ) : (
        <></>
      )}
    </main>
  );
};
