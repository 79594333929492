import React, { useState } from "react";
import { observer } from "mobx-react";
import "react-select-search/style.css";
import { useHistory } from "react-router-dom";
import authStore from "../../../../../../Stores/AuthStore/AuthStore";
import { CheckStep } from "../../../../registration1";

import { DashboardAsideButton } from "./Dashboard";
import { DashboardAccount } from "./DashboardAccount";
import { DashboardCert } from "./DashboardCert";
import { DashboardTeachers } from "./DashboardTeachers";
import { DashboardSecurity } from "./DashboardSecurity";
import DashboardHeader from "./DashboardHeader";
import { DashboardCode } from "./DashboardCode";
import { DashboardResults } from "./DashboardResults";
import { DashboardGroups } from "./DashboardGroups";
import { DashboardKids } from "./DashboardKids";
import { DashboardQuiz } from "./DashboardQuiz";

export const Dashboard = observer((props) => {
  const [state, setState] = useState("account");

  // director; teacher; parent
  let accountType;
  if (localStorage.getItem("accountType") != null) {
    accountType = localStorage.getItem("accountType");
  } else {
    window.location.replace("/");
  }

  let history = useHistory();
  if (authStore.isRegistration) {
    CheckStep("teacher");
  }

  return (
    <>
      <DashboardHeader />
      <aside style={{ marginLeft: "-165px", position: "absolute" }}>
        {accountType === "parent" ? (
          <DashboardAsideButton
            insideText="Олимпиада"
            svg='<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9293 7.99988L6.66659 5.15788V10.8419L10.9293 7.99988ZM12.9173 8.27722L5.85125 12.9879C5.80106 13.0213 5.74274 13.0404 5.68251 13.0433C5.62229 13.0462 5.56241 13.0327 5.50925 13.0042C5.4561 12.9758 5.41165 12.9334 5.38066 12.8817C5.34967 12.83 5.33328 12.7708 5.33325 12.7105V3.28922C5.33328 3.22892 5.34967 3.16976 5.38066 3.11804C5.41165 3.06633 5.4561 3.02398 5.50925 2.99552C5.56241 2.96706 5.62229 2.95355 5.68251 2.95644C5.74274 2.95932 5.80106 2.97848 5.85125 3.01188L12.9173 7.72255C12.9629 7.75299 13.0003 7.79423 13.0262 7.84261C13.0521 7.89099 13.0657 7.94501 13.0657 7.99988C13.0657 8.05475 13.0521 8.10878 13.0262 8.15715C13.0003 8.20553 12.9629 8.24677 12.9173 8.27722Z" fill="#2E2E2E"/>
            </svg>                     
'
            eventKey="quiz"
            state={state}
            setState={setState}
          />
        ) : (
          ""
        )}
        <DashboardAsideButton
          insideText="Аккаунт"
          svg='<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.00016 13.6668C3.31816 13.6668 0.333496 10.6822 0.333496 7.00016C0.333496 3.31816 3.31816 0.333496 7.00016 0.333496C10.6822 0.333496 13.6668 3.31816 13.6668 7.00016C13.6668 10.6822 10.6822 13.6668 7.00016 13.6668ZM3.6755 11.1708C4.6192 11.9253 5.79195 12.3354 7.00016 12.3335C8.3135 12.3335 9.5155 11.8588 10.4448 11.0722C10.0106 10.6267 9.49153 10.2727 8.91819 10.0314C8.34484 9.78998 7.72891 9.66603 7.10683 9.66683C6.46189 9.66609 5.82383 9.7994 5.23314 10.0583C4.64245 10.3172 4.11202 10.6961 3.6755 11.1708V11.1708ZM2.74416 10.2135C3.30447 9.61888 3.98061 9.14531 4.73092 8.82197C5.48123 8.49864 6.28982 8.3324 7.10683 8.3335C7.8946 8.33247 8.67481 8.48707 9.40266 8.78842C10.1305 9.08976 10.7917 9.53192 11.3482 10.0895C11.9189 9.28625 12.2559 8.34059 12.3219 7.35744C12.3878 6.37429 12.1801 5.39209 11.7218 4.5198C11.2635 3.64751 10.5725 2.91924 9.72547 2.41579C8.87844 1.91234 7.90849 1.65339 6.92323 1.66766C5.93798 1.68194 4.97594 1.96889 4.14384 2.49667C3.31175 3.02445 2.64214 3.77243 2.20929 4.65763C1.77645 5.54283 1.59729 6.53064 1.69171 7.51146C1.78612 8.49229 2.15041 9.42778 2.74416 10.2142V10.2135ZM7.00016 7.66683C6.29292 7.66683 5.61464 7.38588 5.11454 6.88578C4.61445 6.38568 4.3335 5.70741 4.3335 5.00016C4.3335 4.29292 4.61445 3.61464 5.11454 3.11454C5.61464 2.61445 6.29292 2.3335 7.00016 2.3335C7.70741 2.3335 8.38568 2.61445 8.88578 3.11454C9.38588 3.61464 9.66683 4.29292 9.66683 5.00016C9.66683 5.70741 9.38588 6.38568 8.88578 6.88578C8.38568 7.38588 7.70741 7.66683 7.00016 7.66683ZM7.00016 6.3335C7.35378 6.3335 7.69292 6.19302 7.94297 5.94297C8.19302 5.69292 8.3335 5.35378 8.3335 5.00016C8.3335 4.64654 8.19302 4.3074 7.94297 4.05735C7.69292 3.80731 7.35378 3.66683 7.00016 3.66683C6.64654 3.66683 6.3074 3.80731 6.05735 4.05735C5.80731 4.3074 5.66683 4.64654 5.66683 5.00016C5.66683 5.35378 5.80731 5.69292 6.05735 5.94297C6.3074 6.19302 6.64654 6.3335 7.00016 6.3335Z" fill="#2E2E2E"/>
</svg>
'
          eventKey="account"
          state={state}
          setState={setState}
        />
        {accountType === "teacher" ? (
          <DashboardAsideButton
            insideText="Группы"
            svg='<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.33341 9.50134V10.8947C8.73003 10.6813 8.08426 10.6159 7.45035 10.7039C6.81644 10.7918 6.21288 11.0306 5.69037 11.4001C5.16786 11.7697 4.74164 12.2592 4.44752 12.8276C4.1534 13.396 3.99996 14.0267 4.00008 14.6667L2.66675 14.666C2.66654 13.8519 2.8527 13.0486 3.21095 12.3176C3.5692 11.5866 4.09004 10.9473 4.73356 10.4487C5.37708 9.95009 6.1262 9.60542 6.92352 9.4411C7.72083 9.27677 8.54519 9.29715 9.33341 9.50067V9.50134ZM8.00008 8.66667C5.79008 8.66667 4.00008 6.87667 4.00008 4.66667C4.00008 2.45667 5.79008 0.666668 8.00008 0.666668C10.2101 0.666668 12.0001 2.45667 12.0001 4.66667C12.0001 6.87667 10.2101 8.66667 8.00008 8.66667ZM8.00008 7.33333C9.47341 7.33333 10.6667 6.14 10.6667 4.66667C10.6667 3.19333 9.47341 2 8.00008 2C6.52675 2 5.33341 3.19333 5.33341 4.66667C5.33341 6.14 6.52675 7.33333 8.00008 7.33333ZM12.0001 11.3333V9.33333H13.3334V11.3333H15.3334V12.6667H13.3334V14.6667H12.0001V12.6667H10.0001V11.3333H12.0001Z" fill="#2E2E2E"/>
            </svg>            
'
            eventKey="groups"
            state={state}
            setState={setState}
          />
        ) : (
          ""
        )}
        {accountType === "parent" ? (
          <DashboardAsideButton
            insideText="Дети"
            svg='<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.33341 9.50117V10.8945C8.73003 10.6812 8.08426 10.6157 7.45035 10.7037C6.81644 10.7917 6.21288 11.0304 5.69037 11.4C5.16786 11.7695 4.74164 12.259 4.44752 12.8274C4.1534 13.3958 3.99996 14.0265 4.00008 14.6665L2.66675 14.6658C2.66654 13.8518 2.8527 13.0484 3.21095 12.3174C3.5692 11.5864 4.09004 10.9471 4.73356 10.4485C5.37708 9.94993 6.1262 9.60526 6.92352 9.44093C7.72083 9.27661 8.54519 9.29699 9.33341 9.5005V9.50117ZM8.00008 8.6665C5.79008 8.6665 4.00008 6.8765 4.00008 4.6665C4.00008 2.4565 5.79008 0.666504 8.00008 0.666504C10.2101 0.666504 12.0001 2.4565 12.0001 4.6665C12.0001 6.8765 10.2101 8.6665 8.00008 8.6665ZM8.00008 7.33317C9.47341 7.33317 10.6667 6.13984 10.6667 4.6665C10.6667 3.19317 9.47341 1.99984 8.00008 1.99984C6.52675 1.99984 5.33341 3.19317 5.33341 4.6665C5.33341 6.13984 6.52675 7.33317 8.00008 7.33317ZM12.0001 11.3332V9.33317H13.3334V11.3332H15.3334V12.6665H13.3334V14.6665H12.0001V12.6665H10.0001V11.3332H12.0001Z" fill="#2E2E2E"/>
            </svg>                   
'
            eventKey="kids"
            state={state}
            setState={setState}
          />
        ) : (
          ""
        )}
        {accountType === "director" ? (
          <DashboardAsideButton
            insideText="Педагоги"
            svg='<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.33317 9.50117V10.8945C6.72978 10.6812 6.08402 10.6157 5.45011 10.7037C4.81619 10.7917 4.21264 11.0304 3.69012 11.4C3.16761 11.7695 2.7414 12.259 2.44728 12.8274C2.15316 13.3958 1.99972 14.0265 1.99984 14.6665L0.666504 14.6658C0.666297 13.8518 0.852451 13.0484 1.2107 12.3174C1.56895 11.5864 2.0898 10.9471 2.73332 10.4485C3.37684 9.94993 4.12595 9.60526 4.92327 9.44093C5.72059 9.27661 6.54494 9.29699 7.33317 9.5005V9.50117ZM5.99984 8.6665C3.78984 8.6665 1.99984 6.8765 1.99984 4.6665C1.99984 2.4565 3.78984 0.666504 5.99984 0.666504C8.20984 0.666504 9.99984 2.4565 9.99984 4.6665C9.99984 6.8765 8.20984 8.6665 5.99984 8.6665ZM5.99984 7.33317C7.47317 7.33317 8.6665 6.13984 8.6665 4.6665C8.6665 3.19317 7.47317 1.99984 5.99984 1.99984C4.5265 1.99984 3.33317 3.19317 3.33317 4.6665C3.33317 6.13984 4.5265 7.33317 5.99984 7.33317ZM9.99984 11.3332V9.33317H11.3332V11.3332H13.3332V12.6665H11.3332V14.6665H9.99984V12.6665H7.99984V11.3332H9.99984Z" fill="#2E2E2E"/>
          </svg>
'
            margin="3px 0"
            eventKey="teachers"
            state={state}
            setState={setState}
          />
        ) : (
          ""
        )}
        <DashboardAsideButton
          insideText="Защита"
          svg='<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.522 1.88402L8 0.666687L13.478 1.88402C13.626 1.91693 13.7584 1.99933 13.8533 2.11762C13.9482 2.23592 14 2.38303 14 2.53469V9.19269C14 9.85118 13.8373 10.4995 13.5266 11.08C13.2158 11.6606 12.7666 12.1554 12.2187 12.5207L8 15.3334L3.78133 12.5207C3.23352 12.1555 2.78431 11.6608 2.47357 11.0803C2.16282 10.4999 2.00016 9.85173 2 9.19335V2.53469C2.00003 2.38303 2.05176 2.23592 2.14666 2.11762C2.24156 1.99933 2.37396 1.91693 2.522 1.88402ZM3.33333 3.06935V9.19269C3.33334 9.63166 3.44172 10.0638 3.64884 10.4509C3.85597 10.8379 4.15543 11.1678 4.52067 11.4114L8 13.7314L11.4793 11.4114C11.8445 11.1679 12.1439 10.8381 12.351 10.4512C12.5581 10.0643 12.6665 9.63221 12.6667 9.19335V3.06935L8 2.03335L3.33333 3.06935ZM8.66667 6.66669H10.6667L7.33333 11.3334V8.00002H5.33333L8.66667 3.33335V6.66669Z" fill="#2E2E2E"/>
          </svg>          
'
          eventKey="security"
          state={state}
          setState={setState}
        />
        {accountType === "parent" || accountType === "teacher" ? (
          <DashboardAsideButton
            insideText="Отчеты"
            svg='<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99992 14.6668C4.31792 14.6668 1.33325 11.6822 1.33325 8.00016C1.33325 5.01483 3.29525 2.48816 5.99992 1.63883V3.05483C4.85387 3.52019 3.90512 4.3693 3.31596 5.45691C2.7268 6.54451 2.53384 7.80303 2.77009 9.01719C3.00634 10.2314 3.65711 11.3257 4.6111 12.113C5.56508 12.9004 6.76299 13.3318 7.99992 13.3335C9.06244 13.3335 10.1008 13.0162 10.9818 12.4223C11.8629 11.8285 12.5466 10.985 12.9453 10.0002H14.3613C13.5119 12.7048 10.9853 14.6668 7.99992 14.6668ZM14.6333 8.66683H7.33325V1.36683C7.55259 1.34483 7.77525 1.3335 7.99992 1.3335C11.6819 1.3335 14.6666 4.31816 14.6666 8.00016C14.6666 8.22483 14.6553 8.4475 14.6333 8.66683ZM8.66659 2.70816V7.3335H13.2919C13.1435 6.15857 12.6085 5.06639 11.7711 4.22899C10.9337 3.39159 9.84151 2.85655 8.66659 2.70816Z" fill="#2E2E2E"/>
            </svg>           
'
            eventKey="results"
            state={state}
            setState={setState}
          />
        ) : (
          ""
        )}
        {accountType === "parent" || accountType === "teacher" ? (
          <DashboardAsideButton
            insideText="Ввести код"
            svg='<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.66659 3.33333V12.6667H13.3333V3.33333H2.66659ZM1.99992 2H13.9999C14.1767 2 14.3463 2.07024 14.4713 2.19526C14.5963 2.32029 14.6666 2.48986 14.6666 2.66667V13.3333C14.6666 13.5101 14.5963 13.6797 14.4713 13.8047C14.3463 13.9298 14.1767 14 13.9999 14H1.99992C1.82311 14 1.65354 13.9298 1.52851 13.8047C1.40349 13.6797 1.33325 13.5101 1.33325 13.3333V2.66667C1.33325 2.48986 1.40349 2.32029 1.52851 2.19526C1.65354 2.07024 1.82311 2 1.99992 2ZM3.99992 4.66667H5.99992V11.3333H3.99992V4.66667ZM6.66659 4.66667H7.99992V11.3333H6.66659V4.66667ZM8.66659 4.66667H9.33325V11.3333H8.66659V4.66667ZM9.99992 4.66667H11.9999V11.3333H9.99992V4.66667Z" fill="#2E2E2E"/>
            </svg>                       
'
            eventKey="code"
            state={state}
            setState={setState}
          />
        ) : (
          ""
        )}
        {accountType === "director" ? (
          <DashboardAsideButton
            insideText="Сертификаты"
            svg='<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.6668 13.6668H2.3335C1.80306 13.6668 1.29436 13.4561 0.919283 13.081C0.54421 12.706 0.333496 12.1973 0.333496 11.6668V1.00016C0.333496 0.823352 0.403734 0.653782 0.528758 0.528758C0.653782 0.403734 0.823352 0.333496 1.00016 0.333496H10.3335C10.5103 0.333496 10.6799 0.403734 10.8049 0.528758C10.9299 0.653782 11.0002 0.823352 11.0002 1.00016V9.00016H13.6668V11.6668C13.6668 12.1973 13.4561 12.706 13.081 13.081C12.706 13.4561 12.1973 13.6668 11.6668 13.6668ZM11.0002 10.3335V11.6668C11.0002 11.8436 11.0704 12.0132 11.1954 12.1382C11.3204 12.2633 11.49 12.3335 11.6668 12.3335C11.8436 12.3335 12.0132 12.2633 12.1382 12.1382C12.2633 12.0132 12.3335 11.8436 12.3335 11.6668V10.3335H11.0002ZM9.66683 12.3335V1.66683H1.66683V11.6668C1.66683 11.8436 1.73707 12.0132 1.86209 12.1382C1.98712 12.2633 2.15669 12.3335 2.3335 12.3335H9.66683ZM3.00016 3.66683H8.3335V5.00016H3.00016V3.66683ZM3.00016 6.3335H8.3335V7.66683H3.00016V6.3335ZM3.00016 9.00016H6.3335V10.3335H3.00016V9.00016Z" fill="#2E2E2E"/>
          </svg>
'
            margin="3px 0 0 0"
            eventKey="cert"
            state={state}
            setState={setState}
          />
        ) : (
          ""
        )}
      </aside>
      {state === "account" ? (
        <DashboardAccount accountType={accountType} />
      ) : (
        ""
      )}
      {state === "teachers" ? (
        <DashboardTeachers accountType={accountType} />
      ) : (
        ""
      )}
      {state === "security" ? (
        <DashboardSecurity accountType={accountType} />
      ) : (
        ""
      )}
      {state === "cert" ? <DashboardCert accountType={accountType} /> : ""}
      {state === "code" ? <DashboardCode accountType={accountType} /> : ""}
      {state === "groups" ? <DashboardGroups accountType={accountType} /> : ""}
      {state === "kids" ? <DashboardKids accountType={accountType} /> : ""}
      {state === "quiz" ? <DashboardQuiz accountType={accountType} /> : ""}
      {state === "results" ? (
        <DashboardResults accountType={accountType} />
      ) : (
        ""
      )}
    </>
  );
});
