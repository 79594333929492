import React from "react";
import UIBlockButton, {
  UIBlockButtonColor,
} from "../../../../../../Components/Katelinlis/Button.tsx";

export const DashboardCert = () => {
  return (
    <main
      style={{
        marginLeft: "47px",
      }}
    >
      <h1
        style={{
          fontFamily: "Open Sans",
          fontWeight: "700",
          fontSize: "24px",
          lineHeight: "120%",
          letterSpacing: "-0.01em",
          color: "#000000",
          textAlign: "left",
          margin: "0 0 24px",
        }}
      >
        Сертификаты
      </h1>
      <p
        style={{
          margin: "0",
          textAlign: "left",
        }}
      >
        Все сертификаты скачаются одним архивом
      </p>
      <div
        style={{
          margin: "48px 0 0",
          width: "100px",
        }}
      >
        <UIBlockButton style={UIBlockButtonColor.Primary} text="Скачать" />
      </div>
    </main>
  );
};
