import React, { useState } from "react";
import { observer } from "mobx-react";
import "react-select-search/style.css";
import { DashboardMobileHeader } from "./DashboardMobileHeader";
import "./DashboardMobile.css";
import UIBlockButton, {
  UIBlockButtonColor,
} from "../../../../../../Components/Katelinlis/Button.tsx";
import { UIInputBlock } from "../UIInputBlock";

export const DashboardMobileCode = observer((props) => {
  let accountType;

  if (localStorage.getItem("accountType") != null) {
    accountType = localStorage.getItem("accountType");
  } else {
    window.location.replace("/");
  }
  return (
    <>
      <DashboardMobileHeader accountType={accountType} />
      <div className="DashboardMobile-Content">
        <div className="DashboardMobile-Security">
          <h1 className="DashboardMobile-h1">Код-приглашение</h1>
          <UIInputBlock
            type="text"
            placeholder="Введите код-приглашение"
            width="100%"
            height="13.15vw"
            margin="0 0 6.57vw"
          />
          <UIBlockButton style={UIBlockButtonColor.Primary} text="Ввести" />
        </div>
      </div>
    </>
  );
});
