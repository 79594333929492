import React, { useState } from "react";
import { observer } from "mobx-react";
import "react-select-search/style.css";
import { DashboardMobileHeader } from "./DashboardMobileHeader";
import "./DashboardMobile.css";
import UIBlockButton, {
  UIBlockButtonColor,
} from "../../../../../../Components/Katelinlis/Button.tsx";
import { UIInputBlock } from "../UIInputBlock";
import { Link } from "react-router-dom";
import { DashboardAsideButtonMobile } from "./DashboardMobile";
import { AvatarCrop } from "../../../../../../Components/AvatarCrop/AvavarCropUI/AvatarCrop";

export const DashboardMobileAccount = observer((props) => {
  const AvatarSrc = "https://klike.net/uploads/posts/2019-07/1564314059_1.jpg";
  let accountType;

  if (localStorage.getItem("accountType") != null) {
    accountType = localStorage.getItem("accountType");
  } else {
    window.location.replace("/");
  }
  let Name = "Мария";
  let Surname = "Матвиенко";
  let Patronymic = "Игоревна";
  let Tel = "+7 (800) 555-35-35";
  let Mail = "admin@school.ru";
  const MailStatus = true;
  const City = "Челябинск";
  const Edu = "Название";
  const [showAvatarMenu, setShowAvatarMenu] = useState(false);
  const [editAccount, setEditAccount] = useState(false);
  const [sendMailPopup, setSendMailPopup] = useState(false);
  if (showAvatarMenu || sendMailPopup) {
    window.scrollTo(0, 0);
    document.getElementsByTagName("body")[0].style =
      "height: 100vh; overflow-y: hidden;";
  } else {
    document.getElementsByTagName("body")[0].style = "";
  }
  return (
    <>
      <DashboardMobileHeader accountType={accountType} />
      <div className="DashboardMobile-Content">
        <div className="DashboardMobile-Security DashboardMobile-Account">
          <button
            className="DashboardMobile-AvatarButton"
            onClick={() => {
              setShowAvatarMenu(!showAvatarMenu);
            }}
          >
            <img alt="Avatar" src={AvatarSrc}></img>
          </button>

          {showAvatarMenu ? (
            <div
              id="DashboardMobile-MenuContainer"
              onClick={(e) => {
                if (e.target !== e.currentTarget) {
                  return;
                } else {
                  setShowAvatarMenu(false);
                }
              }}
            >
              <div id="DashboardMobile-MenuCard">
                <figure>
                  <svg
                    width="40"
                    height="3"
                    viewBox="0 0 40 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="3" fill="#D9D9D9" />
                  </svg>
                </figure>
                <div className="DashboardAsideButtonsMobile">
                  <a target="__blank" href={AvatarSrc}>
                    <DashboardAsideButtonMobile
                      insideText="Открыть фото"
                      svg='<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.00016 14.6668C4.31816 14.6668 1.3335 11.6822 1.3335 8.00016C1.3335 4.31816 4.31816 1.3335 8.00016 1.3335C11.6822 1.3335 14.6668 4.31816 14.6668 8.00016C14.6668 11.6822 11.6822 14.6668 8.00016 14.6668ZM4.6755 12.1708C5.61919 12.9253 6.79195 13.3354 8.00016 13.3335C9.3135 13.3335 10.5155 12.8588 11.4448 12.0722C11.0106 11.6267 10.4915 11.2727 9.91819 11.0314C9.34484 10.79 8.72891 10.666 8.10683 10.6668C7.46189 10.6661 6.82383 10.7994 6.23314 11.0583C5.64245 11.3172 5.11202 11.6961 4.6755 12.1708ZM3.74416 11.2135C4.30447 10.6189 4.98061 10.1453 5.73092 9.82197C6.48123 9.49864 7.28982 9.3324 8.10683 9.3335C8.8946 9.33247 9.67481 9.48707 10.4027 9.78842C11.1305 10.0898 11.7917 10.5319 12.3482 11.0895C12.9189 10.2862 13.2559 9.34059 13.3219 8.35744C13.3878 7.37429 13.1801 6.39209 12.7218 5.5198C12.2635 4.64751 11.5725 3.91924 10.7255 3.41579C9.87844 2.91234 8.90849 2.65339 7.92323 2.66766C6.93798 2.68194 5.97594 2.96889 5.14384 3.49667C4.31175 4.02445 3.64214 4.77243 3.20929 5.65763C2.77645 6.54283 2.59729 7.53064 2.69171 8.51146C2.78612 9.49229 3.15041 10.4278 3.74416 11.2142V11.2135ZM8.00016 8.66683C7.29292 8.66683 6.61464 8.38588 6.11454 7.88578C5.61445 7.38568 5.3335 6.70741 5.3335 6.00016C5.3335 5.29292 5.61445 4.61464 6.11454 4.11454C6.61464 3.61445 7.29292 3.3335 8.00016 3.3335C8.70741 3.3335 9.38568 3.61445 9.88578 4.11454C10.3859 4.61464 10.6668 5.29292 10.6668 6.00016C10.6668 6.70741 10.3859 7.38568 9.88578 7.88578C9.38568 8.38588 8.70741 8.66683 8.00016 8.66683ZM8.00016 7.3335C8.35379 7.3335 8.69292 7.19302 8.94297 6.94297C9.19302 6.69292 9.3335 6.35379 9.3335 6.00016C9.3335 5.64654 9.19302 5.3074 8.94297 5.05735C8.69292 4.80731 8.35379 4.66683 8.00016 4.66683C7.64654 4.66683 7.3074 4.80731 7.05735 5.05735C6.80731 5.3074 6.66683 5.64654 6.66683 6.00016C6.66683 6.35379 6.80731 6.69292 7.05735 6.94297C7.3074 7.19302 7.64654 7.3335 8.00016 7.3335Z" fill="#2E2E2E"/>
                      </svg>                    
'
                      margin="3px 0"
                      eventKey="dashboard-mobile/open-avatar"
                    />
                  </a>
                  <DashboardAsideButtonMobile
                    insideText="Изменить фото"
                    svg='<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.33333 12.6667H4.276L10.4853 6.45739L9.54267 5.51472L3.33333 11.7241V12.6667ZM14 14.0001H2V11.1714L10.9567 2.21472C11.0817 2.08974 11.2512 2.01953 11.428 2.01953C11.6048 2.01953 11.7743 2.08974 11.8993 2.21472L13.7853 4.10072C13.9103 4.22574 13.9805 4.39528 13.9805 4.57206C13.9805 4.74883 13.9103 4.91837 13.7853 5.04339L6.162 12.6667H14V14.0001ZM10.4853 4.57206L11.428 5.51472L12.3707 4.57206L11.428 3.62939L10.4853 4.57206Z" fill="#92A0B4"/>
                    </svg>                                        
'
                    margin="3px 0"
                    eventKey="dashboard-mobile/change-avatar"
                  />
                  <DashboardAsideButtonMobile
                    insideText="Удалить фото"
                    svg='<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.33341 9.50117V10.8945C8.73003 10.6812 8.08426 10.6157 7.45035 10.7037C6.81644 10.7917 6.21288 11.0304 5.69037 11.4C5.16786 11.7695 4.74164 12.259 4.44752 12.8274C4.1534 13.3958 3.99996 14.0265 4.00008 14.6665L2.66675 14.6658C2.66654 13.8518 2.8527 13.0484 3.21095 12.3174C3.5692 11.5864 4.09004 10.9471 4.73356 10.4485C5.37708 9.94993 6.1262 9.60526 6.92352 9.44093C7.72083 9.27661 8.54519 9.29699 9.33341 9.5005V9.50117ZM8.00008 8.6665C5.79008 8.6665 4.00008 6.8765 4.00008 4.6665C4.00008 2.4565 5.79008 0.666504 8.00008 0.666504C10.2101 0.666504 12.0001 2.4565 12.0001 4.6665C12.0001 6.8765 10.2101 8.6665 8.00008 8.6665ZM8.00008 7.33317C9.47341 7.33317 10.6667 6.13984 10.6667 4.6665C10.6667 3.19317 9.47341 1.99984 8.00008 1.99984C6.52675 1.99984 5.33341 3.19317 5.33341 4.6665C5.33341 6.13984 6.52675 7.33317 8.00008 7.33317ZM12.0001 11.3332V9.33317H13.3334V11.3332H15.3334V12.6665H13.3334V14.6665H12.0001V12.6665H10.0001V11.3332H12.0001Z" fill="#92A0B4"/>
                    </svg>                                    
'
                    margin="3px 0"
                    eventKey="dashboard-mobile/delete-avatar"
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {sendMailPopup ? (
            <div
              className="MobileTeachers-PopupBG"
              onClick={(e) => {
                if (e) {
                  if (e.target !== e.currentTarget) {
                    return;
                  } else {
                    setSendMailPopup(false);
                  }
                }
              }}
            >
              <div className="MobileTeachers-inviteCard">
                <h3>Проверьте почту</h3>
                <p>
                  Перейдите по ссылке,
                  <br /> чтобы подтвердить почту
                </p>
                <UIBlockButton
                  style={UIBlockButtonColor.Primary}
                  text="Отправить "
                />
              </div>
            </div>
          ) : (
            <></>
          )}

          <h1 className="DashboardMobile-h1">
            Аккаунт {accountType === "director" ? "директора" : ""}
            {accountType === "teacher" ? "педагога" : ""}
            {accountType === "parent" ? "родителя" : ""}
          </h1>
          <h2>Основная информация</h2>
          <span>Имя</span>
          <UIInputBlock
            type="text"
            placeholder={Name}
            width="100%"
            height="13.15vw"
            disabled={editAccount ? "" : "disabled"}
          />
          <span>Фамилия</span>
          <UIInputBlock
            type="text"
            placeholder={Surname}
            width="100%"
            height="13.15vw"
            disabled={editAccount ? "" : "disabled"}
          />
          <span>Отчество</span>
          <UIInputBlock
            type="text"
            placeholder={Patronymic}
            width="100%"
            height="13.15vw"
            disabled={editAccount ? "" : "disabled"}
          />
          <h2>Контактная информация</h2>
          <span>Телефон</span>
          <UIInputBlock
            type="text"
            placeholder={Tel}
            width="100%"
            height="13.15vw"
            disabled={editAccount ? "" : "disabled"}
          />
          <span>Почта</span>
          <UIInputBlock
            type="text"
            placeholder={Mail}
            width="100%"
            height="13.15vw"
            disabled={editAccount ? "" : "disabled"}
            correct={MailStatus ? "true" : ""}
            error={MailStatus ? "" : "true"}
          />
          {MailStatus ? (
            <span className="span-success">Почта подтверждена</span>
          ) : (
            <div>
              <span className="span-error">Подтвердите почту или нажмите </span>
              <button
                id="sendMail-span"
                onClick={() => {
                  setSendMailPopup(true);
                }}
              >
                выслать письмо
              </button>
            </div>
          )}

          {accountType === "director" ? (
            <>
              <h2>Информация о детском саде</h2>
              <span>Город</span>
              <UIInputBlock
                type="text"
                placeholder={City}
                width="100%"
                height="13.15vw"
                disabled={editAccount ? "" : "disabled"}
              />
              <span>Название ДОУ/СОШ</span>
              <UIInputBlock
                type="text"
                placeholder={Edu}
                width="100%"
                height="13.15vw"
                disabled={editAccount ? "" : "disabled"}
              />
            </>
          ) : (
            <></>
          )}

          {editAccount ? (
            <UIBlockButton
              style={UIBlockButtonColor.Second}
              text="Сохранить"
              onClick={() => {
                setEditAccount(false);
              }}
            />
          ) : (
            <UIBlockButton
              style={UIBlockButtonColor.Primary}
              text="Редактировать"
              onClick={() => {
                setEditAccount(true);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
});
