import {observer} from "mobx-react";
import authStore from "../../Stores/AuthStore/AuthStore";
import React from "react";
import '../../styles/spiner.css';
import {Link, useHistory} from "react-router-dom";

export const LeftHeader = observer(() => {
    let history = useHistory();

    if(authStore.userData === undefined){
        history.push('/')
        return null;
    }

    if(authStore?.userData?.UserType === null){
        history.push('/')
        return null;
    }

    return <>

        <div className="left_header pt_header">
            <Link style={{textDecoration: 'none'}} to="/account">
                <p className="elem_left_menu" style={window.location.href.indexOf('/account') !== -1 && window.location.href.indexOf('/account/') === -1? {background: '#4FC1E9'} : {}}>
                    АККАУНТ
                    <svg className="svg_elem_left_menu" style={{margin: 'auto 0'}} width="12" height="12" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.71451 0.75099C1.08765 0.110796 0 0.554625 0 1.45061V20.622C0 21.5078 1.06646 21.9562 1.69946 21.3367L11.2852 11.9549C11.6799 11.5686 11.6867 10.9353 11.3002 10.5406L1.71451 0.75099Z" fill="black"/>
                    </svg>
                </p>
            </Link>
            {authStore.userData.UserType === "Parent"?
                <>
                    <Link style={{textDecoration: 'none'}} to="/children">
                        <p className="elem_left_menu" style={window.location.href.indexOf('/children') !== -1 ? {background: '#4FC1E9'} : {}}>
                            ДЕТИ
                            <svg className="svg_elem_left_menu" style={{margin: 'auto 0'}} width="12" height="12" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.71451 0.75099C1.08765 0.110796 0 0.554625 0 1.45061V20.622C0 21.5078 1.06646 21.9562 1.69946 21.3367L11.2852 11.9549C11.6799 11.5686 11.6867 10.9353 11.3002 10.5406L1.71451 0.75099Z" fill="black"/>
                            </svg>
                        </p>
                    </Link>
                    <Link style={{textDecoration: 'none'}} to="/account/security">
                        <p className="elem_left_menu" style={window.location.href.indexOf('/account/security') !== -1 ? {background: '#4FC1E9'} : {}}>
                            ЗАЩИТА
                            <svg className="svg_elem_left_menu" style={{margin: 'auto 0'}} width="12" height="12" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.71451 0.75099C1.08765 0.110796 0 0.554625 0 1.45061V20.622C0 21.5078 1.06646 21.9562 1.69946 21.3367L11.2852 11.9549C11.6799 11.5686 11.6867 10.9353 11.3002 10.5406L1.71451 0.75099Z" fill="black"/>
                            </svg>
                        </p>
                    </Link>
                    <Link style={{textDecoration: 'none'}} to="/account/notification">
                        <p className="elem_left_menu" style={window.location.href.indexOf('/account/notification') !== -1 ? {background: '#4FC1E9'} : {}}>
                            УВЕДОМЛЕНИЯ
                            <svg className="svg_elem_left_menu" style={{margin: 'auto 0'}} width="12" height="12" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.71451 0.75099C1.08765 0.110796 0 0.554625 0 1.45061V20.622C0 21.5078 1.06646 21.9562 1.69946 21.3367L11.2852 11.9549C11.6799 11.5686 11.6867 10.9353 11.3002 10.5406L1.71451 0.75099Z" fill="black"/>
                            </svg>
                        </p>
                    </Link>
                    <Link style={{textDecoration: 'none'}} to="/report">
                        <p className="elem_left_menu" style={window.location.href.indexOf('/report') !== -1 ? {background: '#4FC1E9'} : {}}>
                            ОТЧЕТЫ
                            <svg className="svg_elem_left_menu" style={{margin: 'auto 0'}} width="12" height="12" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.71451 0.75099C1.08765 0.110796 0 0.554625 0 1.45061V20.622C0 21.5078 1.06646 21.9562 1.69946 21.3367L11.2852 11.9549C11.6799 11.5686 11.6867 10.9353 11.3002 10.5406L1.71451 0.75099Z" fill="black"/>
                            </svg>
                        </p>
                    </Link>
                </>
                :<></>}

            {authStore.userData.UserType === "Children"?
                <>
                    <Link style={{textDecoration: 'none'}} to="/game">
                        <p className="elem_left_menu" style={window.location.href.indexOf('/game') !== -1 ? {background: '#4FC1E9'} : {}}>
                            ИГРЫ
                            <svg className="svg_elem_left_menu" style={{margin: 'auto 0'}} width="12" height="12" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.71451 0.75099C1.08765 0.110796 0 0.554625 0 1.45061V20.622C0 21.5078 1.06646 21.9562 1.69946 21.3367L11.2852 11.9549C11.6799 11.5686 11.6867 10.9353 11.3002 10.5406L1.71451 0.75099Z" fill="black"/>
                            </svg>
                        </p>
                    </Link>
                </>
                :<></>}

            {authStore.userData.UserType === "Pedagog"?
                <>
                    <Link style={{textDecoration: 'none'}} to="/account/security">
                        <p className="elem_left_menu" style={window.location.href.indexOf('/account/security') !== -1 ? {background: '#4FC1E9'} : {}}>
                            ЗАЩИТА
                            <svg className="svg_elem_left_menu" style={{margin: 'auto 0'}} width="12" height="12" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.71451 0.75099C1.08765 0.110796 0 0.554625 0 1.45061V20.622C0 21.5078 1.06646 21.9562 1.69946 21.3367L11.2852 11.9549C11.6799 11.5686 11.6867 10.9353 11.3002 10.5406L1.71451 0.75099Z" fill="black"/>
                            </svg>
                        </p>
                    </Link>
                    <Link style={{textDecoration: 'none'}} to="/account/notification">
                        <p className="elem_left_menu" style={window.location.href.indexOf('/account/notification') !== -1 ? {background: '#4FC1E9'} : {}}>
                            УВЕДОМЛЕНИЯ
                            <svg className="svg_elem_left_menu" style={{margin: 'auto 0'}} width="12" height="12" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.71451 0.75099C1.08765 0.110796 0 0.554625 0 1.45061V20.622C0 21.5078 1.06646 21.9562 1.69946 21.3367L11.2852 11.9549C11.6799 11.5686 11.6867 10.9353 11.3002 10.5406L1.71451 0.75099Z" fill="black"/>
                            </svg>
                        </p>
                    </Link>
                    {authStore.userData.CreatedGartenId !== 0?
                    <Link style={{textDecoration: 'none'}} to="/kindergarten">
                        <p className="elem_left_menu" style={window.location.href.indexOf('/kindergarten') !== -1 ? {background: '#4FC1E9'} : {}}>
                            ДЕТСКИЙ САД
                            <svg className="svg_elem_left_menu" style={{margin: 'auto 0'}} width="12" height="12" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.71451 0.75099C1.08765 0.110796 0 0.554625 0 1.45061V20.622C0 21.5078 1.06646 21.9562 1.69946 21.3367L11.2852 11.9549C11.6799 11.5686 11.6867 10.9353 11.3002 10.5406L1.71451 0.75099Z" fill="black"/>
                            </svg>
                        </p>
                    </Link> : <></>}
                    {authStore.userData.Position.IsEditable && authStore.userData.CreatedGartenId == 0?
                      <Link style={{textDecoration: 'none'}} to="/registration/kindegarten">
                        <p className="elem_left_menu" style={window.location.href.indexOf('/kindergarten') !== -1 ? {background: '#4FC1E9'} : {}}>
                            СОЗДАТЬ ДЕТ.САД
                            <svg className="svg_elem_left_menu" style={{margin: 'auto 0'}} width="12" height="12" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.71451 0.75099C1.08765 0.110796 0 0.554625 0 1.45061V20.622C0 21.5078 1.06646 21.9562 1.69946 21.3367L11.2852 11.9549C11.6799 11.5686 11.6867 10.9353 11.3002 10.5406L1.71451 0.75099Z" fill="black"/>
                            </svg>
                        </p>
                      </Link>: <></>
                    }
                    {!authStore.userData.Position.IsEditable?
                    <Link style={{textDecoration: 'none'}} to="/groups">
                        <p className="elem_left_menu" style={window.location.href.indexOf('/groups') !== -1 ? {background: '#4FC1E9'} : {}}>
                            ГРУППЫ
                            <svg className="svg_elem_left_menu" style={{margin: 'auto 0'}} width="12" height="12" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.71451 0.75099C1.08765 0.110796 0 0.554625 0 1.45061V20.622C0 21.5078 1.06646 21.9562 1.69946 21.3367L11.2852 11.9549C11.6799 11.5686 11.6867 10.9353 11.3002 10.5406L1.71451 0.75099Z" fill="black"/>
                            </svg>
                        </p>
                    </Link> : <></>}
                    <Link style={{textDecoration: 'none'}} to="/account/teacher/invite">
                        <p className="elem_left_menu" style={window.location.href.indexOf('/account/teacher/invite') !== -1 ? {background: '#4FC1E9', textIndent: 0} : {textIndent: 0}}>
                            <span style={{paddingLeft: "8px"}}>КОД-ПРИГЛАШЕНИЕ</span>
                            <svg className="svg_elem_left_menu" style={{margin: 'auto 0'}} width="12" height="12" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.71451 0.75099C1.08765 0.110796 0 0.554625 0 1.45061V20.622C0 21.5078 1.06646 21.9562 1.69946 21.3367L11.2852 11.9549C11.6799 11.5686 11.6867 10.9353 11.3002 10.5406L1.71451 0.75099Z" fill="black"/>
                            </svg>
                        </p>
                    </Link>
                    <Link style={{textDecoration: 'none'}} to="/report">
                        <p className="elem_left_menu" style={window.location.href.indexOf('/report') !== -1 ? {background: '#4FC1E9', textIndent: 0} : {textIndent: 0}}>
                            <span style={{paddingLeft: "8px"}}>Отчёты</span>
                            <svg className="svg_elem_left_menu" style={{margin: 'auto 0'}} width="12" height="12" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.71451 0.75099C1.08765 0.110796 0 0.554625 0 1.45061V20.622C0 21.5078 1.06646 21.9562 1.69946 21.3367L11.2852 11.9549C11.6799 11.5686 11.6867 10.9353 11.3002 10.5406L1.71451 0.75099Z" fill="black"/>
                            </svg>
                        </p>
                    </Link>
                </>
                :<></>}
        </div>

    </>
})
