import {observer} from "mobx-react";
import {childrenStore} from "../../../Stores/Children/Children";
import React from "react";
import {Link} from "react-router-dom";
import authStore from "../../../Stores/AuthStore/AuthStore";

export const ChildrenTable = observer(() => {

    return <>
        <table className="table mr-20 ml-20 mt-5" style={{width: '100%'}}>
            <thead style={{width: '100%'}}>
            <tr className="table_header">
                <th className="col-4">Фамилия Имя</th>
                <th className="col-4">Садик</th>
                <th className="col-4">Группа</th>
            </tr>
            </thead>
            <tbody>
            {childrenStore.ChildList?.map((child) =>
            <>
                {childrenStore.childId !== 0 && childrenStore.changeFlag && child.UserId === childrenStore.childId ?
                    <>
                        <tr className="table_main" onClick={() => childrenStore.ChangeInputOnP()} style={{background: "#E6E9ED", color: "black"}}>
                        <td>{child.Name + ' ' + child.Surname}</td>
                        <td>{child.GroupInfo !== null ? child.GroupInfo.Group.User.KGName : 'Не состоит в садике'}</td>
                        <td>{child.GroupInfo !== null ? child.GroupInfo.Group.Name : 'Не состоит в группе'}</td>
                        </tr>
                        <tr style={{background: "#E6E9ED", color: "black"}}>
                            <td colSpan={3}>
                                <div className='row'>
                                    <div className="col-md-4 col-sm-4">
                                        <div className="form_group ml-md-10 pb-md-10 mr-md-5 sm-button-child">
                                            <button id="select_education" className="buttons button_green"
                                                    onClick={() => {
                                                        authStore.ChangeRelatedAccount(child.UserId)
                                                    }}
                                                    style={{width: '100%', margin: 0, padding: 0}}>ВОЙТИ В АККАУНТ
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4">
                                        <div className="form_group mr-md-5 pb-md-10 ml-md-5 sm-button-child">
                                            <Link style={{width: '100%', display: 'flex', textDecoration: 'none'}} to={'/children/change'}>
                                                <button id="auth_button" className="buttons button_blue"
                                                        style={{width: '100%', margin: 0, padding: 0}}>ИЗМЕНИТЬ ДАННЫЕ
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4">
                                        <div className="form_group mr-md-10 pb-md-10 ml-md-5 sm-button-child">
                                            <button className="buttons button_yellow" onClick={() => {childrenStore.DeleteChild()}}
                                                    style={{width: '100%', margin: 0, padding: 0}}>УДАЛИТЬ РЕБЕНКА
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </>
                    :
                    <>
                        <tr className="table_main" onClick={() => childrenStore.ChangePOnInput(child)}>
                        <td>{child.Name + ' ' + child.Surname}</td>
                        <td>{child.GroupInfo !== null ? child.GroupInfo.Group.User.KGName : 'Не состоит в садике'}</td>
                        <td>{child.GroupInfo !== null ? child.GroupInfo.Group.Name : 'Не состоит в группе'}</td>
                        </tr>
                    </>
                }
            </>
            )}
            </tbody>
        </table>
    </>
})