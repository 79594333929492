import React, { useState } from "react";
import { observer } from "mobx-react";
import "react-select-search/style.css";
import { useHistory, Link } from "react-router-dom";
import authStore from "../../../../../Stores/AuthStore/AuthStore";
import { CheckStep } from "../../../registration1";
import UIBlockButton, {
  UIBlockButtonColor,
} from "../../../../../Components/Katelinlis/Button.tsx";
import { UIInputBlock } from "./UIInputBlock";
import { set } from "mobx";

export const RegStep3TeacherCode = observer((props) => {
  const [SuccessPopup, setSuccessPopup] = useState(false);

  if (SuccessPopup) {
    window.scrollTo(0, 0);
    document.getElementsByTagName("body")[0].style =
      "height: 100vh; overflow-y: hidden;";
  } else {
    document.getElementsByTagName("body")[0].style = "";
  }

  let history = useHistory();

  if (authStore.isRegistration) {
    CheckStep("teacher");
  }

  return (
    <>
      <div
        className="RegistrationFirstStepMainContainer"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translateY(-50%) translateX(-50%)",
        }}
      >
        <div className="RegistrationTeacherCode-CardBox">
          <div className="RegistrationTeacherCodeCardBox-Card">
            <h2 className="RegistrationTeacherCodeCardBoxCard-Heading">
              У меня есть код
            </h2>
            <div className="RegistrationTeacherCodeCardBoxCard-Subflex">
              <p className="RegistrationTeacherCodeCardBoxCard-Summary">
                Введите код, которы вам дал директор чтобы&nbsp;быть
                в&nbsp;списке вашего детского сада
              </p>
              <UIInputBlock
                type="text"
                placeholder="Ввести код"
                height="48px"
              />
              <UIBlockButton
                style={UIBlockButtonColor.Primary}
                text="Ввести"
                onClick={() => {
                  setSuccessPopup(true);
                }}
              />
            </div>
          </div>
          <div className="RegistrationTeacherCodeCardBox-Card">
            <h2 className="RegistrationTeacherCodeCardBoxCard-Heading">
              У меня нет кода
            </h2>
            <div className="RegistrationTeacherCodeCardBoxCard-Subflex">
              <p className="RegistrationTeacherCodeCardBoxCard-Summary">
                Введите код позже в личном кабинете, а пока заполните информацию
                о себе
              </p>
              <Link to="/registration/teacher-name">
                <UIBlockButton
                  style={UIBlockButtonColor.Primary}
                  text="Далее"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {SuccessPopup ? (
        <div
          className="popup-bg"
          onClick={(e) => {
            if (e.target !== e.currentTarget) {
              return;
            } else {
              setSuccessPopup(false);
            }
          }}
        >
          <div id="TeacherCode-Popup" className="popup-card">
            <h1>Вы успешно зарегистрировались!</h1>
            <p>
              На почту вам пришло письмо. Перейдите по ссылке,
              чтобы&nbsp;подтвердить почту
            </p>
            <div id="TeacherCodePopupButton-Desktop">
              <Link to="/dashboard">
                <button
                  id="restore-popup-button-bg"
                  className="button-green"
                  onClick={() => {
                    localStorage.setItem("accountType", "teacher");
                  }}
                >
                  Хорошо
                </button>
              </Link>
            </div>
            <div id="TeacherCodePopupButton-Mobile">
              <Link to="/dashboard-mobile">
                <button
                  id="restore-popup-button-bg"
                  className="button-green"
                  onClick={() => {
                    localStorage.setItem("accountType", "teacher");
                  }}
                >
                  Хорошо
                </button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
});

export default RegStep3TeacherCode;
