import {makeAutoObservable} from 'mobx'
import {getRecommendation} from '../../../../../Request/api/Olymp/getRecomendation'
import reportStore from "../../../../../Stores/Report/ReportStore";



class Store {
  data = []
  loading = true
  recommend = []
  constructor() {
    makeAutoObservable(this)
  }

  //Получение данных рекомендация
  loadData = async () => {
    //TODO: добавить обработку ошибок!
    getRecommendation(reportStore.bestGame.LevelName)
      .then(r => this.data.push(r))
      .then(() => {
        getRecommendation(reportStore.worseGame.LevelName).then(r => this.data.push(r))
        this.setLoad(false)
      })
      .then(()=>{
        this.randRecomm()
      })



  }

  randRecomm = () => {
      this.recommend = []

      const countB = Math.floor(Math.random() * this.data[0].bestGame.length),
        countW =  Math.floor(Math.random() * this.data[1].worseGame.length)

      this.recommend.push(this.data[0].bestGame[countB])
      this.recommend.push(this.data[1].worseGame[countW])


  }

  setLoad = (state) => this.loading = state
}

export const RecStore = new Store()