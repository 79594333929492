import React from "react";

export enum UIBlockButtonColor {
  Danger = "#FE356B",
  Primary = "#6DC9E9",
  Second = "#A0D468",
  Disable = "#C7CBCF",
}

type onClick = () => {};

const UIBlockButton = (props: {
  text: string;
  onClick: onClick;
  margin: string;
  style: UIBlockButtonColor;
  disabled;
  plusIcon;
  padding: string;
}) => {
  let background = props.style;
  if (props.disabled) background = UIBlockButtonColor.Disable;

  let className = "";
  let padd = props.padding;

  if (!padd) {
    padd = "14px 61px";
  }

  if (props.style == UIBlockButtonColor.Danger) className = "button-red";
  else if (props.style == UIBlockButtonColor.Primary) className = "button-blue";
  else if (props.style == UIBlockButtonColor.Second) className = "button-green";

  if (props.plusIcon) {
    className = className + " plusIcon";
  }

  return (
    <button
      className={className}
      disabled={props.disabled}
      onClick={props.onClick}
      style={{
        padding: padd,
        background,
        borderRadius: "8px",
        //border: "none",
        color: "#000",
        cursor: "pointer",
        fontSize: "20px",
        WebkitUserSelect: "none",
        msUserSelect: "none",
        userSelect: "none",
        margin: props.margin,
        border: "solid 1px" + background,
        boxSizing: "border-box",
      }}
    >
      {props.plusIcon ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginRight: "8px" }}
        >
          <path
            d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20Z"
            fill="black"
          />
        </svg>
      ) : (
        ""
      )}
      {props.text}
    </button>
  );
};

export default UIBlockButton;
