import {observer} from "mobx-react";
import React from "react";
import {Link} from "react-router-dom";
import authStore from "../../Stores/AuthStore/AuthStore";

export const RightHeader = observer(() => {

    return <>
        <div className="right_open_menu mt_header" id="right_open_menu_id">
            <Link style={{textDecoration: 'none'}} to="/account"><p>АККАУНТ</p></Link>
            {authStore.userData.UserType === "Parent"?
                <>
                    <Link style={{textDecoration: 'none'}} to="/children"><p>ДЕТИ</p></Link>
                    <Link style={{textDecoration: 'none'}} to="/account/security"><p>ЗАЩИТА</p></Link>
                    <Link style={{textDecoration: 'none'}} to="/account/notification"><p>УВЕДОМЛЕНИЯ</p></Link>
                </>
                :<></>}

            {authStore.userData.UserType === "Children"?
                <>
                    <Link style={{textDecoration: 'none'}} to="/game"><p>ИГРЫ</p></Link>
                </>
                :<></>}

            {authStore.userData.UserType === "Pedagog"?
                <>
                    <Link style={{textDecoration: 'none'}} to="/account/security"><p>ЗАЩИТА</p></Link>
                    <Link style={{textDecoration: 'none'}} to="/account/notification"><p>УВЕДОМЛЕНИЯ</p></Link>
                    {authStore.userData.CreatedGartenId !== 0?
                        <Link style={{textDecoration: 'none'}} to="/kindergarten"><p>ДЕТСКИЙ САД</p></Link> : <></>}
                    {authStore.userData.Position.IsEditable && authStore.userData.CreatedGartenId == 0?
                      <Link style={{textDecoration: 'none'}} to="/registration/kindegarten"><p>СОЗДАТЬ ДЕТ.САД</p></Link> : <> </>}



                    {!authStore.userData.Position.IsEditable?
                        <Link style={{textDecoration: 'none'}} to="/groups"><p>ГРУППЫ</p></Link> : <></>}
                    <Link style={{textDecoration: 'none'}} to="/account/teacher/invite"><p>
                        <span className="invite_mobile">ИНВАЙТ-КОД</span>
                        <span className="invite_comp">КОД-ПРИГЛАШЕНИЕ</span>
                    </p></Link>
                    <Link style={{textDecoration: 'none'}} to="/report">
                        <p>Отчёты</p>
                    </Link>
                </>
                :<></>}
        </div>
    </>
})

window.addEventListener("click", function(event) {
    if (document.getElementById('right_open_menu_id') !== null && !event.target.matches('.image_right_menu')) {
        document.getElementById('right_open_menu_id').style.display = 'none';
        document.getElementById('opacity_panel').style.display = 'none';
    }
});
