import { action, observable } from "mobx";


export enum UserRoles {
  Director = 0,
  Teacher = 1,
  Parent = 2,
  Children = 3,
}

export interface IUserRegisterForm {
  firstAndLastName: string,
  phone: string,
  email: string,
  role: UserRoles,
  password: string,
  passwordRepeat:string,
}

class registerStore implements IUserRegisterForm {

  @observable firstAndLastName = "";
  @observable phone = '';
  @observable email = '';
  @observable role = UserRoles.Teacher;
  @observable password = '';
  @observable passwordRepeat = '';

  @action Clear(){
        this.firstAndLastName = "";
        this.phone = "";
        this.email = "";
        this.password = "";
        this.passwordRepeat = "";


  }

}

export const RegisterStore = new registerStore();