import {action, makeAutoObservable, observable} from "mobx";
import {appConfig} from "../../../config";
import regStepStore from "../../../Stores/Registration/StepRegistration";
import masterRegStore from "../../../Stores/Registration/MasterRegStore";
import authStore from "../../../Stores/AuthStore/AuthStore";
import modalStore from "../../modal/modalStore";

class InviteStore {
    Invite = "";
    @observable InviteValid = "";
    @observable HelpText = "";
    InviteData = {};
    disabled = 'disabled';
    InviteSend = '';

    constructor() {
        makeAutoObservable(this);
    }

    @action UserKeyDown(inviteValid="", helpText=""){
        this.InviteValid = inviteValid;
        this.HelpText = helpText;
    }


    SetInvite(e){
        this.Invite = e.target.value;
        this.UserKeyDown();
        if(e.target.value.trim().length > 0){
            this.disabled = '';
        } else {
            this.disabled = 'disabled'
        }
    }

    ValidInvite(){
        if(this.Invite.length === 0){
            this.UserKeyDown("is-invalid", "Укажите ваш код-приглашение")
        }
    }

    get GetInvite(){
        this.ValidInvite();
        if(this.InviteValid === "is-invalid"){
            return "";
        } else {
            return this.Invite;
        }
    }

    @action Clear(){
        this.Invite = "";
        this.HelpText = "";
        this.InviteValid = "";
        this.disabled = 'disabled';
        this.InviteData = {};
    }

    @action CheckInviteCode = async(history=null) => {

        let response = await fetch(`${appConfig.api_host}/api/base_groups/invite/get_data/${this.Invite}`,{
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
            },
        });

        await response.json().then(
            (response) =>{
                if(response.type === "Data"){
                    this.InviteSend = this.Invite
                    this.InviteData = response.data

                    sessionStorage.setItem('invite', this.Invite);
                    sessionStorage.setItem('inviteData', JSON.stringify(this.InviteData));

                    // Так и не выяснил зачем это тут
                   // window.$('#simple_message_modal').modal('hide');

                    regStepStore.setStep("0")
                    masterRegStore.setStep('master');
                    if(history !== null && window.location.href.indexOf('/registration/') !== -1 && authStore.access === null){
                        history.push('/registration/master');
                    }


                    if(JSON.parse(sessionStorage.getItem('inviteData')).UserType === "Children" && authStore.access !== null){
                        masterRegStore.setStep('parent');
                        history.push('/registration/parent');
                    }
                    if(JSON.parse(sessionStorage.getItem('inviteData')).UserType === "Pedagog" && authStore.access !== null){
                        masterRegStore.setStep('teacher');
                        history.push('/registration/teacher');
                    }

                    this.Clear();
                } else {
                    this.UserKeyDown("is-invalid","Недействительный код-приглашение")
                }

            },
            (error) =>{
                modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                modalStore.simpleModalOpen();
            });

    }

    KeyDown = (e) =>{
        if(e.keyCode === 32){
            e.preventDefault();
        }
    }
}

export const inviteStore = new InviteStore();