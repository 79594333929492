import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import authStore from "../../Stores/AuthStore/AuthStore";
import { Account1 } from "../../Modules/Account/account1";
import {
  RegistrationKindegarten,
  RegistrationParent,
  RegistrationTeacher,
  RegistrationDirector,
  RegistrationChildren,
  RegistrationMaster,
  RegistrationInvite,
  RegistrationSelect,
  RegistrationQuestChildren,
  RegistrationQuestKindegarten,
  RegistrationDirectorEduStep,
  RegistrationDirectorName,
  RegistrationEduNotFound,
  DashboardPage,
  DashboardMobilePage,
  DashboardMobileCertPage,
  DashboardMobileSecurityPage,
  DashboardMobileTeachersPage,
  DashboardMobileAccountPage,
  DashboardMobileQuizPage,
  DashboardMobileKidsPage,
  DashboardMobileCodePage,
  RegistrationByCodePage,
  DashboardMobileGroupsPage,
  DashboardMobileResultsPage,
  RegStep3TeacherCodePage,
  TeacherNamePage,
  RegStep3ParentCodePage,
  ParentNamePage,
} from "../../Modules/RegAuth/registration1";
import Auth from "../../Modules/RegAuth/Auth/Auth";
import { Restore1 } from "../../Modules/RegAuth/Restore1";
import { Restore2 } from "../../Modules/RegAuth/Restore2";
import { ComfirmMail1 } from "../../Modules/RegAuth/comfirmMail1";
import { RestorePassword1 } from "../../Modules/RegAuth/restorePassword1";
import GameIframe from "../../Modules/GameIframe/gameIframe";
import Header from "../Header/header";
import { Children } from "../../Modules/Children/Children";
import { ChildrenAdd } from "../../Modules/Children/Components/ChildrenAdd";
import { Kindergarten } from "../../Modules/Kindergarten/Kindergarten";
import { Groups } from "../../Modules/Groups/Groups";
import { KindergartenChange } from "../../Modules/KindergartenChange/KindergartenChange";
import loadStore from "../../Stores/LoadStore/loadStore";
import { observer } from "mobx-react";
import MessageModal from "../../Components/modal/messageModal";
import Modal from "../../Components/modal/modal";
import { TermsOfUser } from "../../Modules/TermsOfUser/termsOfUser";
import { privacyPolicy } from "../../Modules/PrivacyPolicy/privacyPolicy";
import { ChildrenInvite } from "../../Modules/Children/Components/ChildrenInvite";
import { SecurityAccount } from "../../Modules/Account/AccountPages/Security";
import { NotificationsAccount } from "../../Modules/Account/AccountPages/Notifications";
import { TeacherAdd } from "../../Modules/Kindergarten/Components/teacherAdd";
import { AddGroup } from "../../Modules/Groups/Components/Group/addGroup";
import { AddGroupChildren } from "../../Modules/Groups/Components/ChildrenGroup/addGroupChildren";
import RegStepInviteAdd from "../../Modules/RegAuth/RegStep/RegStepInviteAdd";
import { TeacherInvite } from "../../Modules/Account/AccountPages/teacherInvite";
import { ChangeTeacher } from "../../Modules/Kindergarten/Components/changeTeacher";
import { ChangeGroup } from "../../Modules/Groups/Components/Group/changeGroup";
import { ChangeGroupChildren } from "../../Modules/Groups/Components/ChildrenGroup/changeChildren";
import { ChildrenChange } from "../../Modules/Children/Components/ChildrenChange";
import { Report } from "../../Modules/Report/Report";
import modalStore from "../../Components/modal/modalStore";
import "../../styles/text.css";
import "../../styles/mainPage.css";
import UIBlockButton, {
  UIBlockButtonColor,
} from "../../Components/Katelinlis/Button.tsx";
import { NONAME } from "dns";
import { Divider } from "semantic-ui-react";

function Main() {
  return (
    <>
      <Router>
        <div>
          <Routes />

          {/*<Question/>*/}
          <Modal />
          <MessageModal />
          <OpacityPanel />
          <div
            id="opacity_panel"
            onClick={() => {
              document.getElementById("right_open_menu_id").style.display =
                "none";
              document.getElementById("opacity_panel").style.display = "none";
            }}
          />
          <div id="opacity_panel_two" />
        </div>
      </Router>
      <Loader />
    </>
  );
}

const Routes = () => {
  return (
    <>
      <Header />
      <div style={{ margin: 0, padding: 0 }}>
        <Switch>
          <Route exact path={"/"} component={Home} />

          <Route exact path={"/game"} component={GameIframe} />

          <Route
            exact
            path={"/registration/invite"}
            component={RegistrationInvite}
          />
          <Route
            exact
            path={"/registration/invite/add"}
            component={RegStepInviteAdd}
          />
          <Route
            exact
            path={"/registration/master"}
            component={RegistrationMaster}
          />
          <Route
            exact
            path={"/registration/select"}
            component={RegistrationSelect}
          />

          <Route
            exact
            path={"/registration/parent"}
            component={RegistrationParent}
          />
          <Route
            exact
            path={"/registration/teacher"}
            component={RegistrationTeacher}
          />
          <Route
            exact
            path={"/registration/code"}
            component={RegistrationByCodePage}
          />
          <Route
            exact
            path={"/registration/director"}
            component={RegistrationDirector}
          />

          <Route
            exact
            path={"/registration/director-edu"}
            component={RegistrationDirectorEduStep}
          />

          <Route
            exact
            path={"/registration/director-name"}
            component={RegistrationDirectorName}
          />
          <Route
            exact
            path={"/registration/custom-edu"}
            component={RegistrationEduNotFound}
          />

          <Route exact path={"/dashboard"} component={DashboardPage} />

          <Route
            exact
            path={"/dashboard-mobile"}
            component={DashboardMobilePage}
          />

          <Route
            exact
            path={"/dashboard-mobile/cert"}
            component={DashboardMobileCertPage}
          />

          <Route
            exact
            path={"/dashboard-mobile/security"}
            component={DashboardMobileSecurityPage}
          />

          <Route
            exact
            path={"/dashboard-mobile/teachers"}
            component={DashboardMobileTeachersPage}
          />

          <Route
            exact
            path={"/dashboard-mobile/groups"}
            component={DashboardMobileGroupsPage}
          />

          <Route
            exact
            path={"/dashboard-mobile/account"}
            component={DashboardMobileAccountPage}
          />

          <Route
            exact
            path={"/dashboard-mobile/quiz"}
            component={DashboardMobileQuizPage}
          />

          <Route
            exact
            path={"/dashboard-mobile/kids"}
            component={DashboardMobileKidsPage}
          />

          <Route
            exact
            path={"/dashboard-mobile/results"}
            component={DashboardMobileResultsPage}
          />

          <Route
            exact
            path={"/dashboard-mobile/code"}
            component={DashboardMobileCodePage}
          />

          <Route
            exact
            path={"/registration/children"}
            component={RegistrationChildren}
          />
          <Route
            exact
            path={"/registration/kindegarten"}
            component={RegistrationKindegarten}
          />
          <Route
            exact
            path={"/registration/question/children"}
            component={RegistrationQuestChildren}
          />
          <Route
            exact
            path={"/registration/question/kindegarten"}
            component={RegistrationQuestKindegarten}
          />

          <Route
            exact
            path={"/registration/teacher-code"}
            component={RegStep3TeacherCodePage}
          />

          <Route
            exact
            path={"/registration/parent-code"}
            component={RegStep3ParentCodePage}
          />

          <Route
            exact
            path={"/registration/teacher-name"}
            component={TeacherNamePage}
          />

          <Route
            exact
            path={"/registration/parent-name"}
            component={ParentNamePage}
          />

          <Route exact path={"/terms"} component={TermsOfUser} />
          <Route exact path={"/privacy"} component={privacyPolicy} />

          <Route exact path={"/authorization"} component={Auth} />

          {/**/}
          <Route exact path={"/restore"} component={Restore1} />
          <Route exact path={"/new-password"} component={Restore2} />

          {/**/}
          <Route exact path={"/confirmEmail"} component={ComfirmMail1} />

          {/**/}
          <Route
            exact
            path={"/restore_password"}
            component={RestorePassword1}
          />

          <Route exact path={"/account"} component={Account1} />
          <Route exact path={"/account/security"} component={SecurityAccount} />
          <Route
            exact
            path={"/account/notification"}
            component={NotificationsAccount}
          />
          <Route
            exact
            path={"/account/teacher/invite"}
            component={TeacherInvite}
          />

          <Route exact path={"/kindergarten"} component={Kindergarten} />

          <Route exact path={"/kindergarten/teacher"} component={TeacherAdd} />

          <Route
            exact
            path={"/kindergarten/change"}
            component={KindergartenChange}
          />

          <Route
            exact
            path={"/kindergarten/teacher/change"}
            component={ChangeTeacher}
          />

          <Route exact path={"/groups"} component={Groups} />
          <Route exact path={"/groups/change"} component={ChangeGroup} />
          <Route exact path={"/groups/add"} component={AddGroup} />
          <Route
            exact
            path={"/groups/children/add"}
            component={AddGroupChildren}
          />
          <Route
            exact
            path={"/groups/children/change"}
            component={ChangeGroupChildren}
          />

          <Route exact path={"/children"} component={Children} />
          <Route exact path={"/children/add"} component={ChildrenAdd} />
          <Route exact path={"/children/invite"} component={ChildrenInvite} />
          <Route exact path={"/children/change"} component={ChildrenChange} />

          <Route exact path={"/report"} component={Report} />

          <Route path="*" exact={true} component={NotFound} />
        </Switch>
      </div>
    </>
  );
};

const OpacityPanel = observer(() => {
  let history = useHistory();
  return (
    <>
      <div
        id="opacity"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          background: "black",
          opacity: "80%",
          width: "100%",
          height: "100%",
          display: "none",
          zIndex: 90,
        }}
        onClick={() => {
          document.getElementById("simple_modal_message").style.display =
            "none";
          document.getElementById("modal_message").style.display = "none";
          document.getElementById("opacity").style.display = "none";
          modalStore.modalCode = false;
          Close(history);
        }}
      />
    </>
  );
});

function Close(history) {
  if (authStore.isRegistration) {
    if (modalStore.EndReg) {
      history.push("/authorization");
      modalStore.ModalClose();
    }
  }
}

const Question = observer(() => {
  return (
    <>
      <svg
        className="question"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
      >
        <circle style={{ fill: "#a0d468" }} cx="128" cy="128" r="128" />
        <text
          style={{ fontSize: "250px", fill: "#fff", fontWeight: 700 }}
          transform="translate(82.99 194.432) scale(0.745)"
        >
          ?
        </text>
      </svg>
    </>
  );
});

const NotFound = () => {
  let history = useHistory();
  return (
    <div style={{ height: "100vh" }}>
      <div
        id="NotFoundContainer"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translateY(-50%) translateX(-50%)",
        }}
      >
        <h1
          id="NotFoundHeader"
          style={{
            margin: "0",
            textAlign: "center",
            fontFamily: "Open Sans",
            fontWeight: "700",
            fontSize: "72px",
            lineHeight: "120%",
            letterSpacing: "-0.01em",
            color: "#000000",
          }}
        >
          404
        </h1>
        <h2
          id="NotFoundSubHeader"
          style={{
            margin: "16px 0 0",
            textAlign: "center",
            fontFamily: "Open Sans",
            fontWeight: "700",
            fontSize: "24px",
            lineHeight: "120%",
            letterSpacing: "-0.01em",
            color: "#000000",
          }}
        >
          Страница не найдена
        </h2>
        <p
          id="NotFoundText"
          style={{
            margin: "32px 0",
            textAlign: "center",
            fontFamily: "Open Sans",
            fontWeight: "400",
            fontSize: "24px",
            lineHeight: "120%",
            letterSpacing: "-0.01em",
            color: "#000000",
          }}
        >
          Неправильно набран адрес или такой страницы не существует
        </p>
        <div
          id="NotFoundButtonsBox"
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link to="/">
            <button
              className="button-green"
              style={{
                boxSizing: "border-box",
                background: "#A0D468",
                borderRadius: "8px",
                padding: "16px 32px",
                fontFamily: "PT Sans Caption",
                fontSize: "16px",
                color: "#000000",
                border: "1px solid #A0D468",
                cursor: "pointer",
                margin: "0 12px",
              }}
            >
              На главную
            </button>
          </Link>
          <button
            onClick={history.goBack}
            className="button-green-trans"
            style={{
              boxSizing: "border-box",
              background: "transparent",
              borderRadius: "8px",
              padding: "16px 32px",
              fontFamily: "PT Sans Caption",
              fontSize: "16px",
              color: "#000000",
              border: "1px solid #A0D468",
              cursor: "pointer",
              margin: "0 12px",
            }}
          >
            Вернуться назад
          </button>
        </div>
      </div>
    </div>
  );
};

const Loader = observer(() => {
  return (
    <>
      {loadStore.load ? (
        <>
          <div
            className="loader"
            style={{
              zIndex: 1039,
              position: "fixed",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            Loading...
          </div>
          <div
            style={{
              background: "black",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1038,
              opacity: ".89",
            }}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
});

const Home = observer(() => {
  const [code,setCode] = useState("")
  let history = useHistory();
  if (authStore.isAuth) {
    history.push("/account");
  }
  document.getElementsByTagName("body")[0].style = "";
  return (
    <>
      <div
        className="pt_header"
        style={{ borderLeft: "none", borderRight: "none", width: "100%" }}
      >
        <section class="topSection">
          <div id="internal">
            <div style={{ display: "inline-block" }}>
              <p className="topLabel">
                Олимпиада <br />
                по экологии
              </p>
              <p class="descriptionText">
                Онлайн-путешествие развивает
                <br />
                экологическое мышление, закрепляет
                <br />
                знания по окружающему миру
              </p>
              <div>
                <Link to="authorization">
                  <button
                    id="select_education"
                    className="button-blue-index unselectable"
                    style={{
                      width: "202px",
                      height: "72px",
                      background: "#6EAFFA",
                      borderRadius: "8px",
                      fontFamily: "PT Sans Caption",
                      fontWeight: "700",
                      fontSize: "20px",
                      lineHeight: "24px",
                      letterSpacing: "-0.01em",
                      color: "#41361B",
                      border: "none",
                      cursor: "pointer",
                      margin: "0 37px 0 0",
                    }}
                  >
                    Войти
                  </button>
                </Link>

                <Link
                  className="max_200 max_200_right"
                  to="/registration/select"
                >
                  <button
                    id="registration_button"
                    className="button-green-index unselectable"
                    style={{
                      width: "290px",
                      height: "72px",
                      background: "#39CC5C",
                      borderRadius: "8px",
                      fontFamily: "PT Sans Caption",
                      fontWeight: "700",
                      fontSize: "20px",
                      lineHeight: "24px",
                      letterSpacing: "-0.01em",
                      color: "#41361B",
                      border: "none",
                      cursor: "pointer",
                      margin: "0 68px 37px 0",
                    }}
                  >
                    Зарегистрироваться
                  </button>
                </Link>
              </div>
            </div>
            <div style={{ display: "inline-block" }}>
              <img className="unselectable" src="/planet.webp"></img>
            </div>
          </div>
        </section>
        <section class="topSection-mobile" style={{ display: "none" }}>
          <img
            className="topSection-mobile-planet unselectable"
            src="/planet.webp"
          ></img>
          <p className="topLabel-mobile">
            Олимпиада <br />
            по экологии
          </p>
          <p class="descriptionText-mobile">
            Онлайн-путешествие развивает
            <br />
            экологическое мышление, закрепляет
            <br />
            знания по окружающему миру
          </p>

          <Link to="authorization">
            <button
              id="select_education"
              className="button-blue-index unselectable"
              style={{
                width: "202px",
                height: "72px",
                background: "#6EAFFA",
                borderRadius: "8px",
                fontFamily: "PT Sans Caption",
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "24px",
                letterSpacing: "-0.01em",
                color: "#41361B",
                border: "none",
                cursor: "pointer",
                margin: "0 37px 0 0",
              }}
            >
              Войти
            </button>
          </Link>

          <Link className="max_200 max_200_right" to={code === "" ? "/registration/select" : "/registration/code"}>
            <button

              id="registration_button"
              className="button-green-index unselectable"
              style={{
                width: "290px",
                height: "72px",
                background: "#39CC5C",
                borderRadius: "8px",
                fontFamily: "PT Sans Caption",
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "24px",
                letterSpacing: "-0.01em",
                color: "#41361B",
                border: "none",
                cursor: "pointer",
                margin: "0 68px 37px 0",
              }}
            >
              Зарегистрироваться
            </button>
          </Link>
          <input
            value={code}
              onChange={(it)=>{
                setCode(it.target.value)
              }}
            className="placeholder-fix-home-page"
            style={{
              padding: "6.6vw 8.8vw",
              width: "100%",
              background: "#FFFFFF",
              border: "1px solid #3773CD",
              borderRadius: "2.2vw",
              boxSizing: "border-box",
              fontFamily: "PT Sans Caption",
              fontWeight: "700",
              fontSize: "5.5vw",
              lineHeight: "6.6vw",
              color: "#4E87DB",
              WebkitTapHighlightColor: "transparent!important",
              cursor: "pointer",
            }}
            placeholder="Введите код—приглашение"
          ></input>
        </section>
        <section
          id="mainpage-section-code"
          style={{ margin: "0 auto", width: "850px" }}
        >
          <h3
            style={{
              margin: "68px 0 25px",
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "32px",
              lineHeight: "120%",
              color: "#1B5CBE",
              textAlign: "center",
            }}
          >
            Есть код-приглашение?
          </h3>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
              margin: "0 0 96px",
            }}
          >
            <input
              value={code}
              onChange={(it)=>{
                setCode(it.target.value)
              }}
              className="placeholder-fix-home-page"
              style={{
                padding: "24px 32px",
                width: "535px",
                background: "#FFFFFF",
                border: "1px solid #3773CD",
                borderRadius: "8px",
                boxSizing: "border-box",
                fontFamily: "PT Sans Caption",
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "24px",
                color: "#4E87DB",
                WebkitTapHighlightColor: "transparent!important",
                cursor: "pointer",
              }}
              placeholder="Введите код—приглашение"
            ></input>
            <Link to="/registration/code">
              <button
              disabled={code===""}
                className="button-blue unselectable"
                style={{
                  width: "290px",
                  height: "72px",
                  background: "#1B5CBE",
                  borderRadius: "8px",
                  fontFamily: "PT Sans Caption",
                  fontWeight: "700",
                  fontSize: "20px",
                  lineHeight: "24px",
                  letterSpacing: "-0.01em",
                  color: "#FFFFFF",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Активировать
              </button>
            </Link>
          </div>
        </section>
        <section id="mainpage-section-our-target">
          <h2
            style={{
              margin: "28px 0 25px",
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "32px",
              lineHeight: "120%",
              color: "#FA7348",
              textAlign: "center",
            }}
          >
            Наша цель
          </h2>
          <p
            style={{
              fontFamily: "Open Sans",
              fontWeight: "400",
              fontSize: "24px",
              lineHeight: "120%",
              letterSpacing: "-0.01em",
              color: "#000000",
              width: "865px",
              textAlign: "center",
              margin: "24px auto 0",
            }}
          >
            Дать знания дошкольникам и их родителям о базовых понятиях
            экологического образа жизни
          </p>
          <div className="mainpage-cards-box">
            <div className="mainpage-card">
              <svg
                width="75"
                height="82"
                viewBox="0 0 75 82"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M73.2246 65.7393C73.2246 74.0235 66.5089 80.7393 58.2246 80.7393H16.2246C7.94034 80.7393 1.22461 74.0235 1.22461 65.7393V28.7393H73.2246V65.7393Z"
                  fill="#FFB595"
                  stroke="#F5F7FA"
                  stroke-width="2"
                />
                <circle cx="48.2246" cy="41.7393" r="3" fill="#F5F7FA" />
                <circle cx="58.2246" cy="41.7393" r="3" fill="#F5F7FA" />
                <circle cx="58.2246" cy="50.7393" r="3" fill="#F5F7FA" />
                <circle cx="48.2246" cy="50.7393" r="3" fill="#F5F7FA" />
                <circle cx="38.2246" cy="50.7393" r="3" fill="#F5F7FA" />
                <circle cx="28.2246" cy="50.7393" r="3" fill="#F5F7FA" />
                <circle cx="18.2246" cy="50.7393" r="3" fill="#F5F7FA" />
                <circle cx="18.2246" cy="59.7393" r="3" fill="#F5F7FA" />
                <circle cx="18.2246" cy="68.7393" r="3" fill="#F5F7FA" />
                <circle cx="28.2246" cy="59.7393" r="3" fill="#F5F7FA" />
                <circle cx="38.2246" cy="59.7393" r="3" fill="#F5F7FA" />
                <circle cx="48.2246" cy="59.7393" r="3" fill="#F5F7FA" />
                <circle cx="58.2246" cy="59.7393" r="3" fill="#F5F7FA" />
                <path
                  d="M73.2246 28.7393H1.22461V22.7393C1.22461 14.455 7.94034 7.73926 16.2246 7.73926H58.2246C66.5089 7.73926 73.2246 14.455 73.2246 22.7393V28.7393Z"
                  fill="#FA7348"
                  stroke="#F5F7FA"
                  stroke-width="2"
                />
                <rect
                  x="13.2246"
                  y="1.73926"
                  width="4"
                  height="13"
                  rx="1"
                  fill="#FA7348"
                  stroke="#F5F7FA"
                  stroke-width="2"
                />
                <rect
                  x="56.2246"
                  y="1.73926"
                  width="4"
                  height="13"
                  rx="1"
                  fill="#FA7348"
                  stroke="#F5F7FA"
                  stroke-width="2"
                />
              </svg>
              <div>
                <h4>Дата проведения</h4>
                <span>7 ноября - 16 декабря</span>
              </div>
            </div>
            <div className="mainpage-card">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 67 47"
                width="74px"
                height="77px"
              >
                <path
                  fill="#FA7348"
                  d="M16.13 17.05c2.8 0 5.27.53 7.44 1.6a12.07 12.07 0 0 1 5.16 4.68c1.25 2.07 1.87 4.6 1.87 7.63 0 3.29-.69 6.13-2.06 8.53a13.26 13.26 0 0 1-6.1 5.47c-2.68 1.27-6.04 1.9-10.06 1.9-2.4 0-4.65-.2-6.78-.62-2.1-.4-3.95-1.01-5.53-1.84v-8.35c1.58.84 3.48 1.54 5.69 2.13 2.23.56 4.32.84 6.28.84 1.92 0 3.53-.25 4.84-.75a5.98 5.98 0 0 0 2.97-2.37 7.57 7.57 0 0 0 1.03-4.13c0-2.23-.75-3.94-2.25-5.12-1.5-1.2-3.8-1.79-6.9-1.79-1.2 0-2.43.12-3.72.35a40 40 0 0 0-3.19.69L.98 23.83 2.69.55h24.79v8.19H11.16l-.84 8.97a21.52 21.52 0 0 1 5.81-.66Zm38.49 3.22h12.1v6.84h-12.1v12.04h-6.85V27.1H35.68v-6.84h12.1V8.08h6.84v12.19Z"
                />
              </svg>
              <div>
                <h4>Возраст</h4>
                <span>5 - 7 лет</span>
              </div>
            </div>
            <div className="mainpage-card">
              <svg
                width="75"
                height="81"
                viewBox="0 0 75 81"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="14"
                  y="7"
                  width="52"
                  height="62"
                  rx="2"
                  fill="#FA7348"
                />
                <path
                  d="M60.25 72V15C60.25 13.8954 59.3546 13 58.25 13H21.5603L9.84217 24.6131C9.46319 24.9887 9.25 25.5002 9.25 26.0337V72C9.25 73.1046 10.1454 74 11.25 74H58.25C59.3546 74 60.25 73.1046 60.25 72Z"
                  fill="#FFB595"
                />
                <path
                  d="M21.2086 13H21.5603M21.5603 13H58.25C59.3546 13 60.25 13.8954 60.25 15V72C60.25 73.1046 59.3546 74 58.25 74H11.25C10.1454 74 9.25 73.1046 9.25 72V26.0337C9.25 25.5002 9.46319 24.9887 9.84217 24.6131L21.5603 13Z"
                  stroke="#F5F7FA"
                  stroke-width="2"
                />
                <path
                  d="M24.2246 59.5602L28.1081 47L34.613 48.8072L41.6033 47L44.2246 54.9518L39.6615 53.1446L37.1372 57.1205L34.613 48.8072L30.6324 62L28.1081 57.9337L24.2246 59.5602Z"
                  fill="#FA7348"
                />
                <ellipse
                  cx="34.7246"
                  cy="39"
                  rx="10.5"
                  ry="10"
                  fill="#F5F7FA"
                />
                <circle cx="34.563" cy="39.3384" r="5.33837" fill="#FA7348" />
              </svg>
              <div>
                <h4>Дипломы</h4>
                <span>всем участникам</span>
              </div>
            </div>
          </div>
          <h2 className="mainpage-game-header">Задания в игровой форме</h2>
        </section>
        <section className="mainpage-how-useful">
          <img className="unselectable" src="/ecologinya.webp"></img>
          <div>
            <h4>Чем полезна олимпиада</h4>
            <div className="mainpage-how-useful-block">
              <div className="mainpage-how-useful-block-row">
                <svg
                  width="22"
                  height="21"
                  viewBox="0 0 22 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.656 20.501C4.78901 20.501 0.0332031 16.024 0.0332031 10.501C0.0332031 4.97798 4.78901 0.500977 10.656 0.500977C16.5229 0.500977 21.2787 4.97798 21.2787 10.501C21.2787 16.024 16.5229 20.501 10.656 20.501ZM10.656 18.501C12.9098 18.501 15.0714 17.6581 16.6651 16.1578C18.2588 14.6575 19.1542 12.6227 19.1542 10.501C19.1542 8.37924 18.2588 6.34441 16.6651 4.84412C15.0714 3.34383 12.9098 2.50098 10.656 2.50098C8.40209 2.50098 6.24054 3.34383 4.64682 4.84412C3.0531 6.34441 2.15775 8.37924 2.15775 10.501C2.15775 12.6227 3.0531 14.6575 4.64682 16.1578C6.24054 17.6581 8.40209 18.501 10.656 18.501ZM9.59687 14.501L5.08963 10.258L6.59169 8.84398L9.59687 11.673L15.6051 6.01598L17.1082 7.42998L9.59687 14.501Z"
                    fill="#39CC5C"
                  />
                </svg>
                <p>
                  Поддерживает интерес детей к обучению и облегчает усвоение
                  информации
                </p>
              </div>
              <div className="mainpage-how-useful-block-row">
                <svg
                  width="22"
                  height="21"
                  viewBox="0 0 22 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.656 20.501C4.78901 20.501 0.0332031 16.024 0.0332031 10.501C0.0332031 4.97798 4.78901 0.500977 10.656 0.500977C16.5229 0.500977 21.2787 4.97798 21.2787 10.501C21.2787 16.024 16.5229 20.501 10.656 20.501ZM10.656 18.501C12.9098 18.501 15.0714 17.6581 16.6651 16.1578C18.2588 14.6575 19.1542 12.6227 19.1542 10.501C19.1542 8.37924 18.2588 6.34441 16.6651 4.84412C15.0714 3.34383 12.9098 2.50098 10.656 2.50098C8.40209 2.50098 6.24054 3.34383 4.64682 4.84412C3.0531 6.34441 2.15775 8.37924 2.15775 10.501C2.15775 12.6227 3.0531 14.6575 4.64682 16.1578C6.24054 17.6581 8.40209 18.501 10.656 18.501ZM9.59687 14.501L5.08963 10.258L6.59169 8.84398L9.59687 11.673L15.6051 6.01598L17.1082 7.42998L9.59687 14.501Z"
                    fill="#39CC5C"
                  />
                </svg>
                <p>
                  Мотивирует детей к бережному отношению к живой и неживой
                  природе
                </p>
              </div>
              <div className="mainpage-how-useful-block-row">
                <svg
                  width="22"
                  height="21"
                  viewBox="0 0 22 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.656 20.501C4.78901 20.501 0.0332031 16.024 0.0332031 10.501C0.0332031 4.97798 4.78901 0.500977 10.656 0.500977C16.5229 0.500977 21.2787 4.97798 21.2787 10.501C21.2787 16.024 16.5229 20.501 10.656 20.501ZM10.656 18.501C12.9098 18.501 15.0714 17.6581 16.6651 16.1578C18.2588 14.6575 19.1542 12.6227 19.1542 10.501C19.1542 8.37924 18.2588 6.34441 16.6651 4.84412C15.0714 3.34383 12.9098 2.50098 10.656 2.50098C8.40209 2.50098 6.24054 3.34383 4.64682 4.84412C3.0531 6.34441 2.15775 8.37924 2.15775 10.501C2.15775 12.6227 3.0531 14.6575 4.64682 16.1578C6.24054 17.6581 8.40209 18.501 10.656 18.501ZM9.59687 14.501L5.08963 10.258L6.59169 8.84398L9.59687 11.673L15.6051 6.01598L17.1082 7.42998L9.59687 14.501Z"
                    fill="#39CC5C"
                  />
                </svg>
                <p>
                  Формирует представления о классах отходов и многообразии
                  продуктов для повторного использования после переработки
                </p>
              </div>
              <div className="mainpage-how-useful-block-row">
                <svg
                  width="22"
                  height="21"
                  viewBox="0 0 22 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.656 20.501C4.78901 20.501 0.0332031 16.024 0.0332031 10.501C0.0332031 4.97798 4.78901 0.500977 10.656 0.500977C16.5229 0.500977 21.2787 4.97798 21.2787 10.501C21.2787 16.024 16.5229 20.501 10.656 20.501ZM10.656 18.501C12.9098 18.501 15.0714 17.6581 16.6651 16.1578C18.2588 14.6575 19.1542 12.6227 19.1542 10.501C19.1542 8.37924 18.2588 6.34441 16.6651 4.84412C15.0714 3.34383 12.9098 2.50098 10.656 2.50098C8.40209 2.50098 6.24054 3.34383 4.64682 4.84412C3.0531 6.34441 2.15775 8.37924 2.15775 10.501C2.15775 12.6227 3.0531 14.6575 4.64682 16.1578C6.24054 17.6581 8.40209 18.501 10.656 18.501ZM9.59687 14.501L5.08963 10.258L6.59169 8.84398L9.59687 11.673L15.6051 6.01598L17.1082 7.42998L9.59687 14.501Z"
                    fill="#39CC5C"
                  />
                </svg>
                <p>
                  Развивает экологическое сознание, основанное на гуманном и
                  ценностном отношении к природе
                </p>
              </div>
            </div>
            <Link
              id="additional-registration_button-mobile"
              to="/registration/select"
              style={{ display: "none" }}
            >
              <button
                className="button-green-index unselectable"
                style={{
                  width: "100%",
                  height: "19.72vw",
                  background: "rgba(160, 212, 104, 1)",
                  borderRadius: "2.2vw",
                  fontFamily: "PT Sans Caption",
                  fontWeight: "700",
                  fontSize: "5.5vw",
                  lineHeight: "6.6vw",
                  letterSpacing: "-0.01em",
                  color: "#41361B",
                  border: "none",
                  cursor: "pointer",
                  margin: "0 0 10vw 0",
                }}
              >
                Присоединиться
              </button>
            </Link>
          </div>
        </section>
      </div>
    </>
  );
});

export default Main;
