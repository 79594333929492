import {observer} from "mobx-react";

export const ColumnDash = observer((props) => {
    return <div style={{display: "flex", flexDirection: "column", position: 'relative'}}>
        <div className={props.iter === 0    ? 'line_game first_line_game' : 'line_game'}/>
        <div style={{display: "flex", flexDirection: 'row'}}>
            {/*<div style={{width: '2px', height: '100%', background: 'rgba(67, 74, 84, 0.2)'}}/>*/}
            <div style={{width: '448px', height: '36px', background: "#E6E9ED", display: "flex", alignItems: "flex-end", borderLeft: '2px solid rgba(67, 74, 84, 0.12)'}}>
                <div style={{display: 'flex', justifyContent: 'flex-end', background: "#A0D468", height: '100%', width: `${props.width}%`}}>
                    <p style={{margin: 'auto 0', paddingRight: '6px', color: '#434A54'}}>{props.width >= 5? Math.round(props.width) : ""}</p>
                </div>
                {props.width < 5?
                    <p style={{margin: 'auto 0', paddingLeft: '9px', color: '#434A54'}}>{props.width < 5? Math.round(props.width) : ""}</p>
                    : ""}
            </div>
        </div>
        <div className={'line_game'}/>
    </div>
})