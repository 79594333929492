import { React, useState } from "react";
import { observer } from "mobx-react";
import restorePassword from "../../Stores/Registration/restorePassword";
import EmailUI from "../../Components/Inputs/Email/EmailUI";
import { UIInputBlock } from "./RegStep/SelectRole/Role/UIInputBlock";
import { Link, useHistory } from "react-router-dom";

export const Restore1 = observer(() => {
  const [EmailPopup, setEmailPopup] = useState(false);
  if (EmailPopup) {
    window.scrollTo(0, 0);
    document.getElementsByTagName("body")[0].style =
      "height: 100vh; overflow-y: hidden;";
  } else {
    document.getElementsByTagName("body")[0].style = "";
  }
  return (
    <div
      id="restore-container"
      className="unselectable"
      style={{ height: "100vh" }}
    >
      <div
        id="restore-container-child"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translateY(-50%) translateX(-50%)",
        }}
      >
        <h1
          style={{
            margin: "0",
            textAlign: "center",
            fontFamily: "Open Sans",
            fontWeight: "700",
            fontSize: "24px",
            lineHeight: "28.8px",
            letterSpacing: "-0.01em",
            color: "#000000",
          }}
          className="unselectable"
        >
          Восстановление пароля
        </h1>
        <UIInputBlock
          type="email"
          placeholder="Укажите вашу почту"
          width="336px"
          height="48px"
          margin="40px 0 0"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            style={{
              padding: "12px 56px",
              background: "#4FC1E9",
              borderRadius: "8px",
              cursor: "pointer",
              WebkitUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
              border: "solid 1px #4FC1E9",
              fontFamily: "PT Sans Caption",
              fontWeight: "400",
              fontSize: "20px",
              lineHeight: "24px",
              color: "#000000",
              margin: "40px 0 0",
              boxSizing: "border-box",
            }}
            className="button-blue"
            onClick={() => {
              setEmailPopup(true);
            }}
          >
            Отправить
          </button>
        </div>
      </div>
      {EmailPopup ? (
        <div
          className="popup-bg"
          onClick={(e) => {
            if (e.target !== e.currentTarget) {
              return;
            } else {
              setEmailPopup(false);
            }
          }}
        >
          <div className="popup-card">
            <h1 style={{ display: "none" }}>Восстановление пароля</h1>
            <p>
              Проверьте почту.
              <br />
              Вам должно прийти уведомление
            </p>
            <Link to="/new-password">
              <button id="restore-popup-button-bg" className="button-green">
                Хорошо
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );

  // return <>

  //     <div className="main pt_header">
  //         <p className="title_greetings">ЗАБЫЛИ ПАРОЛЬ?</p>
  //         <p className="greetings">ВОССТАНОВЛЕНИЕ ПАРОЛЯ</p>

  //         <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
  //             <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
  //         </svg>

  //         <div className="row">
  //             <div className="col-md-8 col-sm-10" style={{display: "flex", margin: '0 auto'}}>
  //                 <div className="row" style={{width: '100%'}}>
  //                     <form style={{width: '100%'}}>
  //                         <div className="col-md-12 col-sm-12">
  //                             <div className="form_group ml-md-5 mt-md-10 mb-md-10 mb-sm-10 mt-sm-5">
  //                                 <EmailUI/>
  //                             </div>
  //                         </div>

  //                         <div className="col-md-12 col-sm-12">
  //                             <div className="form_group ml-md-5 mt-md-10 mb-md-10 mb-sm-10">
  //                                 <button type="button" id="auth_button" style={{width: '100%', margin: 0}} className="buttons button_blue"
  //                                         onClick={() => restorePassword.checkRestoreData()}>
  //                                     Отправить
  //                                 </button>
  //                             </div>
  //                         </div>
  //                     </form>
  //                 </div>
  //             </div>
  //         </div>
  //     </div>

  // </>
});
