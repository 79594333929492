import React from "react";
import { observer } from "mobx-react";
import relatedAccsStore from "../../../../Stores/RelatedAccsStore/RelatedAccsStore";
import GenderUI from "../../../../Components/Inputs/gender/genderUI";
import NameUI from "../../../../Components/Inputs/Name/nameUI";
import SurnameUI from "../../../../Components/Inputs/Surname/surnameUI";
import AgeUI from "../../../../Components/Inputs/Age/AgeUI";
import childStore from "./StoreProfile/childProfile";

export const ChildProfile = observer( (props) => {
    if(relatedAccsStore.ReletedAccount){
        childStore.getUserData();
        relatedAccsStore.SetRelatedAccountFlag()


    }

    return <form>

        <div className="form_group pt-30">
            <div className="form_row mr-15 ml_account-15">
                <div className="col-md-6 col-sm-12">
                    <div className="form_group mr-5 ml-5 mb-5 mt-sm-5">
                        <NameUI disabled={props.disabled}/>
                    </div>
                </div>

                <div className="col-md-6 col-sm-12">
                    <div className="form_group mr-5 ml-5 mb-5 mt-sm-5">
                        <SurnameUI disabled={props.disabled}/>
                    </div>
                </div>

                <div className="col-md-6 col-sm-12">
                    <div className="form_group mr-5 ml-5 mb-5 mt-5">
                        <GenderUI checkChange={() => {}} disabled={props.disabled}/>
                    </div>
                </div>


                <div className="col-md-6 col-sm-12">
                    <div className="form_group mr-5 ml-5 mb-5 mt-5">
                        <AgeUI disabled={props.disabled}/>
                    </div>
                </div>
            </div>
        </div>
    </form>
})