import { React, useState } from "react";
import UIBlockButton, {
  UIBlockButtonColor,
} from "../../../../../../Components/Katelinlis/Button.tsx";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

const GroupRow = ({ id, number, setSelectedGroup }) => {
  return (
    <tr>
      <td>{id}</td>
      <td>{number}</td>
      <td>
        <button
          onClick={() => {
            setSelectedGroup(number);
          }}
        >
          Выбрать
        </button>
      </td>
    </tr>
  );
};

const KidRow = ({ id, Name, Surname, setSelectedKid }) => {
  return (
    <tr>
      <td>{id}</td>
      <td>{Name}</td>
      <td>{Surname}</td>
      <td>
        <button
          onClick={() => {
            setSelectedKid(id);
          }}
        >
          Выбрать
        </button>
      </td>
    </tr>
  );
};

const BarChart = ({ Label, Percent }) => {
  return (
    <div className="DeaktopBarChart-row">
      <span className="DeaktopBarChartBar-label">{Label}</span>
      <div className="DeaktopBarChart-bar">
        <div className="DeaktopBarChartBar-bg"></div>
        <div className="DeaktopBarChartBar-fg" style={{ width: Percent + "%" }}>
          {Percent > 15 ? (
            <span className="DeaktopBarChartBar-score">{Percent}</span>
          ) : (
            <span className="DeaktopBarChartBar-score-dark">{Percent}</span>
          )}
        </div>
      </div>
    </div>
  );
};

const LevelStatsItem = ({ Color, Label, Time }) => {
  return (
    <div className="Desktop-levelstats-item">
      <div className="Desktop-levelstats-colorblock">
        <figure className="Desktop-levelstats-color">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="9" cy="9" r="9" fill={Color} />
          </svg>
        </figure>
        <span className="Desktop-levelstats-label">{Label}</span>
      </div>

      <span className="Desktop-levelstats-time">{Time}</span>
    </div>
  );
};

export const DashboardResults = ({ accountType }) => {
  const [SelectedGroup, setSelectedGroup] = useState(-1);
  const [SelectedKid, setSelectedKid] = useState(-1);

  const group = [];

  return (
    <main
      style={{
        marginLeft: "47px",
      }}
    >
      {accountType === "teacher" && SelectedGroup === -1 ? (
        <>
          <div className="Dashboard-breadcrumbs">
            <button
              onClick={() => {
                setSelectedKid(-1);
                setSelectedGroup(-1);
              }}
            >
              Отчеты
            </button>
          </div>
          <h1
            style={{
              fontFamily: "Open Sans",
              fontWeight: "700",
              fontSize: "24px",
              lineHeight: "120%",
              letterSpacing: "-0.01em",
              color: "#000000",
              textAlign: "left",
              margin: "0 0 24px",
            }}
          >
            Отчеты
          </h1>
          <table className="DashboardTable-Group">
            <thead>
              <tr>
                <th id="DashboardTable-ID">№</th>
                <th id="DashboardTable-Name">Группа</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <GroupRow
                id="1"
                number="213"
                setSelectedGroup={setSelectedGroup}
              />
              <GroupRow
                id="2"
                number="123"
                setSelectedGroup={setSelectedGroup}
              />
              <GroupRow
                id="3"
                number="432"
                setSelectedGroup={setSelectedGroup}
              />
              <GroupRow
                id="4"
                number="333"
                setSelectedGroup={setSelectedGroup}
              />
            </tbody>
          </table>
        </>
      ) : (
        ""
      )}

      {accountType === "teacher" &&
      SelectedGroup !== -1 &&
      SelectedKid === -1 ? (
        <>
          <div className="Dashboard-breadcrumbs">
            <button
              onClick={() => {
                setSelectedKid(-1);
                setSelectedGroup(-1);
              }}
            >
              Отчеты
            </button>
            <figure>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.11467 8.00005L6.22867 6.11472L7.17133 5.17139L10 8.00005L7.17133 10.8287L6.22867 9.88539L8.11467 8.00005Z"
                  fill="#BDC7D5"
                />
              </svg>
            </figure>
            <button
              onClick={() => {
                setSelectedKid(-1);
              }}
            >
              Результаты группы {SelectedGroup}
            </button>
          </div>
          <h1
            style={{
              fontFamily: "Open Sans",
              fontWeight: "700",
              fontSize: "24px",
              lineHeight: "120%",
              letterSpacing: "-0.01em",
              color: "#000000",
              textAlign: "left",
              margin: "0 0 24px",
            }}
          >
            Результаты группы {SelectedGroup}
          </h1>
          <table className="DashboardTable-Group">
            <thead>
              <tr>
                <th id="DashboardTable-ID">№</th>
                <th id="DashboardTable-Name">Имя</th>
                <th id="DashboardTable-Surname">Фамилия</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <KidRow
                id="1"
                Name="Миша"
                Surname="Мрак"
                setSelectedKid={setSelectedKid}
              />
              <KidRow
                id="2"
                Name="Настя"
                Surname="Гагарина"
                setSelectedKid={setSelectedKid}
              />
              <KidRow
                id="3"
                Name="Полина"
                Surname="Апполонова"
                setSelectedKid={setSelectedKid}
              />
            </tbody>
          </table>
          <section className="SectionResults">
            <h3>Общее выполнение Олимпиады (%)</h3>
            <div className="pie-Chart-Desktop">
              <figure className="pieChart-bg-Desktop">
                <svg
                  width="240"
                  height="240"
                  viewBox="0 0 240 240"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="120" cy="120" r="120" fill="#E6E9ED" />
                </svg>
                <Doughnut
                  data={{
                    datasets: [
                      {
                        data: [75, 25],
                        backgroundColor: ["#A0D468", "#E6E9ED"],
                        borderColor: ["#A0D468", "#E6E9ED"],
                        borderWidth: 1,
                      },
                    ],
                  }}
                />
              </figure>

              <span className="pieChart-score-Desktop">75%</span>
            </div>
            <h3>Выполнение одной игры (%)</h3>
            <div className="barChart-Desktop">
              <BarChart Label="Красная книга" Percent="36" />
              <BarChart Label="Экосистема" Percent="76" />
              <BarChart Label="Покорми птичку" Percent="5" />
              <BarChart Label="Поведение в лесу" Percent="77" />
              <BarChart Label="Экономим ресурсы" Percent="100" />
              <BarChart Label="Сортировка мусора" Percent="23" />
              <BarChart Label="Вторая жизнь вещей" Percent="36" />
              <BarChart Label="Осознанное потребление" Percent="80" />
            </div>
            <h3>Время, проведенное в одной игре (мин)</h3>
            <div className="pie-Chart-Desktop">
              <figure className="pieChart-bg-Desktop">
                <svg
                  width="240"
                  height="240"
                  viewBox="0 0 240 240"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="120" cy="120" r="120" fill="#E6E9ED" />
                </svg>
                <Doughnut
                  data={{
                    datasets: [
                      {
                        data: [5.37, 4.37, 2.7, 5.1, 8.27, 1.3, 9.37, 4.37],
                        backgroundColor: [
                          "#FC6E51",
                          "#A0D468",
                          "#FFCE54",
                          "#4FC1E9",
                          "#5D9CEC",
                          "#AC92EC",
                          "#EC87C0",
                          "#ED5565",
                        ],
                        borderColor: [
                          "#FC6E51",
                          "#A0D468",
                          "#FFCE54",
                          "#4FC1E9",
                          "#5D9CEC",
                          "#AC92EC",
                          "#EC87C0",
                          "#ED5565",
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                />
              </figure>

              <div className="pieChart-SpanBox-Desktop">
                <div className="pieChartSpanBox-Block-Desktop">
                  <span className="pieChart-time-Desktop">25</span>
                  <span className="pieChart-TimeSubtitle-Desktop">МИНУТ</span>
                </div>
                <div className="pieChartSpanBox-Block-Desktop">
                  <span className="pieChart-time-Desktop">17</span>
                  <span className="pieChart-TimeSubtitle-Desktop">СЕКУНД</span>
                </div>
              </div>
            </div>
            <h3>Время, проведенное на одном уровне (мин)</h3>
            <div className="levelstats-box-Desktop">
              <LevelStatsItem
                Color="#ED5565"
                Label="Красная книга"
                Time="5м.37с."
              />
              <LevelStatsItem
                Color="#FC6E51"
                Label="Экосистема"
                Time="5м.35с."
              />
              <LevelStatsItem
                Color="#FFCE54"
                Label="Покорми птичку"
                Time="5м.35с."
              />
              <LevelStatsItem
                Color="#A0D468"
                Label="Поведение в лесу"
                Time="5м.35с."
              />
              <LevelStatsItem
                Color="#4FC1E9"
                Label="Экономим ресурсы"
                Time="5м.35с."
              />
              <LevelStatsItem
                Color="#5D9CEC"
                Label="Сортировка мусора"
                Time="5м.35с."
              />
              <LevelStatsItem
                Color="#AC92EC"
                Label="Вторая жизнь вещей"
                Time="5м.35с."
              />
              <LevelStatsItem
                Color="#EC87C0"
                Label="Осознанное потребление"
                Time="5м.35с."
              />
            </div>
          </section>
          <h3 id="DashboardResults-Recommendation">Рекомендации</h3>
          <p id="DashboardResults-RecommendationText">
            Таким образом постоянное информационно-пропагандистское обеспечение
            нашей деятельности позволяет оценить значение дальнейших направлений
            развития. Повседневная практика показывает, что новая модель
            организационной деятельности играет важную роль в формировании форм
            развития. Разнообразный и богатый опыт постоянное
            информационно-пропагандистское обеспечение нашей деятельности
            требуют определения и уточнения новых предложений.{" "}
          </p>
        </>
      ) : (
        <></>
      )}
      {accountType === "teacher" &&
      SelectedGroup !== -1 &&
      SelectedKid !== -1 ? (
        <>
          <div className="Dashboard-breadcrumbs">
            <button
              onClick={() => {
                setSelectedKid(-1);
                setSelectedGroup(-1);
              }}
            >
              Отчеты
            </button>
            <figure>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.11467 8.00005L6.22867 6.11472L7.17133 5.17139L10 8.00005L7.17133 10.8287L6.22867 9.88539L8.11467 8.00005Z"
                  fill="#BDC7D5"
                />
              </svg>
            </figure>
            <button
              onClick={() => {
                setSelectedKid(-1);
              }}
            >
              Результаты группы {SelectedGroup}
            </button>
            <figure>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.11467 8.00005L6.22867 6.11472L7.17133 5.17139L10 8.00005L7.17133 10.8287L6.22867 9.88539L8.11467 8.00005Z"
                  fill="#BDC7D5"
                />
              </svg>
            </figure>
            <button>Результаты Имени Фамилии</button>
          </div>
          <h1
            style={{
              fontFamily: "Open Sans",
              fontWeight: "700",
              fontSize: "24px",
              lineHeight: "120%",
              letterSpacing: "-0.01em",
              color: "#000000",
              textAlign: "left",
              margin: "0 0 24px",
            }}
          >
            Результаты Имени Фамилии
          </h1>
          <section className="SectionResults">
            <h3>Общее выполнение Олимпиады (%)</h3>
            <div className="pie-Chart-Desktop">
              <figure className="pieChart-bg-Desktop">
                <svg
                  width="240"
                  height="240"
                  viewBox="0 0 240 240"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="120" cy="120" r="120" fill="#E6E9ED" />
                </svg>
                <Doughnut
                  data={{
                    datasets: [
                      {
                        data: [45, 55],
                        backgroundColor: ["#A0D468", "#E6E9ED"],
                        borderColor: ["#A0D468", "#E6E9ED"],
                        borderWidth: 1,
                      },
                    ],
                  }}
                />
              </figure>

              <span className="pieChart-score-Desktop">45%</span>
            </div>
            <h3>Выполнение одной игры (%)</h3>
            <div className="barChart-Desktop">
              <BarChart Label="Красная книга" Percent="36" />
              <BarChart Label="Экосистема" Percent="76" />
              <BarChart Label="Покорми птичку" Percent="5" />
              <BarChart Label="Поведение в лесу" Percent="77" />
              <BarChart Label="Экономим ресурсы" Percent="100" />
              <BarChart Label="Сортировка мусора" Percent="23" />
              <BarChart Label="Вторая жизнь вещей" Percent="36" />
              <BarChart Label="Осознанное потребление" Percent="80" />
            </div>
            <h3>Среднее время, проведённое в олимпиаде (мин)</h3>
            <div className="pie-Chart-Desktop">
              <figure className="pieChart-bg-Desktop">
                <svg
                  width="240"
                  height="240"
                  viewBox="0 0 240 240"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="120" cy="120" r="120" fill="#E6E9ED" />
                </svg>
                <Doughnut
                  data={{
                    datasets: [
                      {
                        data: [1.37, 3.37, 12.7, 2.1, 2.27, 14.3, 1.37, 24.37],
                        backgroundColor: [
                          "#FC6E51",
                          "#A0D468",
                          "#FFCE54",
                          "#4FC1E9",
                          "#5D9CEC",
                          "#AC92EC",
                          "#EC87C0",
                          "#ED5565",
                        ],
                        borderColor: [
                          "#FC6E51",
                          "#A0D468",
                          "#FFCE54",
                          "#4FC1E9",
                          "#5D9CEC",
                          "#AC92EC",
                          "#EC87C0",
                          "#ED5565",
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                />
              </figure>

              <div className="pieChart-SpanBox-Desktop">
                <div className="pieChartSpanBox-Block-Desktop">
                  <span className="pieChart-time-Desktop">37</span>
                  <span className="pieChart-TimeSubtitle-Desktop">МИНУТ</span>
                </div>
                <div className="pieChartSpanBox-Block-Desktop">
                  <span className="pieChart-time-Desktop">12</span>
                  <span className="pieChart-TimeSubtitle-Desktop">СЕКУНД</span>
                </div>
              </div>
            </div>
            <h3>Время, проведенное на одном уровне (мин)</h3>
            <div className="levelstats-box-Desktop">
              <LevelStatsItem
                Color="#ED5565"
                Label="Красная книга"
                Time="5м.37с."
              />
              <LevelStatsItem
                Color="#FC6E51"
                Label="Экосистема"
                Time="5м.35с."
              />
              <LevelStatsItem
                Color="#FFCE54"
                Label="Покорми птичку"
                Time="5м.35с."
              />
              <LevelStatsItem
                Color="#A0D468"
                Label="Поведение в лесу"
                Time="5м.35с."
              />
              <LevelStatsItem
                Color="#4FC1E9"
                Label="Экономим ресурсы"
                Time="5м.35с."
              />
              <LevelStatsItem
                Color="#5D9CEC"
                Label="Сортировка мусора"
                Time="5м.35с."
              />
              <LevelStatsItem
                Color="#AC92EC"
                Label="Вторая жизнь вещей"
                Time="5м.35с."
              />
              <LevelStatsItem
                Color="#EC87C0"
                Label="Осознанное потребление"
                Time="5м.35с."
              />
            </div>
          </section>
          <h3 id="DashboardResults-Recommendation">Рекомендации</h3>
          <p id="DashboardResults-RecommendationText">
            Таким образом постоянное информационно-пропагандистское обеспечение
            нашей деятельности позволяет оценить значение дальнейших направлений
            развития. Повседневная практика показывает, что новая модель
            организационной деятельности играет важную роль в формировании форм
            развития. Разнообразный и богатый опыт постоянное
            информационно-пропагандистское обеспечение нашей деятельности
            требуют определения и уточнения новых предложений.{" "}
          </p>
        </>
      ) : (
        ""
      )}
      {accountType === "parent" && SelectedKid === -1 ? (
        <>
          <div className="Dashboard-breadcrumbs">
            <button
              onClick={() => {
                setSelectedKid(-1);
                setSelectedGroup(-1);
              }}
            >
              Отчеты
            </button>
          </div>
          <h1
            style={{
              fontFamily: "Open Sans",
              fontWeight: "700",
              fontSize: "24px",
              lineHeight: "120%",
              letterSpacing: "-0.01em",
              color: "#000000",
              textAlign: "left",
              margin: "0 0 24px",
            }}
          >
            Отчеты
          </h1>
          <table className="DashboardTable-Group">
            <thead>
              <tr>
                <th id="DashboardTable-ID">№</th>
                <th id="DashboardTable-Name">Имя</th>
                <th id="DashboardTable-Surname">Фамилия</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <KidRow
                id="1"
                Name="Миша"
                Surname="Мрак"
                setSelectedKid={setSelectedKid}
              />
              <KidRow
                id="2"
                Name="Настя"
                Surname="Гагарина"
                setSelectedKid={setSelectedKid}
              />
              <KidRow
                id="3"
                Name="Полина"
                Surname="Апполонова"
                setSelectedKid={setSelectedKid}
              />
            </tbody>
          </table>
        </>
      ) : (
        ""
      )}
      {accountType === "parent" && SelectedKid !== -1 ? (
        <>
          <div className="Dashboard-breadcrumbs">
            <button
              onClick={() => {
                setSelectedKid(-1);
                setSelectedGroup(-1);
              }}
            >
              Отчеты
            </button>
            <figure>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.11467 8.00005L6.22867 6.11472L7.17133 5.17139L10 8.00005L7.17133 10.8287L6.22867 9.88539L8.11467 8.00005Z"
                  fill="#BDC7D5"
                />
              </svg>
            </figure>
            <button>Результаты Имени Фамилии</button>
          </div>
          <h1
            style={{
              fontFamily: "Open Sans",
              fontWeight: "700",
              fontSize: "24px",
              lineHeight: "120%",
              letterSpacing: "-0.01em",
              color: "#000000",
              textAlign: "left",
              margin: "0 0 24px",
            }}
          >
            Результаты Имени Фамилии
          </h1>
          <section className="SectionResults">
            <h3>Общее выполнение Олимпиады (%)</h3>
            <div className="pie-Chart-Desktop">
              <figure className="pieChart-bg-Desktop">
                <svg
                  width="240"
                  height="240"
                  viewBox="0 0 240 240"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="120" cy="120" r="120" fill="#E6E9ED" />
                </svg>
                <Doughnut
                  data={{
                    datasets: [
                      {
                        data: [45, 55],
                        backgroundColor: ["#A0D468", "#E6E9ED"],
                        borderColor: ["#A0D468", "#E6E9ED"],
                        borderWidth: 1,
                      },
                    ],
                  }}
                />
              </figure>

              <span className="pieChart-score-Desktop">45%</span>
            </div>
            <h3>Выполнение одной игры (%)</h3>
            <div className="barChart-Desktop">
              <BarChart Label="Красная книга" Percent="36" />
              <BarChart Label="Экосистема" Percent="76" />
              <BarChart Label="Покорми птичку" Percent="5" />
              <BarChart Label="Поведение в лесу" Percent="77" />
              <BarChart Label="Экономим ресурсы" Percent="100" />
              <BarChart Label="Сортировка мусора" Percent="23" />
              <BarChart Label="Вторая жизнь вещей" Percent="36" />
              <BarChart Label="Осознанное потребление" Percent="80" />
            </div>
            <h3>Среднее время, проведённое в олимпиаде (мин)</h3>
            <div className="pie-Chart-Desktop">
              <figure className="pieChart-bg-Desktop">
                <svg
                  width="240"
                  height="240"
                  viewBox="0 0 240 240"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="120" cy="120" r="120" fill="#E6E9ED" />
                </svg>
                <Doughnut
                  data={{
                    datasets: [
                      {
                        data: [1.37, 3.37, 12.7, 2.1, 2.27, 14.3, 1.37, 24.37],
                        backgroundColor: [
                          "#FC6E51",
                          "#A0D468",
                          "#FFCE54",
                          "#4FC1E9",
                          "#5D9CEC",
                          "#AC92EC",
                          "#EC87C0",
                          "#ED5565",
                        ],
                        borderColor: [
                          "#FC6E51",
                          "#A0D468",
                          "#FFCE54",
                          "#4FC1E9",
                          "#5D9CEC",
                          "#AC92EC",
                          "#EC87C0",
                          "#ED5565",
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                />
              </figure>

              <div className="pieChart-SpanBox-Desktop">
                <div className="pieChartSpanBox-Block-Desktop">
                  <span className="pieChart-time-Desktop">37</span>
                  <span className="pieChart-TimeSubtitle-Desktop">МИНУТ</span>
                </div>
                <div className="pieChartSpanBox-Block-Desktop">
                  <span className="pieChart-time-Desktop">12</span>
                  <span className="pieChart-TimeSubtitle-Desktop">СЕКУНД</span>
                </div>
              </div>
            </div>
            <h3>Время, проведенное на одном уровне (мин)</h3>
            <div className="levelstats-box-Desktop">
              <LevelStatsItem
                Color="#ED5565"
                Label="Красная книга"
                Time="5м.37с."
              />
              <LevelStatsItem
                Color="#FC6E51"
                Label="Экосистема"
                Time="5м.35с."
              />
              <LevelStatsItem
                Color="#FFCE54"
                Label="Покорми птичку"
                Time="5м.35с."
              />
              <LevelStatsItem
                Color="#A0D468"
                Label="Поведение в лесу"
                Time="5м.35с."
              />
              <LevelStatsItem
                Color="#4FC1E9"
                Label="Экономим ресурсы"
                Time="5м.35с."
              />
              <LevelStatsItem
                Color="#5D9CEC"
                Label="Сортировка мусора"
                Time="5м.35с."
              />
              <LevelStatsItem
                Color="#AC92EC"
                Label="Вторая жизнь вещей"
                Time="5м.35с."
              />
              <LevelStatsItem
                Color="#EC87C0"
                Label="Осознанное потребление"
                Time="5м.35с."
              />
            </div>
          </section>
          <h3 id="DashboardResults-Recommendation">Рекомендации</h3>
          <p id="DashboardResults-RecommendationText">
            Таким образом постоянное информационно-пропагандистское обеспечение
            нашей деятельности позволяет оценить значение дальнейших направлений
            развития. Повседневная практика показывает, что новая модель
            организационной деятельности играет важную роль в формировании форм
            развития. Разнообразный и богатый опыт постоянное
            информационно-пропагандистское обеспечение нашей деятельности
            требуют определения и уточнения новых предложений.{" "}
          </p>
        </>
      ) : (
        ""
      )}
    </main>
  );
};
