import { makeAutoObservable, observable, action } from "mobx";
import authStore from "../../../../../Stores/AuthStore/AuthStore";
import regStore from "../../../../../Stores/Registration/registration";

class MainStore {
  @observable email;
  @observable telephone;
  @observable password = "";
  @observable validEmail;
  @observable validTelephone;
  @observable validPassword;

  setEmail(e) {
    this.email = e.target.value;
    this.validateEmail();
  }
  setTelephone(e) {
    this.telephone = e.target.value;
    this.validateConfirmTelephone();
  }
  setPassword(e) {
    this.password = e.target.value;
    this.validatePassword();
  }

  validateConfirmTelephone() {
    if (this.telephone.indexOf("_") !== -1) {
      this.validTelephone = "is-invalid";
    } else if (this.telephone.length === 0) {
      this.validTelephone = "";
    } else {
      this.validTelephone = "is-valid";
    }
  }
  validateEmail() {
    if (!regStore.pattern.test(this.email)) this.validEmail = "is-invalid";
    else this.validEmail = "is-valid";
  }
  validatePassword() {
    if (this.password.length < 8) this.validPassword = "is-invalid";
    else this.validPassword = "is-valid";
  }

  @action getUserData() {
    this.email = authStore.masterAccountData.email;
    this.telephone = authStore.masterAccountData.phone;
  }

  constructor() {
    makeAutoObservable(this);
    if (authStore.isAuth) {
      this.email = authStore.masterAccountData.email;
      this.telephone = authStore.masterAccountData.phone;
    }
  }

  @action Clear() {
    this.validPassword = "";
    this.validTelephone = "";
    this.validEmail = "";
  }
}

const mainStore = new MainStore();

export default mainStore;
