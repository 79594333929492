import { observable, makeAutoObservable } from "mobx"
import regStepStore from './StepRegistration'
import authStore from "../AuthStore/AuthStore"
import modalStore from "../../Components/modal/modalStore";
import {emailStore} from "../../Components/Inputs/Email/EmailStore";
import {telephoneStore} from "../../Components/Inputs/Telephone/TelephoneStore";
import {newPasswordStore} from "../../Components/Inputs/Password/NewPasswordStore";
import {confirmPasswordStore} from "../../Components/Inputs/Password/ConfirmPasswordStore";
import masterRegStore from "./MasterRegStore";
import dropdownPositionStore from "../../Components/Inputs/Position/dropdownPositionStore";
import {nameStore} from "../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../Components/Inputs/Surname/surnameStore";
import {userRegistration} from "../../Request/api/User/userRegistration.tsx";
import {RegisterStore} from "./RegistrationForm.tsx"


class RegStore {
    @observable disabledRegButton = "disabled";

    constructor() {
        makeAutoObservable(this);
        this.CheckRegInput = this.CheckRegInput.bind(this);
    }

    async CheckRegInput(history){
        if(regStore.disabledRegButton === ''){
            emailStore.ValidEmail();
            telephoneStore.ValidTelephone();
            newPasswordStore.ValidPassword();
            confirmPasswordStore.ValidPassword();
            if(emailStore.GetEmail !== "" && telephoneStore.GetTelephone !== "" && newPasswordStore.GetPassword !== "" &&
                confirmPasswordStore.GetPassword !== ""
            ){
                await this.PostRegistration(history);
            }
        }
    }




    async PostRegistration(history){
        userRegistration({
            email:RegisterStore.email,
            password:RegisterStore.password,
            phone:RegisterStore.phone,
            role:RegisterStore.phone,
            firstname:RegisterStore.firstAndLastName,
            lastname:RegisterStore.lastname,
            middlename:RegisterStore.firstAndLastName
        }).then(async response => {
            if(response !== null){
                if(response.type !== "Error"){

                    await authStore.Auth();

                    RegisterStore.Clear();

                    this.disabledRegButton = "disabled";
                    regStepStore.setStep("1")

                    if(sessionStorage.getItem('inviteData') !== null && sessionStorage.getItem('invite') !== null){

                        if(JSON.parse(sessionStorage.getItem('inviteData')).UserType === 'Children'){
                            masterRegStore.setStep('parent')
                            history.push('/registration/parent');
                        } else {
                            masterRegStore.setStep('teacher')

                            nameStore.Name = JSON.parse(sessionStorage.getItem('inviteData')).GroupMember.Name;
                            surnameStore.Surname = JSON.parse(sessionStorage.getItem('inviteData')).GroupMember.Surname;
                            dropdownPositionStore.position = JSON.parse(sessionStorage.getItem('inviteData')).GroupMember.Position.PositionName;
                            dropdownPositionStore.positionId = JSON.parse(sessionStorage.getItem('inviteData')).GroupMember.PositionId
                            history.push('/registration/teacher');
                        }
                    } else {
                        masterRegStore.setStep('select')
                        history.push('/registration/select');
                    }
                } else {

                    if(response.message === 'Пользователь уже существует'){
                        modalStore.SetModalInfo("Пользователь уже существует", "Ошибка")
                        modalStore.simpleModalOpen();
                    } else {
                        modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                        modalStore.simpleModalOpen();
                    }
                }
            } else {
                modalStore.SetModalInfo('Произошла ошибка', "Ошибка")
                modalStore.simpleModalOpen();
            }
        })
    }

    keyDownForm = (e,history) => {
        if(this.disabledRegButton === '' && e.code === "Enter" && document.activeElement.tagName !== 'A'){
            this.CheckRegInput(history);
        }
    }

    formCheck(){
        if(emailStore.Email !== "" && telephoneStore.Telephone !== "" && newPasswordStore.password !== "" &&
            confirmPasswordStore.password !== ""
            ){
            this.disabledRegButton = "";
        } else {
            this.disabledRegButton = "disabled";
        }
    }
}

const regStore = new RegStore();

export default regStore;