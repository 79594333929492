import React from "react";
import { observer } from "mobx-react";
import regStore from "../../../Stores/Registration/registration";
import Modal from "../../../Components/modal/modal";
import EmailUI from "../../../Components/Inputs/Email/EmailUI";
import TelephoneUI from "../../../Components/Inputs/Telephone/TelephoneUI";
import PasswordUI from "../../../Components/Inputs/Password/PasswordUI";
import { newPasswordStore } from "../../../Components/Inputs/Password/NewPasswordStore";
import { confirmPasswordStore } from "../../../Components/Inputs/Password/ConfirmPasswordStore";
import "../linkStyle.css";
import { Link, useHistory } from "react-router-dom";
import { CheckStep } from "../registration1";
import authStore from "../../../Stores/AuthStore/AuthStore";

export const RegStep1 = observer(() => {
  let history = useHistory();
  CheckStep("master");

  return (
    <>
      <form
        onChange={() => regStore.formCheck()}
        onKeyDown={(e) => regStore.keyDownForm(e, history)}
      >
        <p className="title_greetings">РЕГИСТРАЦИЯ АККАУНТА</p>
        <p className="greetings">ВВЕДИТЕ ДАННЫЕ АККАУНТА</p>

        <div className="rect">
          <svg
            width="60"
            height="5"
            viewBox="0 0 60 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="60" height="5" rx="2.5" fill="#4FC1E9" />
          </svg>
        </div>

        <div className="row pt-30">
          <div className="col-md-6 col-sm-12">
            <div className="form_group ml-md-145 mb-sm-10 mr-sm-10 ml-sm-10 mr-md-5 mb-md-10">
              <EmailUI />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="form_group mr-md-145 mb-sm-10 mr-sm-10 ml-sm-10 ml-md-5 mb-md-10">
              <TelephoneUI change={() => {}} disabled={false} />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="form_group ml-md-145 mb-sm-10 mr-sm-10 ml-sm-10 mr-md-5">
              <PasswordUI
                text={"Пароль"}
                valid={newPasswordStore.passwordValid}
                placeholder={"Выберите пароль"}
                password={newPasswordStore.password}
                func={newPasswordStore.SetPassword}
                HelpText={newPasswordStore.HelpText}
                src={newPasswordStore.imgEye}
                type={newPasswordStore.inputType}
                eyeClcik={newPasswordStore.eyeClcik}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="form_group mr-md-145 ml-md-5 mb-sm-10 mr-sm-10 ml-sm-10 mb-md-10">
              <PasswordUI
                text={"Подтвердите пароль"}
                valid={confirmPasswordStore.passwordValid}
                placeholder={"Подтвердите пароль"}
                password={confirmPasswordStore.password}
                func={confirmPasswordStore.SetPassword}
                HelpText={confirmPasswordStore.HelpText}
                src={confirmPasswordStore.imgEye}
                type={confirmPasswordStore.inputType}
                eyeClcik={confirmPasswordStore.eyeClcik}
                styleDisplay={"none"}
              />
            </div>
          </div>

          <div className="col-md-12 col-sm-12">
            <div className="mt-md-10 mb-md-10 mb-sm-10 mr-sm-10 ml-sm-10 terms_and_privacy">
              Нажимая на далее вы принимаете{" "}
              <a
                className={"terms_and_privacy"}
                style={{
                  cursor: "pointer",
                  color: "#4FC1E9",
                  textDecoration: "none",
                }}
                onClick={() =>
                  window.open(
                    `${window.location.href.substr(
                      0,
                      window.location.href.indexOf("/", 8)
                    )}/terms`
                  )
                }
              >
                условия использования
              </a>
            </div>
          </div>

          <div className="col-md-12 col-sm-12">
            <div className="form_group mr-md-145 ml-md-145 mb-sm-10 mr-sm-10 ml-sm-10 mb-md-10 mt-md-10">
              <button
                type="button"
                id="auth_button"
                className={`buttons button_blue ${regStore.disabledRegButton}`}
                style={{ width: "100%", margin: 0 }}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    regStore.CheckRegInput(history);
                  }
                }}
                onClick={() => regStore.CheckRegInput(history)}
              >
                Далее
              </button>
            </div>
          </div>
        </div>

        <Link
          to="/authorization"
          className="link"
          onClick={() => {
            authStore.Logout();
          }}
        >
          Авторизация
        </Link>
      </form>

      <svg
        className="step_reg"
        width="70"
        height="10"
        viewBox="0 0 70 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="35" cy="5" r="5" fill="#434A54" fillOpacity="0.5" />
        <circle cx="5" cy="5" r="5" fill="#434A54" />
        <circle cx="65" cy="5" r="5" fill="#434A54" fillOpacity="0.5" />
      </svg>
    </>
  );
});

export default RegStep1;
