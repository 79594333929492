import {observer} from "mobx-react";
import childGroupsStore from "../../../../Stores/ChildGroups/ChildGroupsStore";
import authStore from "../../../../Stores/AuthStore/AuthStore";
import {getChildrenTokens} from "../../../../Request/api/OAuth2/getChildrenTokens";
import React from "react";
import {Link} from "react-router-dom";

function extractDomain(url) {
    if(url.indexOf('localhost') !== -1){
        return 'localhost';
    } else {
        return url.replace(/^(?:https?:\/\/)?(?:[^\/]+\.)?([^.\/]+\.[^.\/]+).*$/, "$1");
    }
}

export const ChildrenGroupTable = observer(() => {

    return <>
        <table className="table mr-20 ml-20 mt-5">
            <thead style={{width: '100%'}}>
            <tr className="table_header">
                <th className="col-6">Фамилия Имя</th>
                <th className="col-6">Группа</th>
            </tr>
            </thead>
            <tbody>
            {childGroupsStore.GroupChlid.map(child =>
                <>
                {childGroupsStore.childrenId !== 0 && childGroupsStore.changeFlag && child.MemberId === childGroupsStore.childrenId ?
                    <>
                        <tr className="table_main" style={{background: "#E6E9ED", color: "black"}} onClick={() => childGroupsStore.closeButtonsChild()}>
                            <td>{child.User == null ? child.Surname + ' ' + child.Name : child.User.Surname + ' ' + child.User.Name}</td>
                            <td>{child.Group.Name}</td>
                        </tr>
                        <tr className="table_main" style={{background: "#E6E9ED", color: "black"}}>
                            <td colSpan={3}>
                                <div className="form_row">
                                    {child.User === null?
                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                            <div className="form_group mr-5 ml-5 mb-5">
                                                <Link to={'/groups/children/change'} style={{width: '100%', display: 'flex', textDecoration: 'none'}}>
                                                    <button id="select_education" className="buttons button_green"
                                                            style={{width: '100%', margin: 0, padding: 0}}>ИЗМЕНИТЬ ДАННЫЕ <span className="sm_none"> РЕБЁНКА</span>
                                                    </button>
                                                </Link>
                                            </div>
                                        </div> :<></>}

                                    <div className={child.User === null? "col-lg-4 col-md-4 col-sm-4" : "col-sm-12"}>
                                        <div className="form_group mr-5 ml-5 mb-5">
                                            <button onClick={() => childGroupsStore.DeleteChild()} className="buttons button_blue" style={{width: '100%', margin: 0, padding: 0, background: "#4FC1E9"}}>
                                                УДАЛИТЬ РЕБЁНКА
                                            </button>
                                        </div>
                                    </div>

                                    {child.User != null?
                                    <div className={child.User === null? "col-lg-4 col-md-4 col-sm-4" : "col-sm-12"}>
                                        <div className="form_group mr-5 ml-5 mb-5">
                                            <button onClick={() => {
                                                getChildrenTokens(child.User.UserId).then(
                                                  r => {
                                                    console.log(r.data.access);
                                                    console.log(r.data.refresh);
                                                    document.cookie = `access=${r.data.access}; domain=${extractDomain(window.location.href)}`;
                                                    document.cookie = `refresh=${r.data.refresh}; domain=${extractDomain(window.location.href)}; max-age=2678400`; //max-age равен клв. секунд в месяце
                                                    if(extractDomain(window.location.href) === "serviceplaystand.ru"){
                                                        window.open('https://olymp.serviceplaystand.ru/');
                                                    }
                                                    if(extractDomain(window.location.href) === "playstand.ru"){
                                                        window.open('https://olymp.playstand.ru/');
                                                    }
                                                  }
                                                )
                                            }} className="buttons button_green" style={{width: '100%', margin: 0, padding: 0}}>
                                                ОЛИМПИАДА
                                            </button>
                                        </div>
                                    </div>: <></>}

                                    {child.User === null?
                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                            <div className="form_group mr-5 ml-5 mb-5">
                                                <button onClick={() => childGroupsStore.GetInviteChild()} id="select_education" className="buttons button_yellow" style={{width: '100%', margin: 0, padding: 0, background: "#FFCE54"}}>
                                                    <span className="invite_mobile">ПОЛУЧИТЬ ИНВАЙТ-КОД</span>
                                                    <span className="invite_comp">ПОЛУЧИТЬ КОД-ПРИГЛАШЕНИЕ</span>
                                                </button>
                                            </div>
                                        </div> : <></>}
                                </div>
                            </td>
                        </tr>
                    </>
                    :
                    <tr className="table_main" onClick={() => {
                        childGroupsStore.ChangeTd(child)
                    }}>
                        <td>
                            {child.User == null ? child.Surname + ' ' + child.Name : child.User.Surname + ' ' + child.User.Name}
                        </td>
                        <td>{child.Group.Name}</td>
                    </tr>
                }
                </>
            )}
            </tbody>
        </table>
    </>
})
