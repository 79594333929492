import React from 'react';
import {observer} from "mobx-react";
//todo: переделать обращение в стор

import BigButton from "../Atoms/Buttons/BigButton";
import reportStore from "../../../../Stores/Report/ReportStore";


const LevelSelector = () => {
  const active = reportStore.levelOlymp

  const list = ['1 УРОВЕНЬ','2 УРОВЕНЬ', '3 УРОВЕНЬ']

  const handleClick = (key) => {
    reportStore.setLevelOlymp(key)
  }

  const buttons = list.map((item,key) => {
    return (
      <div className="col-sm-4">
        <BigButton text={item} active={active == key ? true : false} onClick={() => handleClick(key)} key={key}/>
      </div>
    )
  })


  return (
    <>{buttons}</>
  );
};

export default observer(LevelSelector);